import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import { errorMsg } from '../../components/SnackbarUtilsConfigurator';
import { UIConfigType, FabAction, ActionButton, FabActions } from '../../utils/types';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { defaultAggregateUICustomization } from '../../utils/jsonPartsGenerators';
import { ActionButtonType } from '../../components/ActionButtonEditor/ActionButtonEditor';
import {
  MobileCardDefinition,
  TimerAction,
  WebCardDefinition,
  ScheduleActionRow,
  NavigationButtons,
} from '@terragotech/gen5-config-lib';

export const useUICustomizationAPI = () => {
  const {
    config,
    getConfig,
    setUICustomization,
    getUICustomizations,
    setUICustomizations,
    getFabActions,
    setFabActions,
    getImportActions,
    setImportActions,
    getActionButtons,
    setActionButtons,
    setCardDefinition,
    getScheduleActions,
    setScheduleActions,
    setNavigations,
  } = useContext(ConfigContext);

  const promisify = <T extends Function>(
    child: T
  ): Promise<{ error: Error | null; data: T | null }> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ error: null, data: child() });
      } catch (e) {
        if (e instanceof Error) {
          errorMsg(e.message);
        }
        reject({ error: e as Error, data: null });
      }
    });
  };

  const addNewAggregateUICustomization = (uiConfigType: UIConfigType, aggrUIName: string) =>
    promisify(() => {
      const labelProperty =
        getConfig().aggregates[getAggregateIndex(config, aggrUIName)].labelProperty;
      const aggregateUICustomization = defaultAggregateUICustomization(uiConfigType, labelProperty);
      setUICustomization(uiConfigType, aggrUIName, aggregateUICustomization);
    });

  const deleteAggregateUICustomization = (uiConfigType: UIConfigType, aggrUIName: string) =>
    promisify(() => {
      const aggrUICustomizations = getUICustomizations(uiConfigType);
      if (aggrUICustomizations) {
        delete aggrUICustomizations[aggrUIName];
        setUICustomizations(uiConfigType, aggrUICustomizations);
      }
    });

  const updateAggregateUICustomization = (
    uiConfigType: UIConfigType,
    oldAggrName: string,
    newAggrName: string
  ) =>
    promisify(() => {
      const aggrUICustomizations = getUICustomizations(uiConfigType);
      if (aggrUICustomizations) {
        const data = aggrUICustomizations[oldAggrName];
        delete aggrUICustomizations[oldAggrName];
        aggrUICustomizations[newAggrName] = data;
        setUICustomizations(uiConfigType, aggrUICustomizations);
      }
    });

  const addFabAction = (uiConfigType: UIConfigType, fabAction: FabAction) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType) || [];
      fabActions.push(fabAction);
      setFabActions(uiConfigType, fabActions);
    });

  const addImportAction = (uiConfigType: UIConfigType, fabAction: FabAction) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType) || [];
      fabActions.push(fabAction);
      setImportActions(uiConfigType, fabActions);
    });

  const updateImportAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    fabAction: FabAction
  ) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType);
      fabActions[fabActionIndex] = fabAction;
      setImportActions(uiConfigType, fabActions);
    });

  const deleteImportAction = (uiConfigType: UIConfigType, fabActionIndex: number) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType);
      fabActions.splice(fabActionIndex, 1);
      setImportActions(uiConfigType, fabActions);
    });

  const moveImportAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    direction: 'UP' | 'DOWN'
  ) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType);
      if (direction === 'UP') {
        if (fabActionIndex === 0) throw new Error('Editor action is already at the top');
        fabActions.splice(
          fabActionIndex - 1,
          2,
          fabActions[fabActionIndex],
          fabActions[fabActionIndex - 1]
        );
      }
      if (direction === 'DOWN') {
        if (fabActionIndex === fabActions.length - 1)
          throw new Error('Editor action is already at the bottom');
        fabActions.splice(
          fabActionIndex,
          2,
          fabActions[fabActionIndex + 1],
          fabActions[fabActionIndex]
        );
      }
      setImportActions(uiConfigType, fabActions);
    });

  const moveFabAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    direction: 'UP' | 'DOWN'
  ) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType);
      if (direction === 'UP') {
        if (fabActionIndex === 0) throw new Error('Editor action is already at the top');
        fabActions.splice(
          fabActionIndex - 1,
          2,
          fabActions[fabActionIndex],
          fabActions[fabActionIndex - 1]
        );
      }
      if (direction === 'DOWN') {
        if (fabActionIndex === fabActions.length - 1)
          throw new Error('Editor action is already at the bottom');
        fabActions.splice(
          fabActionIndex,
          2,
          fabActions[fabActionIndex + 1],
          fabActions[fabActionIndex]
        );
      }
      setFabActions(uiConfigType, fabActions);
    });

  const updateFabAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    fabAction: FabAction
  ) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType);
      fabActions[fabActionIndex] = fabAction;
      setFabActions(uiConfigType, fabActions);
    });

  const deleteFabAction = (uiConfigType: UIConfigType, fabActionIndex: number) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType);
      fabActions.splice(fabActionIndex, 1);
      setFabActions(uiConfigType, fabActions);
    });

  const reOrderFabActionRow = (
    uiConfigType: UIConfigType,
    keyValue: 'fabActions' | 'importActions',
    rowData: FabActions
  ) =>
    promisify(() => {
      const setActions = keyValue === 'fabActions' ? setFabActions : setImportActions;
      setActions(uiConfigType, rowData);
    });

  const addActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    isMergedConfig: boolean,
    actionButton: ActionButton
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType) || [];
      if (actionButtons) {
        actionButtons.push(actionButton);
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
      }
    });

  const addActionButtons = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    newActionButtons: ActionButton[],
    ismerged: boolean
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        actionButtons.push(...newActionButtons);
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons);
      }
    });

  const reOrderActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    isMergedConfig: boolean,
    actionButtons: ActionButton[]
  ) =>
    promisify(() => {
      setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
    });

  const updateActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButtonIndex: number,
    fabAction: ActionButton,
    isMergedConfig: boolean = false
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        actionButtons[actionButtonIndex] = fabAction;
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
      }
    });

  const deleteActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButtonIndex: number,
    isMergedConfig: boolean = false
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        actionButtons.splice(actionButtonIndex, 1);
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
      }
    });

  const moveActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButtonIndex: number,
    direction: 'UP' | 'DOWN'
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        if (direction === 'UP') {
          if (actionButtonIndex === 0) throw new Error('Action button is already at the top');
          actionButtons.splice(
            actionButtonIndex - 1,
            2,
            actionButtons[actionButtonIndex],
            actionButtons[actionButtonIndex - 1]
          );
        }
        if (direction === 'DOWN') {
          if (actionButtonIndex === actionButtons.length - 1)
            throw new Error('Action button is already at the bottom');
          actionButtons.splice(
            actionButtonIndex,
            2,
            actionButtons[actionButtonIndex + 1],
            actionButtons[actionButtonIndex]
          );
        }
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons);
      }
    });

  const updateCardDefinition = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    webCardDefinition: WebCardDefinition,
    mobileCardDefinition: MobileCardDefinition
  ) =>
    promisify(() => {
      setCardDefinition(uiConfigType, aggrUIName, webCardDefinition, mobileCardDefinition);
    });

  const updateScheduleAction = (scheduleAction: TimerAction, aggregateName: string) =>
    promisify(() => {
      const scheduleActions = getScheduleActions(aggregateName);
      const { timer } = scheduleAction;
      const scheduleActionIndex = scheduleActions.findIndex(
        (scheduleAction) => scheduleAction.timer === timer
      );
      scheduleActions.splice(scheduleActionIndex, 1, scheduleAction);
      setScheduleActions(aggregateName, scheduleActions);
    });

  const addScheduleAction = (row: ScheduleActionRow, recordName: string) =>
    promisify(() => {
      const scheduleActions = getScheduleActions(recordName);
      const { timer, startTime, repeat, days, endTime } = row;
      const passIn: TimerAction = { timer };
      // the following properties should not be in the json if not in the scheduleActionRow
      if (startTime) passIn.startTime = startTime;
      if (repeat) passIn.repeat = repeat;
      if (days) passIn.days = days;
      if (repeat !== 'Daily' && endTime) passIn.endTime = endTime;
      const scheduleActionIndex = scheduleActions.findIndex(
        (scheduleAction) => scheduleAction.timer === timer
      );
      if (scheduleActionIndex !== -1) {
        throw new Error(
          `A Schedule named "${timer}" under the "${recordName}" record already exists.`
        );
      }
      scheduleActions.push(passIn);
      setScheduleActions(recordName, scheduleActions);
    });

  const deleteScheduleAction = (recordName: string, scheduleActionTimer: string) =>
    promisify(() => {
      const scheduleActions = getScheduleActions(recordName);
      const scheduleActionIndex = scheduleActions.findIndex(
        (scheduleAction) => scheduleAction.timer === scheduleActionTimer
      );
      scheduleActions.splice(scheduleActionIndex, 1);
      setScheduleActions(recordName, scheduleActions);
    });

  const moveScheduleAction = (
    recordName: string,
    scheduleActionIndex: number,
    direction: 'UP' | 'DOWN'
  ) =>
    promisify(() => {
      const scheduleActions = getScheduleActions(recordName);
      const currAction = scheduleActions[scheduleActionIndex];
      scheduleActions.splice(scheduleActionIndex, 1);
      const increment = direction === 'UP' ? -1 : 1;
      scheduleActions.splice(scheduleActionIndex + increment, 0, currAction);
      setScheduleActions(recordName, scheduleActions);
    });

  const updateNavigations = (navigations: NavigationButtons) =>
    promisify(() => {
      setNavigations(navigations as NavigationButtons);
    });

  return {
    addNewAggregateUICustomization,
    deleteAggregateUICustomization,
    addFabAction,
    addImportAction,
    addActionButtons,
    updateFabAction,
    deleteFabAction,
    updateImportAction,
    deleteImportAction,
    moveImportAction,
    moveFabAction,
    addActionButton,
    updateActionButton,
    deleteActionButton,
    moveActionButton,
    updateCardDefinition,
    reOrderFabActionRow,
    reOrderActionButton,
    updateAggregateUICustomization,
    updateScheduleAction,
    addScheduleAction,
    deleteScheduleAction,
    moveScheduleAction,
    updateNavigations,
  };
};
