import React, { useCallback, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { MobileUIConfig, WebUIConfig } from '@terragotech/gen5-config-lib';
import { useConfig } from 'context/ConfigContext';
import { successMsg } from 'components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import { SymbolProps } from '../SymbolContext';
import { useSymbolValue } from '../SymbolContext';
import { configType, SYMBOL_LEGEND_KEY } from 'utils/Utils';
import { UIConfigType } from 'utils/types';
import { useBlocker } from 'react-router-dom';
import useRouteBlocker from 'common/useBlocker';
import useCommonStyles from 'views/useCommonStyles';
import PageHeader from 'views/components/PageHeader';
import SymbolLegendView from './SymbolLegendView';

const SymbolLegend: React.FC = () => {
  const commonClasses = useCommonStyles();
  const { setSelectedSymbol } = useSymbolValue();
  const { config, configDetails, setConfig } = useConfig();
  const [UIConfigObj, setUIConfigObj] = useState<WebUIConfig | MobileUIConfig>(
    cloneDeep(config.webUIConfig)
  );
  const [symbolLegend, setSymbolLegend] = useState<SymbolProps[]>(UIConfigObj?.symbolLegend || []);
  const [symbolScale, setSymbolScale] = useState<number>(
    UIConfigObj?.symbolScaleFactor ? Number((UIConfigObj?.symbolScaleFactor * 100).toFixed(2)) : 100
  );
  useEffect(() => {
    const currentConfigState = configDetails[SYMBOL_LEGEND_KEY];
    if (!currentConfigState) {
      setUIConfigObj(cloneDeep(config.webUIConfig));
    } else {
      if (currentConfigState === configType.ALL || currentConfigState === configType.WEB) {
        const clonedConfig = cloneDeep(config.webUIConfig);
        setUIConfigObj(clonedConfig);
        setSymbolLegend(clonedConfig?.symbolLegend);
        setSymbolScale(
          clonedConfig?.symbolScaleFactor
            ? Number((clonedConfig?.symbolScaleFactor * 100).toFixed(2))
            : 100
        );
      } else {
        const clonedConfig = cloneDeep(config.mobileUIConfig);

        setUIConfigObj(clonedConfig);
        setSymbolLegend(clonedConfig?.symbolLegend as SymbolProps[]);
        setSymbolScale(
          clonedConfig?.symbolScaleFactor
            ? Number((clonedConfig?.symbolScaleFactor * 100).toFixed(2))
            : 100
        );
      }
    }
  }, [configDetails, config]);

  const handleSave = () => {
    const configCopy = cloneDeep(config);
    const currentConfig = configDetails[SYMBOL_LEGEND_KEY];
    const updatedSymbolLegend = symbolLegend.map((symbol) => ({
      name: symbol.name,
      symbolKey: symbol.symbolKey,
    }));
    const updatedSymbolScale = symbolScale / 100;
    const updateConfig = (configKey: UIConfigType) => {
      configCopy[configKey].symbolLegend = updatedSymbolLegend;
      configCopy[configKey].symbolScaleFactor = updatedSymbolScale;
    };

    if (currentConfig.type === configType.ALL.type) {
      updateConfig('mobileUIConfig');
      updateConfig('webUIConfig');
    } else {
      updateConfig(currentConfig.config);
    }
    setConfig(configCopy);
    successMsg('Branding configuration has been successfully saved');
  };
  const classes = useStyles();

  const handleAddSymbol = useCallback(
    (symbol: object) => {
      setSymbolLegend([...symbolLegend, symbol as SymbolProps]);
    },
    [symbolLegend, setSymbolLegend]
  );

  const handleDeleteSymbol = useCallback(
    (symbol: object) => {
      setSymbolLegend(symbolLegend.filter((item) => item.name !== (symbol as SymbolProps).name));
      setSelectedSymbol(null);
    },
    [symbolLegend, setSymbolLegend, setSelectedSymbol]
  );

  const handleUpdateSymbol = useCallback(
    (symbol: object, oldSymbol: object) => {
      if (oldSymbol) {
        const symbolLegendCopy = [...symbolLegend];
        const index = symbolLegendCopy.findIndex(
          (item) => item.name === (oldSymbol as SymbolProps).name
        );
        symbolLegendCopy[index] = symbol as SymbolProps;
        setSymbolLegend(symbolLegendCopy);
      }
    },
    [symbolLegend, setSymbolLegend]
  );
  
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname !== nextLocation.pathname) {
      const isDirty =
        !isEqual(UIConfigObj.symbolScaleFactor, symbolScale / 100) ||
        !isEqual(UIConfigObj.symbolLegend, symbolLegend);
      return isDirty;
    }
    return false;
  });

  useRouteBlocker({ blocker, onSave: handleSave });

  return (
    <Box className={`${commonClasses.innerContainer} ${classes.container}`}>
      <PageHeader
        title="Symbol Legend"
        icon={faSave}
        configKey={SYMBOL_LEGEND_KEY}
        buttonText="Save"
        onSave={handleSave}
      />
      <SymbolLegendView
        {...{
          symbolLegend,
          handleAddSymbol,
          handleUpdateSymbol,
          handleDeleteSymbol,
          symbolScale,
          setSymbolScale,
          setSymbolLegend,
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    height: '100%',
    overflow: 'auto',
  },
});
export default SymbolLegend;
