import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, makeStyles, styled as muiStyled, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { useConfig } from 'context/ConfigContext';
import { isEqual, isNil } from 'lodash';
import { toSplicedArray } from '@terragotech/gen5-shared-utilities';

interface TGColorPaletteProps {
  title?: string;
  value: string[];
  onChange?: (value: string[]) => void;
  classes?: {
    input?: string;
    label?: string;
    summary?: string;
    container?: string;
    picker?: string;
    palette?: string;
  };
  disabled?: boolean;
  summary?: string;
}

const Container = muiStyled(Box)({
  alignItems: 'center',
  position: 'relative',
});
const Input = styled.input`
  position: absolute;
  top: -8px;
  left: 2px;
  width: 0px;
  height: 0px;
  z-index: 1;
`;

const MAX_COLORS = 10 as const; // TODO: [S5-3587] Temp limit until UX design is approved and implemented.

export const TGColorPalette: FC<TGColorPaletteProps> = ({
  title,
  value,
  onChange,
  classes,
  disabled,
  summary,
}) => {
  const clx = useStyles();
  const [colors, setColors] = useState(value);
  const colorPickersRef = useRef<Array<HTMLInputElement | null>>([]);
  const { config: { webUIConfig: { theme: { primary } } } } = useConfig();

  const canAddColor = useMemo(() => !disabled && colors.length < MAX_COLORS, [disabled, colors.length]);

  useEffect(() => {
    if (!isEqual(value, colors)) {
      setColors(value);
    }
  }, [value]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    colorPickersRef.current = colorPickersRef.current.slice(0, colors.length);
    onChange?.(colors);
  }, [colors]); // eslint-disable-line react-hooks/exhaustive-deps

  const addPaletteColor = useCallback(() => {
    setColors(prev => [...prev, prev[prev.length - 1] ?? primary]);
  }, [setColors, primary]);

  const swapPaletteColors = useCallback((fromIndex: number, toIndex: number) => {
    if (fromIndex !== toIndex && fromIndex >= 0 && fromIndex < colors.length && toIndex >= 0 && toIndex < colors.length) {
      setColors(prev => {
        const newColors = [...prev];
        [newColors[fromIndex], newColors[toIndex]] = [newColors[toIndex], newColors[fromIndex]];
        return newColors;
      });
    }
  }, [setColors, colors.length]);

  const deletePaletteColor = useCallback((index: number) => {
    setColors(prev => toSplicedArray(prev, index, 1));
  }, [setColors]);

  const onChangeColor = useCallback((value: string, index: number) => {
    setColors(prev => toSplicedArray(prev, index, 1, value));
  }, [setColors]);

  const onPaletteClick = (index: number) => {
    colorPickersRef?.current[index]?.click();
  };

  return (
    <Container className={classes?.container}>
      <Typography className={`${clx.labelTitle} ${classes?.label}`}>{title}</Typography>
      <Typography className={`${clx.labelSummary} ${classes?.summary}`} variant="body2">{summary}</Typography>
      <Box className={clx.formGroup}>
        {colors.map((color, i) => (
          <Fragment key={`paletteColor-${i}`}>
            <Box className={clx.inputContainer}>
              <Box
                className={`
                  ${clx.button}
                  ${clx.paletteColorButton}
                  ${i === 0 ? clx.buttonLeft : ''}
                  ${!canAddColor && (i === colors.length - 1) ? clx.buttonRight : ''}
                  ${disabled ? clx.buttonDisabled : ''}
                  ${classes?.picker}
                `}
                onClick={() => onPaletteClick(i)}
              >
                <Box
                  className={`${clx.colorPalette} ${classes?.palette}`}
                  style={{ backgroundColor: color }}
                />
              </Box>
              <Input
                ref={element => {
                  if (isNil(colorPickersRef.current[i])) {
                    colorPickersRef.current[i] = element;
                  }
                  return colorPickersRef.current[i];
                }}
                className={classes?.input}
                type="color"
                value={color}
                onChange={(e) => onChangeColor(e.target.value, i)}
                disabled={disabled}
              />
              {!disabled && (
                <Box className={`${clx.actionButtonContainer}`} style={i > 0 ? { left: -18 } : undefined}>
                  {i > 0 && (
                    <Box className={`${clx.button} ${clx.actionButton} ${clx.buttonRound} ${clx.swapButton}`} onClick={() => swapPaletteColors(i, i - 1)}>
                      <i className="fas fa-right-left" />
                    </Box>
                  )}
                  <Box className={`${clx.button} ${clx.actionButton} ${clx.buttonBottom} ${clx.deleteButton}`} onClick={() => deletePaletteColor(i)}>
                    <i className="fas fa-trash" />
                  </Box>
                </Box>
              )}
            </Box>
          </Fragment>
        ))}
        {canAddColor && (
          <Box className={clx.inputContainer}>
            <Box className={`${clx.button} ${clx.actionButton} ${colors.length > 0 ? clx.buttonRight : clx.buttonRound} ${clx.addButton}`} onClick={addPaletteColor}>
              <i className="fas fa-plus" /><span>&nbsp;Add</span>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

const useStyles = makeStyles({
  labelTitle: {
    transform: 'translate(0, 0)',
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 0)',
      color: colors.black,
    },
  },
  labelSummary: {
    fontSize: 14,
    color: colors.black54,
    fontWeight: 400,
    lineHeight: '100%',
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    top: 20,
  },
  button: {
    border: `1px solid ${colors.black15}`,
    backgroundColor: colors.lotion,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 11,
    top: -9,
    cursor: 'pointer',
  },
  buttonDisabled: {
    cursor: 'initial',
  },
  paletteColorButton: {
    width: 70,
    height: 36,
    border: `1px solid ${colors.black15}`,
    backgroundColor: colors.lotion,
  },
  buttonRound: {
    borderRadius: 5,
  },
  buttonLeft: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  buttonRight: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  buttonBottom: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  actionButton: {
    width: 23,
    height: 23,
    '& > i': {
      fontSize: 8,
    }
  },
  swapButton: {
    top: -16,
  },
  deleteButton: {
    top: -10,
  },
  addButton: {
    width: 70,
    height: 36,
    '& > i': {
      fontSize: 12,
    }
  },
  actionButtonContainer: {
    width: 70,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'relative',
  },
  colorPalette: {
    backgroundColor: colors.black,
    width: '75%',
    height: '70%',
    borderRadius: 5,
  },
  formGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingLeft: 80,
    },
  },
});
