import RadioButton from './Radio';
import NumberInputControl from './NumberInput';
import TextAreaControl from './TextArea';
import BarcodeControl from './Barcode';
import SelectControl from './Select';
import CheckboxControl from './Checkbox';
import GroupControl from './GroupControl';
import TextInputControl from './TextInput';
import LocationInput from './LocationInput';
import PolylineInput from './PolylineInput';
import MapAggregateSelectorInput from './MapAggregateSelectorInput';
import TextHeader from './TextHeader';
import MediaUpload from './MediaUpload';
import {
  V2GroupComponentDef,
  V2TextTemplateComponent,
  V2NumberTemplateComponent,
  V2CurrencyTemplateComponent,
  V2SingleSelectComponent,
  V2MultiSelectComponent,
  V2BarcodeComponent,
  V2TextHeaderComponent,
  V2MediaComponent,
  V2LocationComponent,
  V2MapAggregateSelectorComponent,
  V2PolylineComponent,
  V2MediaFileComponent,
} from '@terragotech/form-renderer';
import { FormControlProps, NavigationControlProps, NestedFormControlProps } from '../FormTypes';
import MediaFileUpload from './MediaFileUpload';
import Navigation from './Navigation';
import { WebNavigation } from '@terragotech/gen5-config-lib';

interface FormBuilderControlMap {
  group?: React.ComponentType<FormControlProps<V2GroupComponentDef>>;
  computed?: React.ComponentType<NestedFormControlProps<V2TextTemplateComponent>>;
  textInput?: React.ComponentType<NestedFormControlProps<V2TextTemplateComponent>>;
  textArea?: React.ComponentType<NestedFormControlProps<V2TextTemplateComponent>>;
  date?: React.ComponentType<NestedFormControlProps<V2TextTemplateComponent>>;
  time?: React.ComponentType<NestedFormControlProps<V2TextTemplateComponent>>;
  numberInput?: React.ComponentType<NestedFormControlProps<V2NumberTemplateComponent>>;
  currency?: React.ComponentType<NestedFormControlProps<V2CurrencyTemplateComponent>>;
  radio?: React.ComponentType<NestedFormControlProps<V2SingleSelectComponent>>;
  select?: React.ComponentType<NestedFormControlProps<V2SingleSelectComponent>>;
  checkbox?: React.ComponentType<NestedFormControlProps<V2MultiSelectComponent>>;
  barcode?: React.ComponentType<NestedFormControlProps<V2BarcodeComponent>>;
  textheader?: React.ComponentType<NestedFormControlProps<V2TextHeaderComponent>>;
  signature?: React.ComponentType<NestedFormControlProps<V2MediaComponent>>;
  imageupload?: React.ComponentType<NestedFormControlProps<V2MediaComponent>>;
  fileupload?: React.ComponentType<NestedFormControlProps<V2MediaFileComponent>>;
  videoupload?: React.ComponentType<NestedFormControlProps<V2MediaComponent>>;
  audioupload?: React.ComponentType<NestedFormControlProps<V2MediaComponent>>;
  location?: React.ComponentType<NestedFormControlProps<V2LocationComponent>>;
  polyline?: React.ComponentType<NestedFormControlProps<V2PolylineComponent>>;
  mapAggregateSelector?: React.ComponentType<
    NestedFormControlProps<V2MapAggregateSelectorComponent>
  >;
  navigation?: React.ComponentType<NavigationControlProps<WebNavigation>>;
}

export const controlMap: FormBuilderControlMap = {
  group: GroupControl,
  computed: TextInputControl,
  textInput: TextInputControl,
  textArea: TextAreaControl,
  date: TextInputControl,
  time: TextInputControl,
  numberInput: NumberInputControl,
  currency: undefined,
  radio: RadioButton,
  select: SelectControl,
  checkbox: CheckboxControl,
  barcode: BarcodeControl,
  textheader: TextHeader,
  signature: undefined,
  imageupload: MediaUpload,
  fileupload: MediaFileUpload,
  videoupload: MediaUpload,
  audioupload: MediaUpload,
  location: LocationInput,
  polyline: PolylineInput,
  mapAggregateSelector: MapAggregateSelectorInput,
  navigation: Navigation,
};
export { default as FormFrame } from './FormFrame';
