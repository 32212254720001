import { faArrowDownShortWide, faArrowUpShortWide, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';
import { colors } from 'utils/colors';
import { RECORD_SEARCH_BAR_HEIGHT } from 'utils/Utils';

interface Props {
  isAsc: boolean;
  handleSort: () => void;
  setSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
}

const SearchBar = ({ isAsc, handleSort, searchText, setSearchText }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <TextField
        autoFocus
        placeholder="Search"
        fullWidth
        className={classes.input}
        value={searchText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleSort}>
              <FontAwesomeIcon
                icon={isAsc ? faArrowUpShortWide : faArrowDownShortWide }
                className={classes.sortIcon}
              />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        onChange={(e) => {
          e.stopPropagation();
          setSearchText(e.target.value);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: RECORD_SEARCH_BAR_HEIGHT,
    borderBottom: `1px solid ${colors.black10}`,
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
    '& .MuiInput-root': {
      height: RECORD_SEARCH_BAR_HEIGHT,
      '& input': {
        height: 17,
        padding: '10px 12px 10px 5px',
        borderRight: `1px solid ${colors.black10}`,
      },
    },
    '& input::placeholder': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '100%',
    },
    '& .MuiInputAdornment-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 37,
      height: 37,
    },
    '& .MuiInputAdornment-positionStart': {
      padding: '13.5px 5px 13.5px 12px',
      '& svg': {
        width: 16,
        height: 16,
        padding: 2,
      },
      marginRight: 0,
    },
    '& .MuiInputAdornment-positionEnd': {
      cursor: 'pointer',
      margin: '2.5px 4.5px',
    },
  },
  searchIcon: {
    color: colors.black35,
    position: 'absolute',
    fontSize: 16,
    zIndex: 2,
  },
  sortIcon: {
    color: colors.black54,
    position: 'absolute',
    fontSize: 16,
    zIndex: 2,
  },
}));

export default SearchBar;
