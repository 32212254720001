import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Container, Theme, makeStyles, createStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import { NavigationButtons, WebNavigation } from '@terragotech/gen5-config-lib';
import { NavigationDefWithName, NavigationComponentProps } from './Navigations';
import { NavigationList } from './NavigationList';
import { useMapperRefChanger } from 'utils/useMapperRefChanger';
import { generateNavigationName } from 'pages/aggregates/utils/navigationUtils';

interface NavigationProps {
  navDefinition: NavigationButtons;
  setNavDefinition: (val: NavigationButtons) => void;
  navEditedData?: null | NavigationDefWithName;
  setNavEditedData?: (val: NavigationDefWithName | null) => void;
  navEditedComponent?: NavigationComponentProps | null;
  setNavEditedComponent?: (val: NavigationComponentProps | null) => void;
  handleSelectedItem: (
    event: React.MouseEvent | null,
    navComponentName: string,
    subMenu?: string,
    item?: NavigationDefWithName
  ) => void;
  selectNextComponent?: (
    droppableId: string,
    index: number,
    componentDef: NavigationButtons,
    navDef?: NavigationButtons
  ) => void;
  editItem: (component: WebNavigation, name: string, droppableId: string, index: number) => void;
  deleteItem: (id: string, droppableId: string, index: number) => void;
  hasError?: boolean;
  error?: string;
  focusedItem: string;
  setExistingLabelError?: (val: boolean) => void;
  setEmptyLabelError?: (val: boolean) => void;
  setEmptyIconError?: (val: boolean) => void;
  setEmptyLinkError?: (val: boolean) => void;
}

export const emptySelectedItems = {
  order: [],
  components: {},
};

export const NavigationEditor: React.FC<NavigationProps> = ({
  navDefinition,
  setNavDefinition,
  navEditedData,
  setNavEditedData,
  navEditedComponent,
  setNavEditedComponent,
  handleSelectedItem,
  selectNextComponent,
  editItem,
  deleteItem,
  hasError,
  error,
  focusedItem,
  setExistingLabelError,
  setEmptyLabelError,
  setEmptyIconError,
  setEmptyLinkError,
}) => {
  const classes = useStyles();
  const boxRef = useRef<HTMLDivElement>(null);
  const mapperRefChanger = useMapperRefChanger();
  const [selectedItems, setSelectedItems] = useState<NavigationButtons>(emptySelectedItems);
  const [refresh, setRefresh] = useState(true);
  const [treeShake, setTreeShake] = useState<number>(0);

  const scrollDown = () => {
    if (boxRef.current) {
      const scrollHeight = boxRef.current.scrollHeight;
      const clientHeight = boxRef.current.clientHeight;
      const scrollTop = scrollHeight - clientHeight;
      boxRef.current.scrollTop = scrollTop > 0 ? scrollTop : 0;
    }
  };

  useEffect(() => {
    if (treeShake) {
      scrollDown();
    }
  }, [treeShake]);

  useEffect(() => {
    if (!refresh) {
      setRefresh(true);
    }
  }, [refresh]);

  const handleItem = (obj: NavigationDefWithName) => {
    const droppableId = obj?.droppableId === 'navigation' ? '' : obj?.droppableId;
    handleSelectedItem(null, obj.name, droppableId, obj);
  };

  const moveItemFromNavigations = (result: DropResult) => {
    if (!result.destination) return;
    const reorderItemInNavigations = () => {
      navs.order.splice(sourceIndex, 1);
      navs.order.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId: 'navigation',
        index,
      });
    };
    const addItemToSubMenu = () => {
      dropComponents[name] = item;
      dropOrder.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId,
        index,
      });
    };
    const removeItemFromNavigations = () => {
      navs.order.splice(sourceIndex, 1);
      delete navs.components[result.draggableId];
    };
    const getObjFromId = (id: string) => {
      return id.split('.').reduce((acc: any, eachId: string) => {
        return acc && acc.components && acc.components[eachId]
          ? acc.components[eachId].subMenu
          : undefined;
      }, navs);
    };

    let navs = cloneDeep(navDefinition);
    const { droppableId, index } = result.destination;
    const { index: sourceIndex } = result.source;
    const name = navs.order[sourceIndex];
    if (setNavEditedComponent && navEditedComponent && name === navEditedComponent.name) {
      setNavEditedComponent({
        ...navEditedComponent,
        index: index,
        droppableId: droppableId,
      });
    }
    const item = navs.components[name];
    if (droppableId === 'navigation') {
      reorderItemInNavigations();
      return setNavDefinition(navs);
    }
    const destObj = getObjFromId(droppableId);
    const level = droppableId.split('.').length;
    const { order: dropOrder, components: dropComponents } = destObj;
    item.level = level;
    if (dropOrder.includes(name)) {
      return errorMsg(
        'An element with the same label already exists in this sub menu. Rename it before drag.'
      );
    }
    removeItemFromNavigations();
    addItemToSubMenu();
    navs = mapperRefChanger.moveFormReferences(navs, name, undefined, droppableId);
    setNavDefinition(navs);
  };

  const moveItemFromSubMenu = (result: DropResult) => {
    if (!result.destination) return;
    const removeItemFromSubMenu = () => {
      sourceOrder.splice(sourceIndex, 1);
      delete sourceComponents[result.draggableId];
      handleItem({
        ...item,
        name,
        droppableId,
        index,
      });
    };
    const addItemToSubMenu = () => {
      dropComponents[name] = item;
      dropOrder.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId,
        index,
      });
    };
    const addItemToNavigations = () => {
      navs.components[name] = item;
      navs.order.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId: 'navigation',
        index,
      });
    };
    const getObjFromId = (id: string) => {
      return id.split('.').reduce((acc: any, eachId: string) => {
        return acc && acc.components && acc.components[eachId]
          ? acc.components[eachId].subMenu
          : undefined;
      }, navs);
    };

    let navs = cloneDeep(navDefinition);
    const { droppableId, index } = result.destination;
    const { droppableId: sourceId, index: sourceIndex } = result.source;
    const sourceObj = sourceId.indexOf('.')
      ? getObjFromId(sourceId)
      : (navs.components[sourceId] as WebNavigation).subMenu;
    const { order: sourceOrder, components: sourceComponents } = sourceObj;
    const name = sourceOrder[sourceIndex];
    const item = sourceComponents[name];
    if (droppableId === 'navigation') {
      if (navs.order.includes(name))
        return errorMsg('An element with the same label already exists. Rename it before drag.');
      item.level = 0;
      removeItemFromSubMenu();
      addItemToNavigations();
      navs = mapperRefChanger.moveFormReferences(navs, name, sourceId);
      setNavDefinition(navs);
      return;
    }
    const destObj = droppableId.indexOf('.')
      ? getObjFromId(droppableId)
      : (navs.components[droppableId] as WebNavigation).subMenu;

    const { order: dropOrder, components: dropComponents } = destObj;
    const level = droppableId.split('.').length;
    item.level = level;
    if (droppableId === sourceId) {
      removeItemFromSubMenu();
      addItemToSubMenu();
    } else {
      if (dropOrder.includes(name))
        return errorMsg(
          'An element with the same label already exists in the sub menu. Rename it before drag.'
        );
      removeItemFromSubMenu();
      addItemToSubMenu();
    }
    navs = mapperRefChanger.moveFormReferences(navs, name, sourceId, droppableId);
    setNavDefinition(navs);
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!source || !destination) return;
    if (source.droppableId === 'navigation') {
      moveItemFromNavigations(result);
    } else {
      moveItemFromSubMenu(result);
    }
  };

  const addItem = (e: React.MouseEvent, type: string) => {
    const navs = cloneDeep(navDefinition);
    const name = generateNavigationName(navs.order);
    const item = {
      type: 'navigation' as const,
      label: name,
      icon: '',
      conditionalMap: { outputDefinition: { outputNode: 'NAVIGATION_OPTIONS' } },
      subMenu: emptySelectedItems,
      level: 0,
    };
    navs.components[name] = item;
    navs.order.push(name);
    setNavDefinition(navs);
    handleSelectedItem(e, name, undefined, {
      ...item,
      name,
      droppableId: 'navigation',
      index: navs.order.length - 1,
    });

    if (type === 'navigation') {
      setTreeShake((x) => x + 1);
    }
  };

  const OuterBox = treeShake % 2 ? Container : Box;
  return refresh ? (
    <OuterBox className={classes.container} ref={boxRef}>
      {navDefinition?.order?.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <FlexRowContainer>
            <Box className={classes.navContainer}>
              <Droppable droppableId="navigation">
                {(droppableProvided) => (
                  //@ts-ignore
                  <NavigationInner
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    <NavigationList
                      navDefinition={navDefinition}
                      setNavDefinition={setNavDefinition}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      handleSelectedItem={handleSelectedItem}
                      hasError={hasError}
                      error={error}
                      editItem={editItem}
                      deleteItem={deleteItem}
                      focusedItem={focusedItem}
                      navEditedData={navEditedData}
                      navEditedComponent={navEditedComponent}
                      setNavEditedData={setNavEditedData}
                      selectNextComponent={selectNextComponent}
                      setExistingLabelError={setExistingLabelError}
                      setEmptyLabelError={setEmptyLabelError}
                      setEmptyIconError={setEmptyIconError}
                      setEmptyLinkError={setEmptyLinkError}
                    />
                    {droppableProvided.placeholder}
                  </NavigationInner>
                )}
              </Droppable>
            </Box>
          </FlexRowContainer>
        </DragDropContext>
      )}
      <Button
        className={classes.emptyButtonCtn}
        onClick={(e) => {
          if (hasError) {
            return errorMsg(error);
          }
          addItem(e, 'navigation');
        }}
      >
        <Box className={classes.plusBox}>
          <FontAwesomeIcon icon={faPlus} className={classes.plusIcon} />
        </Box>
      </Button>
    </OuterBox>
  ) : null;
};

const FlexRowContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`;

const NavigationInner = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px 13px',
      overflow: 'auto',
    },
    navContainer: {
      width: '100%',
    },
    emptyButtonCtn: {
      width: '100%',
      height: 68,
      padding: '15px 0',
      border: `0.05px solid ${colors.black10}`,
      background: colors.white,
      borderRadius: 0,
    },
    plusBox: {
      width: 29,
      height: 29,
      borderRadius: 29 / 2,
      border: `1px dashed ${colors.black25}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    plusIcon: {
      fontSize: 15,
      color: theme.palette.primary.main,
    },
  })
);
