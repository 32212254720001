import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { TextInputTG as TextInput, TGSelect } from '../../views/components/formElements';
import { V2ChartComponent, V2ChartTypeOptions } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';
import { isEmpty, isEqual } from 'lodash';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import usePageRedirect from './hooks/usePageRedirect';
import { nameHelperTxt, useCustomPageStyles } from './commonStyles';
import ModalFrame from './ModalFrame';
import MapperItem from '../../components/FormDialog/MapperItem';
import { useConfirmDialog } from '../../context/ConfirmContext';
import { CONFIRMATION, expandPath } from '../../utils/Utils';
import { BarChartEditSection, ChartEditSectionProps, LineChartEditSection, PieChartEditSection, ScatterPlotChartEditSection } from './ChartEditSections';
import { getChartObjectFromChartType } from '../../pages/aggregates/utils/V2PageTemplateToJsonSchema';
import TGPageChartField, { TGChartFieldProps } from '@terragotech/gen5-shared-components/dist/components/PageFields/TGPageChartField';
import { colors } from 'utils/colors';
import { useConfig } from 'context/ConfigContext';

export type TextTemplateWithName = V2ChartComponent & {
  name: string;
  droppableId: string;
};

interface ChartEditFormProps {
  onSubmit: (result: TextTemplateWithName) => void;
  onClose: () => void;
  component: TextTemplateWithName & { height?: string };
}

export const chartTypeOptions: Record<V2ChartComponent['chartType'], string> = {
  bar:          'Bar Graph',
  line:         'Line Graph',
  pie:          'Pie Chart',
  scatterPlot:  'Scatter Plot',
};

const ChartEditSectionMap: { [K in V2ChartComponent['chartType']]?: React.FC<ChartEditSectionProps<K>> } = {
  bar: BarChartEditSection,
  line: LineChartEditSection,
  pie: PieChartEditSection,
  scatterPlot: ScatterPlotChartEditSection,
};

export const ChartEditForm: React.FC<ChartEditFormProps> = ({ onSubmit, onClose, component }) => {
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [chartType, setChartType] = useState(component.chartType);
  const [chartOptions, setChartOptions] = useState(component.chartOptions);
  const [height, setHeight] = useState(component.height);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [computedMap, setComputedMap] = useState(() => {
    const map = component.computedMap || undefined;
    if (map && map.nodes && map.nodes['OUTPUT']) {
      map.nodes['OUTPUT'].config = { objectSchema: 'COMPUTED' };
    }
    return map;
  });
  const [computedOpen, setComputedOpen] = useState(false);
  const classes = useCustomPageStyles();
  const pageSchemas = usePageSchemas();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const [existingNameError, setExistingNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [emptyLabelError, setEmptyLabelError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const { openConfirmation } = useConfirmDialog();
  const styles = useStyles();
  const { config: { webUIConfig: { colorPalettes } } } = useConfig();

  useEffect(() => {
    setExistingNameError(checkDuplicatePageName(name, component, pageDefinition.elements));
    setEmptyNameError(checkEmptyText(name));
  }, [name, component, pageDefinition.elements]);

  useEffect(() => {
    setEmptyLabelError(checkEmptyText(label));
  }, [label]);

  const checkEmptyText = (text: string) => {
    return text.trim() === '';
  };

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'page'
      ? []
      : expandPath(parts, pageSchemas.FORM.schema.properties as JSONSchema6);

  let pageValue = { value: { type: 'string' } };
  for (let i = 0; i < t.length; i++) {
    pageValue = { ...pageValue, ...t[i] };
  }

  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: pageValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...pageSchemas,
  };

  const computedSchema: LocalSchemaDefinition = useMemo(
    () => ({
      ...pageSchemas,
      COMPUTED: {
        schemaLabel: 'Chart Data',
        schema: {
          type: 'object',
          properties: {
            result: getChartObjectFromChartType(chartType, component.name),
          },
        },
      },
    }),
    [chartType, component.name, pageSchemas]
  );

  const handleClearDisplayMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  };

  const handleClearComputedMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setComputedMap(undefined);
    }
  };

  const doesComputedHaveValue = useCallback(() => {
    return !isEmpty(computedMap);
  }, [computedMap]);

  const getFormValues = () => ({
    type: component.type,
    droppableId: component.droppableId,
    name,
    label,
    ...({ chartType, chartOptions } as V2ChartTypeOptions<typeof chartType, typeof chartOptions>),
    ...(displayOptions && { displayOptions }),
    ...(computedMap && { computedMap }),
    ...(height && { height }),
  });

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () =>
    (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
    onClose();

  const handleSubmit = async () => {
    if (existingNameError || emptyNameError || emptyLabelError) {
      return;
    } else onSubmit(getFormValues());
  };

  const ChartOptionsSection = useMemo(() => ChartEditSectionMap[chartType], [chartType]);

  const chartPreviewValue = useMemo<Partial<TGChartFieldProps<typeof chartType>['value']> | undefined>(() => ({
    showSampleData: true,
    colors: colorPalettes?.find(x => x.name === chartOptions?.colorPalette)?.colors,
    // TODO: [S5-3587] Implement `chartOptions` and `value` type inferrences based on `chartType`.
  }), [colorPalettes, chartType, chartOptions?.colorPalette]);

  return (
    <ModalFrame
      {...{ name: component.name, type: component.type, classes, handleClose, handleSubmit }}
    >
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError || emptyNameError}
        helperText={emptyNameError ? 'Name cannot be empty' : existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        className={styles.textInputContainer}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      <TextInput
        id="Label"
        label="Label"
        error={emptyLabelError}
        helperText={emptyLabelError ? 'Label cannot be empty' : ''}
        value={label}
        onChange={(value) => setLabel(value || '')}
        className={styles.textInputContainer}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      <TGSelect
        id="chartType"
        label="Chart Type"
        value={chartTypeOptions[chartType]}
        options={chartTypeOptions}
        onChange={(value) => {
          setChartType(value as V2ChartComponent['chartType']);
          setChartOptions(undefined); // TODO: [S5-3487] Save existing options to temporary state, keyed by chart type.
        }}
        containerStyle={classes.textInput}
        labelStyle={classes.Root}
      />
      <Typography className={classes.text}>Additional Options</Typography>
      <TextInput
        id="Height"
        label="Height"
        placeholder="400"
        value={height}
        onChange={(value) => setHeight(value || '')}
        className={styles.textInputContainer}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      {!!ChartOptionsSection && (
        // TODO: [S5-3487] Temp fix for typing. Why is `ChartEditSectionProps.chartOptions` being treated as an intersection?
        <ChartOptionsSection
          chartOptions={chartOptions as any}
          setChartOptions={setChartOptions}
          optionStyles={{
            containerStyle: classes.textInput,
            labelStyle: classes.Root,
          }}
        />
      )}
      <div>
        {/* TODO: [S5-3587] Verbiage/tooltip so we don't infer a data-dependenant preview? "Chart Sample", "Chart Options Preview", etc. */}
        <Typography className={classes.text}>Chart Preview</Typography>
        <TGPageChartField
          // label="Preview"
          // info="This preview uses sample data, and does not evaluate the Chart Data mappings."
          height={`${Number(height) || 400}`}
          chartType={chartType}
          chartOptions={chartOptions}
          value={chartPreviewValue}
        />
      </div>
      <div className={styles.mapperContainer}>
        <MapperItem
          {...{
            onToggleMapper: setComputedOpen,
            isActive: doesComputedHaveValue(),
            clearMapper: handleClearComputedMapper,
            openDataMap: computedOpen,
            dataMap: computedMap,
            setDataMap: setComputedMap,
            localSchemaDefinition: computedSchema,
            title: 'Chart Data',
            mapScenario: 'COMPUTED_MAPPING',
            containerStyle: classes.mapperContainer,
          }}
        />
        <MapperItem
          {...{
            onToggleMapper: setConditionalOpen,
            isActive: displayOptions !== undefined,
            clearMapper: handleClearDisplayMapper,
            openDataMap: conditionalOpen,
            dataMap: displayOptions,
            setDataMap: setDisplayOptions,
            localSchemaDefinition: errorWarningSchemas,
            title: 'Display Options',
            mapScenario: 'DISPLAY_OPTIONS',
            containerStyle: classes.mapperContainer,
          }}
        />
      </div>
    </ModalFrame>
  );
};

const useStyles = makeStyles(theme => createStyles({
  textInputContainer: {
    margin: '10px 0',
    width: '100%',
  },
  previewLabel: {
    transform: 'translate(0, 0)',
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 0)',
      color: colors.black,
    },
  },
  mapperContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      flexBasis: '33.3%',
    },
  },
}));
