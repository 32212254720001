import React from 'react';
import { controlMap } from './MobileTheme';
import { Draggable } from 'react-beautiful-dnd';
import {
  V2GroupComponentDef,
  V2FormComponentDef,
  V2FormTemplate,
} from '@terragotech/form-renderer';
import IconButton from '@material-ui/core/IconButton';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { FormEditorEditedComponentProps } from '../../../../views/pages/records/components/commands/tabs/FormEditor';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import { CONFIRMATION } from '../../../../utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';

interface CommandFormListProps {
  formDefinition: V2FormTemplate;
  setFormDefinition: (val: V2FormTemplate) => void;
  selectedItems: V2FormTemplate;
  setSelectedItems: (val: V2FormTemplate) => void;
  lastPastedFormTemplate: V2FormTemplate | null;
  groupDragging: boolean;
  isImportCommand?: boolean;
  handleSelectedItem: (
    event: React.MouseEvent,
    formComponentName: string,
    group?: string,
    item?: V2FormComponentDefWithName
  ) => void;
  editItem: (
    component: V2FormComponentDef,
    name: string,
    droppableId: string,
    index: number
  ) => void;
  deleteItem: (id: string, droppableId: string, index: number) => void;
  focusedItem: string;
  formEditorEditedData?: null | V2FormComponentDefWithName;
  formEditorEditedComponent?: FormEditorEditedComponentProps | null;
  setFormEditorEditedData?: (val: V2FormComponentDefWithName | null) => void;
  hasError?: boolean;
  error?: string;
  isImport?: boolean;
  selectNextComponent?: (droppableId: string, index: number, componentDef: V2FormTemplate, formDef?: V2FormTemplate) => void;
  setExistingNameError?: (val: boolean) => void;
  setEmptyNameError?: (val: boolean) => void;
  setEmptyLabelError?: (val: boolean) => void;
}
export type V2FormComponentDefWithName = V2FormComponentDef & {
  name: string;
  droppableId: string;
  index?: number;
};

export const CommandFormList: React.FC<CommandFormListProps> = ({
  formDefinition,
  selectedItems,
  lastPastedFormTemplate,
  groupDragging,
  handleSelectedItem,
  editItem,
  deleteItem,
  focusedItem,
  setFormDefinition,
  formEditorEditedData,
  formEditorEditedComponent,
  setFormEditorEditedData,
  hasError,
  error,
  isImport,
  selectNextComponent,
  setExistingNameError,
  setEmptyNameError,
  setEmptyLabelError,
}) => {
  const classes = useStyles();
  const { openConfirmation } = useConfirmDialog();

  const onHandleDeleteItem = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    name: string,
    index: number
  ) => {
    e.stopPropagation();
    const props = CONFIRMATION.commandForm({ name });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      if (hasError) {
        setExistingNameError && setExistingNameError(false);
        setEmptyNameError && setEmptyNameError(false);
        setEmptyLabelError && setEmptyLabelError(false);
      }
      deleteItem(name, 'form', index);
    }
  };

  return (
    <>
      {formDefinition.order.map((name, index) => {
        const component = formDefinition.components[name] as V2GroupComponentDef;
        const Control = component && controlMap[component.type];
        if (Control) {
          return (
            <Draggable key={name} draggableId={name} index={index}>
              {(provided) => (
                <Box
                  //@ts-ignore
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={`${classes.panelItem} ${focusedItem === name ? classes.selected : ''}`}
                  onClick={(e) => {
                    if (hasError) {
                      return errorMsg(error);
                    }
                    handleSelectedItem(e, name);
                    editItem(component, name, 'form', index);
                  }}
                >
                  <Control
                    controlDefinition={component}
                    name={name}
                    isImport={isImport}
                    isDropDisabled={groupDragging}
                    focusedItem={focusedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={handleSelectedItem}
                    editItem={editItem}
                    formDefinition={formDefinition}
                    setFormDefinition={setFormDefinition}
                    pasted={lastPastedFormTemplate?.order.includes(name)}
                    lastPastedFormGroup={
                      lastPastedFormTemplate?.components[name] as V2GroupComponentDef
                    }
                    formEditorEditedData={formEditorEditedData}
                    formEditorEditedComponent={formEditorEditedComponent}
                    setFormEditorEditedData={setFormEditorEditedData}
                    hasError={hasError}
                    selectNextComponent={selectNextComponent}
                    setExistingNameError={setExistingNameError}
                    setEmptyNameError={setEmptyNameError}
                    setEmptyLabelError={setEmptyLabelError}
                  />
                  {focusedItem === name && (
                    <Box className={classes.buttonContainer}>
                      <IconButton
                        className={classes.trashButton}
                        component="span"
                        onClick={(e) => {
                          onHandleDeleteItem(e, name, index);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} className={classes.icon} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}
            </Draggable>
          );
        } else {
          console.log(`Couldn't find component: ${formDefinition.components[name]}`);
        }
        return null;
      })}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 18,
    },
    panelItem: {
      position: 'relative',
      background: colors.white,
      color: colors.title,
      boxShadow: `0px 1px 4px 0px ${colors.black15}`,
      border: '1px solid transparent',
      borderRadius: 5,
      overflow: 'hidden',
      marginBottom: 7,
      width: '100%',
    },
    trashButton: { color: colors.black54, padding: 10 },
    selected: {
      border: `1.5px solid ${theme.palette.primary.main}`,
    },
    buttonContainer: {
      position: 'absolute',
      top: 0,
      right: 4,
    },
  })
);
