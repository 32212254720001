import React, { useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import {
  AttributeBasedFilterSimpleOptionArrayType,
  AttributeBasedFilterSimpleOptionType,
} from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { AttributeBasedTextInput } from './AttributeBasedTextInput';
import ValuesIcon from '@material-ui/icons/DynamicFeed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCircleUser, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { categoryColors, colors } from 'utils/colors';
import { TextInputTG } from 'views/components/formElements';

interface ArrayOptionInputProps {
  value: AttributeBasedFilterSimpleOptionArrayType | undefined;
  onChange: (value: AttributeBasedFilterSimpleOptionArrayType | undefined) => void;
  allowValue?: boolean;
}

export const ArrayOptionInput: React.FC<ArrayOptionInputProps> = ({
  value,
  onChange,
  allowValue,
}) => {
  const classes = useStyle();
  const [tempValue, setTempValue] = useState<AttributeBasedFilterSimpleOptionArrayType | undefined>(
    value === '$$_GROUPS_$$' || value === '$$_ROLES_$$' || value === '$$_VALUES_$$'
      ? undefined
      : value
  );
  const handleAddValidOptionsEnum = () => {
    if (isArray(value)) {
      const copiedValue = cloneDeep(value);
      copiedValue.push('');
      onChange(copiedValue);
    }
  };

  const handleValidOptionsEnumChange = (val: string, index: number) => {
    if (isArray(value)) {
      const copiedValue = cloneDeep(value);
      copiedValue[index] = val;
      onChange(copiedValue);
    }
  };

  const handleDeleteValidOptionsEnum = (index: number) => {
    if (isArray(value)) {
      const copiedValue = cloneDeep(value);
      copiedValue.splice(index, 1);
      onChange(copiedValue);
    }
  };

  const handleToggleRoles = () => {
    if (value === '$$_ROLES_$$') {
      onChange(tempValue);
    } else {
      if (typeof value !== 'string') setTempValue(value);
      onChange('$$_ROLES_$$');
    }
  };
  const handleToggleValues = () => {
    if (value === '$$_VALUES_$$') {
      onChange(tempValue);
    } else {
      if (typeof value !== 'string') setTempValue(value);
      onChange('$$_VALUES_$$');
    }
  };

  const isArray = (
    value: AttributeBasedFilterSimpleOptionArrayType | undefined
  ): value is AttributeBasedFilterSimpleOptionType[] => Array.isArray(value);

  return (
    <ValidOptionsContainer>
      <InputContainer>
        {isArray(value) ? (
          value.map((item: AttributeBasedFilterSimpleOptionType, index) => (
            <InputRow key={index}>
              <TextInputContainer>
                <AttributeBasedTextInput
                  value={item as string}
                  onChange={(value) => handleValidOptionsEnumChange((value as string) || '', index)}
                  id={item as string}
                  allowValue={allowValue}
                />
              </TextInputContainer>
              {value.length > 1 && (
                <IconButton
                  title="Delete"
                  className={classes.trashButton}
                  component="span"
                  onClick={() => handleDeleteValidOptionsEnum(index)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </IconButton>
              )}
            </InputRow>
          ))
        ) : (
          // TODO: fix ID
          <TextInputTG id="id" value={value as string} className={classes.noMargin} disabled />
        )}
      </InputContainer>
      {value !== '$$_ROLES_$$' && value !== '$$_GROUPS_$$' && value !== '$$_VALUES_$$' && (
        <IconButton
          title="Add"
          component="span"
          onClick={handleAddValidOptionsEnum}
          className={`${classes.plusButton} ${
            isArray(value) && value.length === 1 ? classes.singleButton : ''
          }`}
        >
          <FontAwesomeIcon icon={faPlus} />
        </IconButton>
      )}
      {/* <IconButton
        title="Groups"
        component="span"
        onClick={handleToggleGroups}
        style={{
          color: value === '$$_GROUPS_$$' ? 'rgb(66, 165, 245)' : 'black',
        }}
      >
        <GroupIcon />
      </IconButton> */}
      <IconButton
        title="Roles"
        component="span"
        onClick={handleToggleRoles}
        className={`${classes.button} ${value === '$$_ROLES_$$' ? classes.activeButton : ''}`}
      >
        <FontAwesomeIcon icon={faCalendarCircleUser} />
      </IconButton>
      {allowValue && (
        <IconButton
          title="Values"
          component="span"
          onClick={handleToggleValues}
          className={`${classes.button} ${value === '$$_VALUES_$$' ? classes.activeButton : ''}`}
        >
          <ValuesIcon />
        </IconButton>
      )}
    </ValidOptionsContainer>
  );
};

const TextInputContainer = styled.div`
  margin: 4px 8px 4px 0;
  flex: 1;
`;

const InputRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ValidOptionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const useStyle = makeStyles((theme) => ({
  trashButton: { fontSize: 18, color: colors.black54, margin: '4px 0' },
  noMargin: { margin: 0 },
  singleButton: { marginLeft: 8 },
  plusButton: { fontSize: 18, color: categoryColors.defaultColor, marginLeft: 0 },
  activeButton: { color: `${theme.palette.primary.main} !important` },
  button: {
    color: categoryColors.defaultColor,
    fontSize: 18,
  },
}));
