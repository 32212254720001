import React, { useState, useEffect } from 'react';
import { ArrayOptionInput } from '../inputs/ArrayOptionInput';
import { commaSeparatedToArr } from '../utils/commaSeparatedToArr';
import { AttributeBasedTextInput } from '../inputs/AttributeBasedTextInput';
import {
  AttributeBasedFilterSimpleOptionType,
  AttributeBasedFilterSimpleOptionArrayType,
} from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { OperatorName } from '../defaults/defaultOperators';

export type QueryBuilderProperty =
  | AttributeBasedFilterSimpleOptionType
  | AttributeBasedFilterSimpleOptionArrayType;

const convertValue = (
  option: QueryBuilderProperty,
  operator: OperatorName,
  keyType: string | undefined
) =>
  (operator === 'INCLUDE' || (operator === 'EQUAL' && keyType === 'StringArray')) &&
  typeof option === 'string' &&
  option !== '$$_GROUPS_$$' &&
  option !== '$$_ROLES_$$' &&
  option !== '$$_VALUES_$$'
    ? commaSeparatedToArr(option)
    : option;

const getInputType = (operator: OperatorName, keyType: string | undefined) => {
  switch (true) {
    case operator === 'EQUAL' && keyType !== 'StringArray':
      return 'TextInput';
    case operator === 'EQUAL' && keyType === 'StringArray':
    case operator === 'INCLUDE':
      return 'ArrayInput';
    case operator === 'NULL':
    case operator === 'NOTNULL':
    default:
      return null;
  }
};

interface OptionEditorProps {
  option: QueryBuilderProperty;
  handleOnChange: (options: QueryBuilderProperty) => void;
  operator: OperatorName;
  keyType: string | undefined;
  allowValue?: boolean;
}

const OptionEditor: React.FC<OptionEditorProps> = ({
  operator,
  option,
  handleOnChange,
  keyType,
  allowValue,
}) => {
  const [value, setValue] = useState<QueryBuilderProperty | undefined>(
    convertValue(option, operator, keyType)
  );
  useEffect(() => {
    setValue(convertValue(option, operator, keyType));
  }, [operator, option, keyType]);

  switch (getInputType(operator, keyType)) {
    case 'TextInput':
      return (
        <AttributeBasedTextInput
          id={value as string}
          value={value as AttributeBasedFilterSimpleOptionType}
          onChange={(newValue) => {
            setValue(newValue);
            handleOnChange(newValue as AttributeBasedFilterSimpleOptionArrayType);
          }}          
          keyType={keyType}
          allowValue={allowValue}
        />
      );
    case 'ArrayInput':
      return (
        <ArrayOptionInput
          value={value as AttributeBasedFilterSimpleOptionArrayType}
          onChange={(newValue) => {
            setValue(newValue);
            handleOnChange(newValue as AttributeBasedFilterSimpleOptionArrayType);
          }}                    
          allowValue={allowValue}
        />
      );
    default:
      return null;
  }
};

export default OptionEditor;
