export const getAdditionalLabels = (): string[] => {
  return Array.from(new Set([...webLabels, ...mobileLabels]));
};

const webLabels = [
  '(Blanks)',
  '*You will get a notification when the file is uploaded. You can also check the status on the ‘Import History’ page.',
  '$__COUNT__$ $__RECORD_TYPE__$ Selected',
  '$__ERRORS__$ errors(s) found. Please correct and reupload corrected file to import file.',
  '$__FILES__$ File(s) Added',
  '$__INDEX__$ Repeat',
  '$__LABEL__$ will be updated',
  '$__LENGTH__$ added',
  '$__LENGTH__$ location(s) selected',
  '$__MODE__$ $__TYPE__$',
  '$__NAME__$ Dialog',
  '$__NAME__$ Id',
  '$__NAME__$ will be updated',
  '$__ROWS__$ Selected',
  '$__SEGMENTS__$ Segment(s), $__MEASUREMENT__$ $__CONFIG_UNITS__$',
  '$__TYPE__$ Jobs',
  '$__WARNINGS__$ warning(s) found. Please correct and reupload corrected file to import records without warning.',
  'About',
  'Access Analytics',
  'Access Advanced Logging',
  'Acres',
  'Action completed successfully',
  'Action failed',
  'Action',
  'Actions',
  'ACTIVE FILTERS ($__FILTERS__$)',
  'Add Audio',
  'Add Barcode',
  'Add Field',
  'Add File',
  'ADD IMAGE',
  'Add Item',
  'Add Location',
  'Add Role',
  'Add Signature',
  'Add User',
  'Add Video',
  'Add',
  'added',
  'Agree & Continue',
  'All $__DASHBOARDS__$',
  'All $__PROJECTS__$',
  'All changes will be lost',
  'All is pinned',
  'All Projects',
  'All Roles',
  'All Time',
  'All',
  'Allowed',
  'An import encountered an error.',
  'An import has been processed with errors',
  'An import has been processed with warnings',
  'An import has been processed',
  'An import has been submitted',
  'An unexpected error occurred during cancellation. Please try again.',
  'An unexpected error occurred during staging. Please try again.',
  'An unexpected error occurred during validation. Please cancel the import and try again.',
  'Analytics',
  'App Version',
  'Application was not able to fetch any data',
  'Applied Filters',
  'Apply',
  'Are you sure you want to delete this $__NAME__$?',
  'Are you sure you want to delete this image?',
  'Are you sure you want to delete those $__NAME__$?',
  'Are you sure you want to discard this file? The job will be cancelled, and the new records will not be updated.',
  'Are you sure you want to proceed?',
  'Are you sure you would like to delete this item?',
  'Are you sure?',
  'Attached Records',
  'Attributes',
  'Available Dashboards',
  'Back to Login',
  'Back',
  'BACK',
  'Barcode element label',
  'Barcode Entry Type',
  'Cancel Import',
  'Cancel',
  'Cancelled',
  'Capture device location',
  'CAPTURE IMAGE',
  'Card View',
  'Caution, $__LABEL__$ is not available for some of the selected records',
  'Caution',
  'Centimeters',
  'Change Password',
  'Change Table Layout',
  'Change the password for your username:',
  'Changes will be lost',
  'Check your device for an SMS code',
  'Choose which attributes appear in the asset table. You can drag columns to change their display order in the table.',
  'CI Build Number',
  'CI Build Time',
  'Clear All',
  'Clear Drawing',
  'Clear Selection',
  'Click on map to add location pin',
  'Click on the map to draw a polyline. Midpoints can also be clicked and dragged to create additional line segments.',
  'Close',
  'commandResolved',
  'Commit',
  'Committed',
  'Complete',
  'Complete with Issues',
  'Completed Jobs',
  'Completion Time',
  'Configure specific permissions for this user.',
  'Confirm',
  'Confirmation',
  'Contact Support',
  'Continue',
  'Coordinates are invalid',
  'Coordinates',
  'Copyright',
  'Could Not Complete',
  'Could not Load Data',
  'Current Password',
  'Currently there is not any accessible dashboards.',
  'Custom Date Range',
  'Dark',
  'DASHBOARDS',
  'Data Export Tracking',
  'Date',
  'Date/Time',
  'Degrees',
  'Delete $__NAME__$',
  'Delete the existing location pin(s) to create a new location.',
  'Delete User',
  'Deselect All',
  'Details',
  'Direction',
  'Discard',
  'Displayed Column Filter',
  'Done',
  'Double-click to complete the drawn shape.',
  'DOWNLOAD REVIEW',
  'Download Template',
  'Download',
  'Drag & Drop file here',
  'Drag & Drop image files here',
  'Drag and Drop files here',
  'Drag the pin to change the location',
  'Draw Shapes on the map to filter your data.',
  'Draw',
  'Drawing Enabled',
  'Edit Audio',
  'Edit Barcode',
  'Edit File',
  'Edit Image',
  'Edit Item',
  'Edit Location',
  'Edit Manually',
  'Edit Signature',
  'Edit Video',
  'EDIT',
  'Editor',
  'Email is a required field',
  'Email',
  'Enable Draw Mode',
  'End User License Agreement',
  'Enter date',
  'Enter Email',
  'Enter manually',
  'Enter number',
  'Enter text',
  'Enter the verification code that was sent to the email you entered in the previous step.',
  'Enter time',
  'Enter your email below to receive a verification code that will allow you to reset your password.',
  'Error, too many $__RECORD_TYPE__$ selected',
  'Error',
  'Error(s)',
  'Errors',
  'Exit',
  'Expand',
  'Export to CSV',
  'Export Type',
  'Failed to load asset',
  'Failed',
  'feet',
  'Feet',
  'File Capture Type',
  'File successfully uploaded',
  'File',
  'File(s) Added',
  'files',
  'Filter Records to Visible Map Extent',
  'First Name is a required field',
  'First Name',
  'Forgot Password?',
  'Forgot Password',
  'Form Level Error(s)',
  'Form Level Warning(s)',
  'French',
  'Hectares',
  'Hide $__CAPTION__$',
  'Hide',
  'History',
  'Home',
  'Horizontal',
  "Hold your device’s camera up to the barcode to scan",
  'Hybrid Satellite',
  'ID',
  'Ids',
  'Image Capture Type',
  'Import cancelled',
  'Import commit completed',
  'Import commit failed!',
  'Import committed',
  'Import contains errors',
  'Import encountered an error while committing',
  'Import file contained errors',
  'Import file contained warnings',
  'Import has already been cancelled',
  'Import has already been committed',
  'Import has been cancelled',
  'Import has not been processed yet',
  'Import History',
  'Import is being processed',
  'Import is being validated',
  'Import status updated',
  'Import submitted',
  'Import Type Not Selected',
  'Import Type',
  'Import updated',
  'Import',
  'Imported',
  'Importing',
  'Inches',
  'Instances',
  'Integration Type',
  'Invalid file selected',
  'Is Data Role?',
  'kilometers',
  'Kilometers',
  'Language',
  'Last Month',
  'Last Name is a required field',
  'Last Name',
  'Last Year',
  'Layers',
  'Layout',
  'Legal Disclaimer',
  'Legend image for $__LABEL__$',
  'Licenses Available',
  'Light',
  'Loading Data...',
  'Loading',
  'Location Coordinates',
  'location(s) selected',
  'Login to Your Account',
  'Login with $__PROVIDER__$',
  'Login with organization',
  'Login',
  'Logout',
  'Manage Map Services',
  'Manage roles and all server data',
  'Manage users',
  'Map Components',
  'Map Extent',
  'Map Labels',
  'Map Legend',
  'Map Type',
  'Map',
  'meters',
  'Meters',
  'miles',
  'Miles',
  'Millimeters',
  'Minimize',
  'minimum of 10 characters',
  'Modify Table Proportion',
  'Month to date',
  'More',
  'Multiple Location Pin Limit Reached',
  'Nauticalmiles',
  'Navigation',
  'NearMap',
  'Needs Action',
  'New import has been submitted',
  'New Password',
  'NEXT',
  'No $__COUNT__$ actions available',
  'No actions available',
  'No aggregate with given name $__NAME__$',
  'No asset found',
  'No cameras are detected on this device. Please connect one, and make sure your browser has allowed this page to access the camera',
  'No card definition found for this asset type',
  'No completed jobs',
  'No Dashboards',
  'No data to be shown',
  'No images available',
  'No Issues Found',
  'No legend information available for currently selected layers.',
  'No matching location found',
  'No matching records found',
  'No multi-select actions available',
  'No pending jobs',
  'No Placeholder',
  'No Records Attached',
  'No Rows To Show',
  'NO, CANCEL',
  'No',
  'Non selectable record',
  'non selectable records',
  'None attached',
  'None',
  'Not Yet Complete',
  'Nothing is pinned yet',
  'Nothing is sticky yet',
  'Number of Records',
  'Number of Segment(s)',
  'of',
  'Ok',
  'On',
  'Oops, the selected file is over the $__LIMIT__$ mb limit. Please choose a smaller file',
  'Oops, you have entered an incorrect username and/or password.',
  'or',
  'Organize Table',
  'Organize',
  'other records',
  'Password must match.',
  'Password Updated',
  'Password',
  'Pending Calculation...',
  'Pending Jobs',
  'Pending',
  'Permissions',
  'photo',
  'Pin Clustering',
  'Pinned',
  'placeholder',
  'Please login to your organizational account or email to continue.',
  'Please return to the login page and use your new password to log in.',
  'Please select an Import Type to upload a file.',
  'Please wait, data is being loaded...',
  'Point',
  'previouslySubmitted',
  'Proceed',
  'Proceeding will import and merge this data with already existing data. Confirm and update data?',
  'Process Time (ms)',
  'Radians',
  'Re-check of file contained errors',
  'Re-enter barcode element label',
  'Re-enter Coordinates',
  'Re-enter New Password',
  'Record Selected',
  'Record(s)',
  'Records Selected',
  'Records',
  'Record Type',
  'Reduce the number of records in view to turn clustering off',
  'Refresh Assets',
  'Refresh',
  'Related Data',
  'Remove analytics access from other users or purchase additional licenses to enable access for this user.',
  'Remove Repeat',
  'Repeat New Password',
  'Repeat',
  'Request Data',
  'Request Date',
  'Required',
  'requires a lowercase',
  'requires a number',
  'requires a special character',
  'requires an upper case',
  'Rescan',
  'RESEND',
  'Reset Password',
  'Reset to Default',
  'Reset your password',
  'Response Code',
  'Response Data',
  'Response Date',
  'Results',
  'Review',
  'Role Title is a required field',
  'Role Title',
  'role',
  'Role(s)',
  'Roles',
  'Row Status',
  'Satellite',
  'SAVE',
  'Scan complete',
  'Scan with device camera',
  'Scan with handheld scanner',
  'Scanning...',
  'Search Attributes',
  'Search',
  'Segment',
  'Segment(s)',
  'Select $__LABEL__$',
  'Select a file from device',
  'Select a project',
  'Select All',
  'Select an Option',
  'Select Camera',
  'Select Role',
  'Select',
  'Selected records cleared due to change in filters',
  'Selected',
  'Settings',
  'Show $__CAPTION__$',
  'Show',
  'Showing',
  'Showing $__FILTERED_RECORDS__$ of $__TOTAL_RECORDS__$ records',
  'Sorry, no access',
  'Spanish',
  'Status',
  'Streets',
  'Submit',
  'submitted',
  'Success',
  'Support',
  'Symbol',
  'System camera',
  'Table',
  'Tap a point to make a selection',
  'The coordinates and the location pin on the map will be deleted. Are you sure you want to delete the selected coordinates of location $__LOCATION__$?',
  'The following attribute(s) will be updated',
  'The scanned code is invalid.',
  'There are currently no records attached.',
  'There are no issues found on the record. No action needed.',
  'There are warnings detected inside of this file. Import and merge data anyways?',
  'This action can not be undone.',
  'This device does not have a location',
  'This includes permissions to add Map Services, including external map layers.',
  'This includes permissions to view and edit all user roles on the server. This also includes the permissions to see all data on the server including all record types, folders, and projects.',
  'This includes permissions to view and edit all users on the server. Editing permissions include the users name, email, phone number, assigned roles, and admin permissions.',
  'This includes permissions to view the analytics module with all currently active dashboards.',
  'This includes permissions to view and edit advanced logging settings.',
  'This integration import does not exist or has not been committed',
  'This is a really long label that should word wrap on a single line.',
  'To enter a location you can click on the map, capture your devices location, or enter it manually.',
  'Today',
  'toggle password visibility',
  'Total',
  'Turn Off Card View',
  'Turn Off Pin Clustering',
  'Turn On Card View',
  'Turn On Pin Clustering',
  'Type',
  'Unable to delete, the file is being processed right now.',
  'undefined',
  'unlimited',
  'Unsaved Changes',
  'Upload Failed',
  'Upload',
  'Uploading',
  'Use Device Camera',
  'User',
  'User(s)',
  'Username',
  'Users & Roles',
  'Users Administration',
  'Users',
  'Valid',
  'Verification Code',
  'Vertical',
  'View Records',
  'Visibility',
  'Warning cannot be World Hello',
  'Warning cannot be World',
  'Warning(s)',
  'Warnings',
  'yards',
  'Yards',
  'Year to date',
  'YES, DELETE',
  'Yes',
  'Yesterday',
  'You can only select one file',
  'You currently have $__COUNT__$ $__RECORD_TYPE__$ selected, but you can only run this command on $__MAX__$ $__RECORD_TYPE__$ at a time. Please select fewer $__RECORD_TYPE__$ and try again.',
  'You currently have too many $__SELECTED__$ selected. You can only have a maximum of $__MAX__$ records selected at a time. Please select fewer $__SELECTED__$ and try again.',
  'You do not have access to this. Contact an admin for assistance.',
  'You do not have the correct permissions to access this view.',
  'You have selectable record types that are currently hidden from this view. To view all available records, adjust record visibility on the home screen.',
  'You need to review the records to import once it uploads to the server. Do you want to proceed with uploading?',
  'Your import has been processed successfully',
  'Your import has been processed with errors',
  'Your import has been processed with warnings',
  'Your import has been submitted successfully',
  'Your import has encountered an error while committing',
  'Your password has been successfully changed.',
  'Your workflow has unsaved changes. If you continue, your changes will be discarded.',
  "'$__LABEL__$' will run for $__VALID_RECORDS__$ out of the $__COUNT__$ selected $__RECORD_TYPE__$",
  "Hold your device's camera up to the barcode to scan",
  "Uh oh… This entry doesn't match",
  "Uh oh…This isn't a valid location",
];

const mobileLabels = [
  '$__ASSETS__$ Assets',
  '$__DOWNLOADED__$ Records',
  '$__FILE_COUNT__$ Added',
  '$__FREE_DISK_SPACE__$ GB of $__TOTAL_DISK_SPACE__$ GB available',
  '$__LABEL__$ instructions',
  "'$__LABEL__$' will run for $__LENGTH__$ of the selected items?",
  '$__LENGTH__$ Coordinates',
  '$__TOTAL__$ $__RECORDS__$ pending upload',
  '(Blank)',
  '(empty)',
  'A new app configuration is available',
  'Accuracy',
  'Added',
  'AGREE & CONTINUE',
  'All records have been synchronized.',
  'All Set!',
  'Android Location Service',
  'API Messages',
  'Apple Maps',
  'Ask Me Later',
  'Asset now assigned to you',
  'Asset status updated',
  'Assign to me',
  'Audio Player',
  'Audio Recorder',
  'Authentication code',
  'Awaiting Cache Generation, Receiving New Data',
  'Back Home',
  'Caches Completed',
  'Camera access permission was blocked, please check you setting to allow access to the camera',
  'Camera access permission was denied',
  'Camera is unavailable on the device',
  'Camera Permission',
  'Capture',
  'CHANGE PASSWORD',
  'Check the email for the email address you just entered, and enter the verification code above.',
  'Check to make sure you have a project selected.',
  'Choose From Library',
  'CLEAR SIGNATURE',
  'Code Resent',
  'Command Completed Successfully',
  'Command wasn’t able to be processed successfully',
  'Command',
  'Configuration updated successfully',
  'Confirm your email above and press “Continue” to receive a verification code in your email that will allow you to reset your password in the next step.',
  'Connected Location Providers',
  'CONNECTED',
  'containing',
  'Continue anyway',
  'CONTINUE',
  'Control',
  'Could not connect to server',
  'default',
  'Delete $__PHOTOS__$ temp $__FILES__$ containing $__DATA__$ $__UNITS__$ of data',
  'Delete pin(s)',
  'Delete Selected Location(s)',
  'Delete the existing pin(s) to create a new location',
  'Deleting this recording will be permanent',
  'Demo Mode',
  'Demo User',
  'Deselect All Projects',
  'Device control request sent',
  'Device is offline. Reconnect the device to the internet and try again.',
  'DEVICE OFFLINE',
  'Directions',
  'Discard Changes',
  'DISCONNECTED',
  'Draw Section',
  'Drop',
  'Either it has no location, or the latitude or longitude is missing.',
  'Enable Map & List Split Screen',
  'Enter Manually',
  'Enter New Password',
  'Enter QR or barcode',
  'Enter the location coordinates bellow.',
  'Enter Verification Code',
  'Error Resending Code',
  'Error Resetting Password',
  'Error!',
  'error',
  'Failed!',
  'Fetching New Data',
  'Filter Options',
  'Filter',
  'Filters',
  'From',
  'General',
  'Google Maps',
  'Guidance',
  'Hide Repeated Groups',
  'Hybrid',
  'If no $__AGGREGATE__$ are selected all data will be shown.',
  'If the email you entered exists, you will receive your code shortly.',
  'Incorrect username or password',
  'Installing this configuration will reset the data on your device and you will loose any unsynced changes. Please make sure all data is synced before you proceed.',
  'instructions',
  'Invalid coordinates',
  'Invalid Credentials',
  'iOS Core location',
  'Last Synced',
  'Leave delete mode?',
  'List View',
  'Location Configuration',
  'Location coordinates',
  'Logs',
  'LOGIN',
  'Map Display',
  'Map extent',
  'Map search',
  'Map Settings',
  'Map Visibility',
  'Map will go here',
  'Microphone Permission',
  'More',
  'more...',
  'Move your camera in front of a barcode or UPC code',
  'Multi-Select',
  'Multiple location pin limit reached',
  'New app version available',
  'New Data Available',
  'New Data Files have been discovered. Please update to access latest data.',
  'New data is being downloaded and updated. Please wait for the process to complete.',
  'New Data is now available. Please update to get the latest data.',
  'Next',
  'No $__AGGREGATE__$ currently match your filters.',
  'No $__AGGREGATE__$ Found',
  'No Command Found',
  'No Recent Searches',
  'No Search Results',
  'Not Now',
  'On wide devices such as tablets and large phones in landscape mode, your primary view will be split with a map and list',
  'One moment, Fetching',
  'Online Map',
  'Online maps',
  'Oops, directions are not available for this asset.',
  'Oops, street view is not available for this asset.',
  'Oops, there was a problem.',
  'Pending Data Download',
  'pending upload',
  'PENDING',
  'Photo Count',
  'Play',
  'Please contact an admin for help',
  'Please enter a valid email address.',
  'Please enter your code',
  'Please enter your password.',
  'Please enter your username.',
  'Please install it and try again.',
  'Please try again in a few minutes',
  'Please try again',
  'Points on Map exceed limit, zoom in to see points',
  'Preparing Download',
  'Preview',
  'Proceed & Remove Data',
  'Projects',
  'Re-Enter Password',
  'Re-record',
  'Recent Searches',
  'RECONNECT',
  'Record Audio Permission was denied',
  'Record',
  'record',
  'records',
  'Recording…',
  'REQUIRED',
  'RESEND CODE',
  'Resend Code',
  'Reset All',
  'Resource Files',
  'Retry',
  'Retype to confirm here',
  'Satellites',
  'Scan barcode',
  'Scan QR or barcode',
  'Scan successful',
  'Scan',
  'Search Results',
  'Select a server',
  'Select multiple',
  'Select time',
  'Selected items',
  'Selected locations',
  'Server Selection',
  'Server',
  'Share my location',
  'Show GPS status bar',
  'Show map labels',
  'Show Map Labels',
  'Show More',
  'Show Repeated Groups',
  'Show User Coordinates',
  'Sign in with your organization',
  'Sort By',
  'Split View',
  'Standard',
  'Stop',
  'Storage',
  'Street View',
  'Success!',
  'SYNC DATA & CONFIGURATION',
  'SYNC LIST',
  'SYNC RECORDS',
  'Sync Status',
  'System',
  'Take Photo',
  'Take Video',
  'Tap multiple points on the map to draw your selection.',
  'Tap the location pin to delete',
  'Tap',
  'Target',
  'temp file',
  'temp files',
  'Terms of Service',
  'Terms of Use',
  'TerraGo Streetlights needs access to your camera.',
  'TerraGo Streetlights needs access to your microphone.',
  'The latest changes will not be applied until data is updated.',
  'The new data has been downloaded and successfully updated. Your data is now up to date.',
  'The selected pin(s) will not be deleted. Do you still want to leave?',
  'The selected pins will be deleted',
  'The update cannot be completed. Please try again or check your Internet connection.',
  'There are no commands for the selected assets.',
  'This field is readonly',
  'This is Demonstration Mode Only - No Changes Will be Saved',
  'This password matches',
  'This record type cannot be selected.',
  'To view all available records, adjust the record visibility on the home screen.',
  'To',
  'Try Again',
  'Type the QR or barcode below. Retype to confirm.',
  'Unknown User',
  'unknown',
  'Unsynced Photos',
  'Update Cannot Complete',
  'Update Completed',
  'Update Now',
  'User locator',
  'Version',
  'View',
  'View $__COUNT__$ Results',
  'Waiting to upload ',
  'Warning!',
  'warning',
  'was not found. ',
  'Workflows',
  'You cannot perform this action.',
  'You currently have $__TARGET__$ $__ASSET_TYPE__$ selected, but you can only run this command on $__MAX__$ $__ASSET_TYPE__$ at a time. Please select fewer $__ASSET_TYPE__$ and try again.',
  'You have reached the maximum allowed number of audios',
  'You have reached the maximum allowed number of images',
  'You have reached the maximum allowed number of items',
  'You have reached the maximum allowed number of videos',
  'You have selectable record types that are currently hidden.',
  'You must update to the latest version to continue.',
  'Your configuration is already up to date',
  'Your device currently has no location.',
  'Your location will be sent to your server for use with user locator.',
  'Your password has been reset',
  "Uh oh... this doesn't match the above",
  "You don't currently have access to any servers",
];
