import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from '@material-ui/core/IconButton';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import { CONFIRMATION } from '../../../../utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';
import { NavigationButtons, WebNavigation } from '@terragotech/gen5-config-lib';
import { NavigationDefWithName, NavigationComponentProps } from './Navigations';
import { controlMap } from 'pages/aggregates/components/CommandFormEditor/MobileTheme';

interface NavigationListProps {
  navDefinition: NavigationButtons;
  setNavDefinition: (val: NavigationButtons) => void;
  selectedItems: NavigationButtons;
  setSelectedItems: (val: NavigationButtons) => void;
  handleSelectedItem: (
    event: React.MouseEvent,
    navComponentName: string,
    subMenu?: string,
    item?: NavigationDefWithName
  ) => void;
  editItem: (component: WebNavigation, name: string, droppableId: string, index: number) => void;
  deleteItem: (id: string, droppableId: string, index: number) => void;
  focusedItem: string;
  navEditedData?: null | NavigationDefWithName;
  navEditedComponent?: NavigationComponentProps | null;
  setNavEditedData?: (val: NavigationDefWithName | null) => void;
  hasError?: boolean;
  error?: string;
  selectNextComponent?: (
    droppableId: string,
    index: number,
    componentDef: NavigationButtons,
    navDefinition?: NavigationButtons
  ) => void;
  setExistingLabelError?: (val: boolean) => void;
  setEmptyLabelError?: (val: boolean) => void;
  setEmptyIconError?: (val: boolean) => void;
  setEmptyLinkError?: (val: boolean) => void;
}

export const NavigationList: React.FC<NavigationListProps> = ({
  navDefinition,
  selectedItems,
  handleSelectedItem,
  editItem,
  deleteItem,
  focusedItem,
  setNavDefinition,
  navEditedData,
  navEditedComponent,
  setNavEditedData,
  hasError,
  error,
  selectNextComponent,
  setExistingLabelError,
  setEmptyLabelError,
  setEmptyIconError,
  setEmptyLinkError,
}) => {
  const classes = useStyles();
  const { openConfirmation } = useConfirmDialog();

  const onHandleDeleteItem = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    name: string,
    index: number
  ) => {
    e.stopPropagation();
    const props = CONFIRMATION.navigations({ name });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      if (hasError) {
        setExistingLabelError && setExistingLabelError(false);
        setEmptyLabelError && setEmptyLabelError(false);
        setEmptyIconError && setEmptyIconError(false);
        setEmptyLinkError && setEmptyLinkError(false);
      }
      deleteItem(name, 'navigation', index);
    }
  };

  return (
    <>
      {navDefinition.order.map((name, index) => {
        const component = navDefinition.components[name] as WebNavigation;
        const Control = component && controlMap[component.type];
        if (Control) {
          return (
            <Draggable key={name} draggableId={name} index={index}>
              {(provided) => (
                <Box
                  //@ts-ignore
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={`${classes.panelItem} ${focusedItem === name ? classes.selected : ''}`}
                  onClick={(e) => {
                    if (hasError) {
                      return errorMsg(error);
                    }
                    handleSelectedItem(e, name);
                    editItem(component, name, 'navigation', index);
                  }}
                >
                  <Control
                    controlDefinition={component}
                    name={name}
                    focusedItem={focusedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={handleSelectedItem}
                    editItem={editItem}
                    navDefinition={navDefinition}
                    setNavDefinition={setNavDefinition}
                    navEditedData={navEditedData}
                    navEditedComponent={navEditedComponent}
                    setNavEditedData={setNavEditedData}
                    hasError={hasError}
                    error={error}
                    selectNextComponent={selectNextComponent}
                    setExistingLabelError={setExistingLabelError}
                    setEmptyLabelError={setEmptyLabelError}
                    setEmptyIconError={setEmptyIconError}
                    setEmptyLinkError={setEmptyLinkError}
                  />
                  {focusedItem === name && (
                    <Box className={classes.buttonContainer}>
                      <IconButton
                        className={classes.trashButton}
                        component="span"
                        onClick={(e) => {
                          onHandleDeleteItem(e, name, index);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} className={classes.icon} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}
            </Draggable>
          );
        } else {
          console.log(`Couldn't find component: ${navDefinition.components[name]}`);
        }
        return null;
      })}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 18,
    },
    panelItem: {
      position: 'relative',
      background: colors.white,
      color: colors.title,
      boxShadow: `0px 1px 4px 0px ${colors.black15}`,
      border: '1px solid transparent',
      overflow: 'hidden',
      width: '100%',
    },
    trashButton: { color: colors.black54, padding: 10 },
    selected: {
      backgroundColor: theme.palette.primary.light,
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
    buttonContainer: {
      position: 'absolute',
      top: 0,
      right: 4,
    },
  })
);
