import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import useCommonStyles from 'views/useCommonStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import { EmptyCommand } from 'views/pages/records/EmptyCommand';
import { emptySelectedItems, NavigationEditor } from './NavigationEditor';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { useMapperRefChanger } from 'utils/useMapperRefChanger';
import { ConfigContext } from 'context/ConfigContext';
import { useBlocker } from 'react-router-dom';
import { NavigationButtons, WebNavigation } from '@terragotech/gen5-config-lib';
import { cloneDeep, isEqual, isUndefined } from 'lodash';
import {
  CONFIRMATION,
  NAVIGATION_REQUIRED_LABEL_ERROR_MESSAGE,
  NAVIGATION_SAME_LABEL_ERROR_MESSAGE,
} from 'utils/Utils';
import useRouteBlocker from 'common/useBlocker';
import { EditedDefData } from 'utils/types';
import { useUICustomizationAPI } from 'context/fakeAPIHooks/useUICustomizationAPI';
import { useConfirmDialog } from 'context/ConfirmContext';
import { NavigationEditForm } from 'components/FormDialog/NavigationEditForm';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { checkDuplicateNavLabel } from 'pages/aggregates/utils/navigationUtils';
import _ from 'lodash';

export type NavigationDefWithName = WebNavigation & {
  name: string;
  droppableId: string;
  index?: number;
};

export interface NavigationComponentProps {
  name: string;
  index: number;
  droppableId: string;
}

interface Component {
  subMenu?: {
    order: string[];
    components: Record<string, Component>;
  };
  type?: string;
  level?: number;
  label?: string;
  icon?: string;
  conditionalMap?: {
    nodes: Record<string, any>;
    outputDefinition: {
      outputNode: string;
    };
  };
}

const Navigations: React.FC = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { config } = useContext(ConfigContext);
  const { openConfirmation } = useConfirmDialog();
  const UICustomizationAPI = useUICustomizationAPI();
  const mapperRefChanger = useMapperRefChanger();
  const [reset, setReset] = useState<number>(0);
  const [focusedItem, setFocusedItem] = useState('');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [existingLabelError, setExistingLabelError] = useState<boolean>(false);
  const [emptyLabelError, setEmptyLabelError] = useState<boolean>(false);
  const [emptyIconError, setEmptyIconError] = useState<boolean>(false);
  const [emptyLinkError, setEmptyLinkError] = useState<boolean>(false);
  const [navLabelError, setNavLabelError] = useState<boolean>(false);
  const [navEmptyLabelError, setNavEmptyLabelError] = useState<boolean>(false);
  const [navEmptyIconError, setNavEmptyIconError] = useState<boolean>(false);
  const [navEmptyLinkError, setNavEmptyLinkError] = useState<boolean>(false);
  const [navEditedData, setNavEditedData] = useState<null | NavigationDefWithName>(null);
  const [navEditedComponent, setNavEditedComponent] = useState<NavigationComponentProps | null>(
    null
  );
  const [currentEditItem, setCurrentEditItem] = useState<null | NavigationDefWithName>(null);
  const initialNavDefinition = useCallback(
    () =>
      (config.webUIConfig.navigations && cloneDeep(config.webUIConfig.navigations)) || {
        components: {},
        order: [],
      },
    [config.webUIConfig.navigations]
  );
  const [navDefinition, setNavDefinition] = useState<NavigationButtons>(initialNavDefinition());
  const [savedDefinition, setSavedDefinition] = useState<NavigationButtons>(initialNavDefinition());
  const error = existingLabelError
    ? NAVIGATION_SAME_LABEL_ERROR_MESSAGE
    : emptyLabelError
    ? NAVIGATION_REQUIRED_LABEL_ERROR_MESSAGE
    : emptyIconError
    ? 'Icon is required for all navigation items. Add an icon before selecting another element.'
    : emptyLinkError
    ? 'Link is required for all navigation items. Add a link before selecting another element.'
    : '';

  const isTabDirty = () => isDirty || !isEqual(savedDefinition, navDefinition);

  useEffect(() => {
    const initialNavDef = initialNavDefinition();
    setSavedDefinition(initialNavDef);
  }, [initialNavDefinition]);

  const selectFirstComponent = useCallback((navigationDef: NavigationButtons) => {
    if (navigationDef.order.length > 0) {
      const name = navigationDef.order[0];
      const component = navigationDef.components[name];
      setCurrentEditItem({ ...component, name, index: 0, droppableId: 'navigation' });
      setFocusedItem(name);
    } else {
      setCurrentEditItem(null);
      setFocusedItem('');
    }
  }, []);

  useEffect(() => {
    selectFirstComponent(initialNavDefinition());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNavLabelError(existingLabelError);
  }, [existingLabelError, setNavLabelError]);

  useEffect(() => {
    setNavEmptyLabelError(emptyLabelError);
  }, [emptyLabelError, setNavEmptyLabelError]);

  useEffect(() => {
    setNavEmptyIconError(emptyIconError);
  }, [emptyIconError, setNavEmptyIconError]);

  useEffect(() => {
    setNavEmptyLinkError(emptyLinkError);
  }, [emptyLinkError, setNavEmptyLinkError]);

  const checkCurrentEditItem = useCallback(() => {
    if (currentEditItem) {
      if (navDefinition.order.length === 0) {
        setCurrentEditItem(null);
      }
    }
  }, [navDefinition.order, currentEditItem, setCurrentEditItem]);

  useEffect(() => {
    checkCurrentEditItem();
  }, [navDefinition.order, currentEditItem, checkCurrentEditItem]);

  useEffect(() => {
    if (config.webUIConfig.navigations) {
      setNavDefinition(cloneDeep(config.webUIConfig.navigations));
    } else {
      setNavDefinition({
        components: {},
        order: [],
      });
    }
  }, [reset, config.webUIConfig.navigations]);

  const removeItemFromNavigations = (
    index: number,
    label: string,
    copySelectedItems: NavigationButtons
  ) => {
    copySelectedItems.order.splice(index, 1);
    delete copySelectedItems.components[label];
  };

  const addModifiedItemToNavigations = useCallback(
    (index: number, newName: string, copySelectedItems: NavigationButtons, editedData: unknown) => {
      const { name, ...rest } = editedData as NavigationDefWithName;
      (copySelectedItems.components[newName] as WebNavigation) = {
        ...(rest as WebNavigation),
      };
      copySelectedItems.order.splice(index, 0, newName);
    },
    []
  );

  const removeItemFromSubMenu = (
    orders: string[],
    index: number,
    label: string,
    components: { [label: string]: WebNavigation }
  ) => {
    orders.splice(index, 1);
    delete components[label];
  };

  const addModifiedItemToSubMenu = useCallback(
    (
      components: { [name: string]: WebNavigation },
      orders: string[],
      index: number,
      newName: string,
      editedData: unknown
    ) => {
      const { name, ...rest } = editedData as NavigationDefWithName;
      components[newName] = {
        ...(rest as WebNavigation),
      };
      orders.splice(index, 0, newName);
    },
    []
  );

  const getEditedDef = useCallback(
    ({ editedData, oldName, newName, droppableId }: EditedDefData): NavigationButtons | null => {
      const copySelectedItems = cloneDeep(navDefinition);
      if (oldName !== newName) {
        setFocusedItem(`${droppableId === 'navigation' ? '' : droppableId}${newName}`);
      }
      if (newName && oldName && droppableId) {
        if (droppableId === 'navigation') {
          const index = copySelectedItems.order.findIndex((o) => o === oldName);
          removeItemFromNavigations(index, oldName, copySelectedItems);
          addModifiedItemToNavigations(index, newName, copySelectedItems, editedData);
          return mapperRefChanger.renameFormReferences(copySelectedItems, oldName, newName);
        } else {
          const dropPath = droppableId.split('.');
          const finalComp = dropPath.reduce((acc: any, currPath: string) => {
            return acc && acc.components && (acc.components[currPath] as WebNavigation)?.subMenu;
          }, copySelectedItems) as NavigationButtons;
          if (finalComp && finalComp.components[oldName]) {
            const dropOrder = finalComp.order;
            const dropComponents = finalComp.components;
            const index = dropOrder.findIndex((o) => o === oldName);
            removeItemFromSubMenu(dropOrder, index, oldName, dropComponents);
            addModifiedItemToSubMenu(dropComponents, dropOrder, index, newName, editedData);
            return mapperRefChanger.renameFormReferences(
              copySelectedItems,
              oldName,
              newName,
              droppableId
            );
          }
        }
      }
      return null;
    },
    [navDefinition, mapperRefChanger, addModifiedItemToNavigations, addModifiedItemToSubMenu]
  );

  const handleCancelChanges = async () => {
    try {
      const status = await openConfirmation(CONFIRMATION.commonCancel);
      if (status === 'confirm') {
        selectFirstComponent(navDefinition);
        setReset((prev) => prev + 1);
      }
    } catch (error) {
      console.log('request cancelled');
    }
  };

  const findComponentByKey = (data: Component, targetKey: string): Component | undefined => {
    if (_.isObject(data) && data !== null) {
      const components = _.get(data, 'components', {});
      if (_.isObject(components)) {
        for (const [key, value] of Object.entries(components)) {
          if (key === targetKey) {
            return value as Component;
          }
          const result = findComponentByKey(value, targetKey);
          if (result) return result;
        }
      }
      for (const value of Object.values(data)) {
        const result = findComponentByKey(value, targetKey);
        if (result) return result;
      }
    }
    return undefined;
  };

  const handleSelectedItem = (
    event: React.MouseEvent | null,
    navComponentName: string,
    subMenu?: string,
    item?: NavigationDefWithName
  ) => {
    setFocusedItem(`${subMenu || ''}${navComponentName}`);
    if (!isUndefined(item)) {
      setCurrentEditItem(item);
    }
  };

  const selectNextComponent = (
    droppableId: string,
    index: number,
    componentNavDefinition: NavigationButtons,
    navDefinition?: NavigationButtons
  ) => {
    // if navDefinition arg present, then it is subMenu child
    let nextIndex = -1;
    if (index === componentNavDefinition.order.length - 1) {
      //if last index deleted, select previous component
      nextIndex = index - 1;
    } else {
      // select next component
      nextIndex = index + 1;
    }
    if (nextIndex !== -1) {
      const nextComponentName = componentNavDefinition.order[nextIndex];
      const component = componentNavDefinition.components[nextComponentName];
      setCurrentEditItem({ ...component, name: nextComponentName, index: nextIndex, droppableId });
      setFocusedItem(navDefinition ? `${droppableId}${nextComponentName}` : nextComponentName);
    } else {
      if (navDefinition) {
        const pathComponents = droppableId.split('.');
        const firstElement = pathComponents[0];
        const lastElement = pathComponents[pathComponents.length - 1];
        let droppableElement = droppableId;
        let component: WebNavigation = navDefinition.components[firstElement];
        if (firstElement !== droppableId) {
          component = findComponentByKey(navDefinition.components, lastElement) as WebNavigation;
          if(pathComponents.length > 1) {
            droppableElement = pathComponents.slice(0, pathComponents.length-1).join('.');
          }
        }else{
          droppableElement = 'navigation';
        }
        let updatedDroppableId = droppableId.replace(`.${lastElement}`, '');
        handleSelectedItem(null, lastElement, pathComponents.length > 1 ? updatedDroppableId : undefined, undefined);
        setCurrentEditItem({
          ...component,
          name: droppableId,
          index: nextIndex,
          droppableId: droppableElement,
        } as NavigationDefWithName);
      }
    }
  };
  const editItem = async (
    component: WebNavigation,
    name: string,
    droppableId: string,
    index: number
  ) => {
    const comp = cloneDeep(component);
    const item: NavigationDefWithName = { ...comp, name, droppableId, index };
    setCurrentEditItem(item);
  };

  const deleteItem = async (id: string, droppableId: string, index: number) => {
    selectNextComponent(droppableId, index, navDefinition);
    let navs = cloneDeep(navDefinition);
    const removeItemFromNavigations = () => {
      navs.order.splice(index, 1);
      delete navs.components[id];
    };
    const removeItemFromSubMenu = () => {
      dropOrder.splice(index, 1);
      delete dropComponents[id];
    };
    successMsg(`Navigation item "${id}" has been successfully deleted`);
    if (droppableId === 'navigation') {
      removeItemFromNavigations();
      navs = mapperRefChanger.removeFormReferences(navs, id);
      setNavEditedData(null);
      setNavEditedComponent(null);
      setNavDefinition(navs);
      return;
    }
    const {
      subMenu: { order: dropOrder, components: dropComponents },
    } = navs.components[droppableId] as WebNavigation;
    removeItemFromSubMenu();
    navs = mapperRefChanger.removeFormReferences(navs, id, droppableId);
    setNavEditedData(null);
    setNavEditedComponent(null);
    setNavDefinition(navs);
  };

  const save = async () => {
    if (navLabelError) {
      return errorMsg(
        'A navigation item with the same label already exists. Rename it before saving.'
      );
    }
    if (navEmptyLabelError) {
      return errorMsg('Label is required for all navigation items. Add a label before saving.');
    }
    if (navEmptyIconError) {
      return errorMsg('Icon is required for all navigation items. Add an icon before saving.');
    }
    if (navEmptyLinkError) {
      return errorMsg('Link is required for all navigation items. Add a link before saving.');
    }
    if (!isTabDirty()) {
      return;
    }

    const newNavigations = { ...navDefinition };
    const { error } = await UICustomizationAPI.updateNavigations(newNavigations);
    if (error) return;
    const currentNavigations =
      config.webUIConfig.navigations && cloneDeep(config.webUIConfig.navigations);
    setSavedDefinition(currentNavigations || emptySelectedItems);
    successMsg('Navigations have been saved');
    setIsDirty(false);
  };

  const onDiscardChanges = () => {
    setNavDefinition(savedDefinition);
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation.pathname !== nextLocation.pathname && isTabDirty();
  });

  useRouteBlocker({ blocker, onSave: save, onDiscard: onDiscardChanges });

  return (
    <Box className={`${commonClasses.innerContainer} ${classes.container}`}>
      <Box className={commonClasses.titleBarContainer}>
        <Box>
          <Typography className={classes.title}>{'Navigations (Web Only)'}</Typography>
        </Box>
        <Box className={classes.btnContainer}>
          <Button
            variant="outlined"
            onClick={handleCancelChanges}
            className={[classes.cancelBtn, classes.btnStyle, classes.text].join(' ')}
            startIcon={<FontAwesomeIcon icon={faXmark} />}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={save}
            className={[classes.saveBtn, classes.btnStyle, classes.text].join(' ')}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Grid container className={[classes.gridCtn, commonClasses.panelContainer].join(' ')}>
        <Grid item sm={7} className={classes.navigationFieldContainer}>
          <NavigationEditor
            navDefinition={navDefinition}
            setNavDefinition={setNavDefinition}
            navEditedData={navEditedData}
            setNavEditedData={setNavEditedData}
            navEditedComponent={navEditedComponent}
            setNavEditedComponent={setNavEditedComponent}
            handleSelectedItem={handleSelectedItem}
            selectNextComponent={selectNextComponent}
            editItem={editItem}
            deleteItem={deleteItem}
            hasError={existingLabelError || emptyLabelError || emptyIconError || emptyLinkError}
            error={error}
            focusedItem={focusedItem}
            setExistingLabelError={setExistingLabelError}
            setEmptyLabelError={setEmptyLabelError}
            setEmptyIconError={setEmptyIconError}
            setEmptyLinkError={setEmptyLinkError}
          />
        </Grid>
        <Grid item sm={5} className={classes.navigationDetailContainer}>
          {currentEditItem !== null ? (
            <div className={classes.column}>
              {getNavigationComponent(
                navDefinition,
                currentEditItem,
                existingLabelError,
                setExistingLabelError,
                emptyLabelError,
                setEmptyLabelError,
                emptyIconError,
                setEmptyIconError,
                emptyLinkError,
                setEmptyLinkError,
                getEditedDef,
                setNavDefinition
              )}
            </div>
          ) : (
            <>
              <EmptyCommand
                title="Navigations are currently empty"
                description="No menu is set up yet. Click the '+' icon to create a new menu item."
              />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      overflow: 'auto',
    },
    column: { display: 'flex', flexDirection: 'column' },
    gridCtn: {
      height: '100%',
    },
    navigationFieldContainer: {
      height: '100%',
      backgroundColor: colors.lotion,
      borderRight: `1px solid ${colors.black10}`,
    },
    navigationDetailContainer: {
      backgroundColor: colors.white,
      padding: '31px 35px 31px 34px',
      height: '100%',
      overflowY: 'scroll',
    },
    btnContainer: {
      display: 'flex',
      gap: '8px',
    },
    saveBtn: {
      color: theme.palette.primary.main,
    },
    cancelBtn: {
      color: colors.black54,
    },
    text: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    btnStyle: {
      height: '40px',
      padding: ' 11px 23px 10px 20px',
      borderRdius: '5px',
      border: `1px solid ${colors.greySeaShell}`,
      backgroundColor: colors.white,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    },
    title: {
      fontSize: '24px',
      fontWeight: 500,
    },
  })
);

const getNavigationComponent = (
  navDefinition: NavigationButtons,
  item: NavigationDefWithName,
  existingLabelError: boolean,
  setExistingLabelError: (hasError: boolean) => void,
  emptyLabelError: boolean,
  setEmptyLabelError: (hasError: boolean) => void,
  emptyIconError: boolean,
  setEmptyIconError: (hasError: boolean) => void,
  emptyLinkError: boolean,
  setEmptyLinkError: (hasError: boolean) => void,
  getEditedDef?: (val: EditedDefData) => NavigationButtons | null,
  setNavDefinition?: (val: NavigationButtons) => void
) => {
  const checkEmptyLink = (link: NodeMapDefinition | undefined) => {
    if (
      link &&
      link.nodes &&
      link.nodes['NAVIGATION_OPTIONS'] &&
      link.nodes['NAVIGATION_OPTIONS'].inputs
    ) {
      return (
        isUndefined(link.nodes['NAVIGATION_OPTIONS'].inputs.link) ||
        link.nodes['NAVIGATION_OPTIONS'].inputs.link === '' ||
        link.nodes['NAVIGATION_OPTIONS'].inputs.link === null
      );
    }
    return link === undefined;
  };

  const checkEmptyText = (text: string) => {
    return text.trim() === '';
  };

  const handleLabelChange = (label: string, setLabel: (label: string) => void) => {
    const isLabel = checkDuplicateNavLabel(label.trim(), item, navDefinition.components);
    const isEmpty = checkEmptyText(label);
    setExistingLabelError(isLabel);
    setEmptyLabelError(isEmpty);
    setLabel(label);
  };

  const handleIconChange = (icon: string, setIcon: (icon: string) => void) => {
    const isEmpty = checkEmptyText(icon);
    setEmptyIconError(isEmpty);
    setIcon(icon);
  };

  const handleConditionalMapChange = (
    conditionalMap: NodeMapDefinition | undefined,
    setConditionalMap: (conditionalMap: NodeMapDefinition) => void
  ) => {
    if (conditionalMap) {
      const isEmpty = checkEmptyLink(conditionalMap);
      setEmptyLinkError(isEmpty);
      setConditionalMap(conditionalMap);
    }
  };

  return (
    <NavigationEditForm
      component={item}
      getEditedDef={getEditedDef}
      setNavDefinition={setNavDefinition}
      existingLabelError={existingLabelError}
      emptyLabelError={emptyLabelError}
      handleLabelChange={handleLabelChange}
      emptyIconError={emptyIconError}
      handleIconChange={handleIconChange}
      setEmptyIconError={setEmptyIconError}
      emptyLinkError={emptyLinkError}
      handleConditionalMapChange={handleConditionalMapChange}
      setEmptyLinkError={setEmptyLinkError}
    />
  );
};

export default Navigations;
