import React, { useState, useCallback, useEffect } from 'react';
import { Box, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { useFormSchemas } from './hooks/useFormSchemas';
import _, { isUndefined } from 'lodash';
import { EditedDefData } from 'utils/types';
import MapperItem from './MapperItem';
import { TextInput } from './common';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { IconPicker } from 'utils/iconPicker';
import { colors } from 'utils/colors';
import { NavigationDefWithName } from 'views/pages/settings/components/Navigations';
import { NavigationButtons } from '@terragotech/gen5-config-lib';
import clsx from 'clsx';
interface NavigationComponentProps {
  component: NavigationDefWithName;
  getEditedDef?: (props: EditedDefData) => NavigationButtons | null;
  setNavDefinition?: (val: NavigationButtons) => void;
  existingLabelError?: boolean;
  emptyLabelError?: boolean;
  handleLabelChange?: (label: string, setLabel: (label: string) => void) => void;
  emptyIconError?: boolean;
  handleIconChange?: (icon: string, setIcon: (icon: string) => void) => void;
  setEmptyIconError: (val: boolean) => void;
  emptyLinkError?: boolean;
  handleConditionalMapChange?: (
    data: NodeMapDefinition | undefined,
    setConditionalMap: (data: NodeMapDefinition) => void
  ) => void;
  setEmptyLinkError: (val: boolean) => void;
}

export const NavigationEditForm: React.FC<NavigationComponentProps> = ({
  component,
  getEditedDef,
  setNavDefinition,
  existingLabelError,
  emptyLabelError,
  handleLabelChange,
  emptyIconError,
  handleIconChange,
  setEmptyIconError,
  emptyLinkError,
  handleConditionalMapChange,
  setEmptyLinkError,
}) => {
  const classes = useStyles();
  const formSchemas = useFormSchemas();
  const { openConfirmation } = useConfirmDialog();
  const [name, setName] = useState(component.name);
  const [oldLabel, setOldLabel] = useState(component.label);
  const [type, setType] = useState(component.type);
  const [subMenu, setSubMenu] = useState(component.subMenu);
  const [label, setLabel] = useState<string>(component.label);
  const [icon, setIcon] = useState<string>(component.icon || '');
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [level, setLevel] = useState<number>(component.level || 0);
  const [conditionalMap, setConditionalMap] = useState<NodeMapDefinition | undefined>(
    component.conditionalMap || undefined
  );

  const setInitialData = useCallback(() => {
    setName(component.name);
    setSubMenu(component.subMenu);
    setOldLabel(component.label);
    setLabel(component.label);
    setType(component.type);
    setIcon(component.icon);
    setConditionalMap(component.conditionalMap || undefined);
    setLevel(component.level || 0);
    setEmptyIconError(component.icon === '' || component.icon === undefined);
    setEmptyLinkError(
      isUndefined(component.conditionalMap?.nodes?.NAVIGATION_OPTIONS?.inputs?.link)
    );
  }, [component, setEmptyIconError, setEmptyLinkError]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const getNavigationValues = useCallback(() => {
    return {
      subMenu: subMenu,
      type: type,
      level: level || 0,
      name: name || '',
      label: label || '',
      ...(icon && { icon }),
      ...(conditionalMap && { conditionalMap }),
    };
  }, [type, subMenu, name, label, icon, conditionalMap, level]);

  useEffect(() => {
    if (
      getEditedDef &&
      setNavDefinition &&
      label.length > 0 &&
      !existingLabelError &&
      !emptyLabelError
    ) {
      const edited = getNavigationValues();
      const editedDef = getEditedDef({
        editedData: edited,
        oldName: oldLabel,
        newName: label,
        droppableId: component.droppableId,
      });
      if (editedDef) {
        setNavDefinition(editedDef);
        if (oldLabel !== label) {
          setOldLabel(label);
        }
      }
    }
    // If add those all dependency, it'll call recursively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNavigationValues, label]);

  const handleClearConditionalMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setConditionalMap(undefined);
      setEmptyLinkError(true);
    }
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  return (
    <>
      <Typography className={classes.header}>{label}</Typography>
      <TextInput
        id="Label"
        label="Label"
        error={existingLabelError || emptyLabelError}
        helperText={
          existingLabelError
            ? 'Duplicate label error. Label must be unique across all menu or sub menu elements.'
            : emptyLabelError
            ? 'Label cannot be empty'
            : ''
        }
        value={label}
        onChange={(value) => handleLabelChange && handleLabelChange(value || '', setLabel)}
        placeholder="Enter label"
      />
      <Box className={classes.item}>
        <Typography className={classes.iconLabel}>Icon</Typography>
        {IconPicker(icon, (value) => handleIconChange && handleIconChange(value || '', setIcon))}
        {emptyIconError && (
          <FormHelperText className={classes.errorText}>Icon cannot be empty</FormHelperText>
        )}
      </Box>
      <Box className={classes.item}>
        <Typography className={clsx(classes.label, classes.iconLabel)}>
          Configure Menu Options
        </Typography>
        <MapperItem
          {...{
            onToggleMapper: setConditionalOpen,
            isActive: doesConditionalHaveValue(),
            clearMapper: handleClearConditionalMapper,
            openDataMap: conditionalOpen,
            dataMap: conditionalMap,
            setDataMap: (data: NodeMapDefinition | undefined) =>
              handleConditionalMapChange && handleConditionalMapChange(data, setConditionalMap),
            localSchemaDefinition: formSchemas,
            mapScenario: 'NAVIGATION_OPTIONS',
            noMargin: true,
          }}
        />
        {emptyLinkError && (
          <FormHelperText className={classes.errorText}>
            Link field in configured menu options cannot be empty
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.black,
    marginBottom: 32,
  },
  iconLabel: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.black,
  },
  item: {
    marginBottom: 29,
  },
  label: {
    paddingBottom: 12,
  },
  errorText: {
    color: colors.redLight,
    fontSize: 12,
    paddingLeft: 15,
  },
});
