import { WebNavigation } from '@terragotech/gen5-config-lib';

interface NavigationComponents {
  [key: string]: WebNavigation;
}

const checkComponentLabelsFromSubMenu = (
  label: string,
  component: { label: string; droppableId?: string },
  navigationComponents: NavigationComponents
): boolean => {
  for (const [fcName, fc] of Object.entries(navigationComponents)) {
    if ((fc.label === label || fcName === label) && component.label !== label) {
      console.log('here', fc.label, label, component.label);
      return true;
    }

    if (fc?.subMenu?.components) {
      if (checkComponentLabelsFromSubMenu(label, component, fc.subMenu.components)) {
        return true;
      }
    }
  }
  return false;
};

export const checkDuplicateNavLabel = (
  label: string,
  component: { label: string; droppableId?: string },
  navigationComponents: NavigationComponents
) => {
  const components = Object.keys(navigationComponents || {});
  const labelExists = components.some((componentLabel) => label === componentLabel);
  const duplicateNameExistsInSubMenu = checkComponentLabelsFromSubMenu(label, component, navigationComponents);
  if (labelExists && component.label !== label || duplicateNameExistsInSubMenu) {
    return true;
  }
  return false;
};

export const generateNavigationName = (order: string[], subMenuName?: string): string => {
  let counter = 1;

  const getFreeName = (): string => {
    const name = subMenuName ? `${subMenuName}-${counter}` : `Navigation${counter}`;
    if (order.includes(name)) {
      counter += 1;
      return getFreeName();
    }
    return name;
  };

  return getFreeName();
};