import React, { useState } from 'react';
import { V2ScatterPlotChartOptions } from '@terragotech/page-renderer';
import { TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps, ColorPaletteChartOption, LegendPositionChartOption } from './common';

const layersOptions: Record<NonNullable<V2ScatterPlotChartOptions['layers']>[number], string> = {
  grid: 'Grid',
  axes: 'Axes',
  nodes: 'Nodes',
  markers: 'Markers',
  mesh: 'Mesh',
  legends: 'Legends',
  annotations: 'Annotations',
};
const layersOptionsOrderedKeys = Object.keys(layersOptions) as NonNullable<V2ScatterPlotChartOptions['layers']>;

export const ScatterPlotChartEditSection: React.FC<ChartEditSectionProps<'scatterPlot'>> = ({
  chartOptions,
  setChartOptions,
  optionStyles,
}) => {
  const [colorPalette, setColorPalette] = useState(chartOptions?.colorPalette);
  const [legendPosition, setLegendPosition] = useState(chartOptions?.legendPosition);
  const [layers, setLayers] = useState<V2ScatterPlotChartOptions['layers']>(chartOptions?.layers ?? layersOptionsOrderedKeys);

  return (
    <>
      <ColorPaletteChartOption
        value={colorPalette}
        onChange={(value) => {
          setColorPalette(value);
          setChartOptions((o) => ({ ...o, colorPalette: value }));
        }}
        optionStyles={optionStyles}
      />
      <LegendPositionChartOption
        value={legendPosition}
        onChange={(value) => {
          setLegendPosition(value);
          setChartOptions((o) => ({ ...o, legendPosition: value }));
        }}
        optionStyles={optionStyles}
      />
      <TGSelect
        id="layers"
        label="Layers"
        type="layer(s)"
        multiple={true}
        value={layers ?? []}
        options={layersOptions}
        onChangeMultiple={(valueArray) => {
          const value = valueArray.length === layersOptionsOrderedKeys.length ? undefined : layersOptionsOrderedKeys.filter(k => valueArray.some(v => v === k));
          setLayers(value ?? layersOptionsOrderedKeys as V2ScatterPlotChartOptions['layers']);
          setChartOptions((o) => ({ ...o, layers: value as V2ScatterPlotChartOptions['layers'] }));
        }}
        {...optionStyles}
      />
    </>
  );
};

export default ScatterPlotChartEditSection;
