import React, { useMemo } from 'react';
import { TGSelect } from '../../../../views/components/formElements';
import { ChartOptionProps } from '.';
import { useConfig } from '../../../../context/ConfigContext';
import { V2CommonChartOptions } from '@terragotech/page-renderer';
import { ColorPickerPage } from 'components/FormElements/ColorPage';

export const ColorPaletteChartOption: React.FC<ChartOptionProps<V2CommonChartOptions['colorPalette']>> = ({
  value: colorPalette,
  onChange: onChangeColorPalette,
  label,
  optionStyles,
}) => {
  const { config: { webUIConfig: { colorPalettes } } } = useConfig();

  const selectedPalette = useMemo(() => colorPalettes?.find(x => x.name === colorPalette), [colorPalettes, colorPalette]);

  return (
    <>
      <TGSelect
        id="colorPalette"
        label={label ?? "Color Palette"}
        type="color palette"
        value={selectedPalette?.label!}
        options={colorPalettes}
        canShowEmpty={true}
        onChange={(value) => {
          onChangeColorPalette(value || undefined);
        }}
        {...optionStyles}
      />
      {selectedPalette?.colors?.length && (
        <ColorPickerPage
         value={selectedPalette.colors}
         containerStyle={styles.topAdjacentContainer}
         />
      )}
    </>
  );
};
const styles = {
  topAdjacentContainer: {
    marginBottom: 20,
  },
};

export default ColorPaletteChartOption;
