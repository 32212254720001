import React from 'react';
import { TGSelect } from '../../../../views/components/formElements';
import { ChartOptionProps } from '../common';
import { V2CommonChartOptions } from '@terragotech/page-renderer';

// Notes: [S5-3587] We're currently only allowing `right` and `bottom` options. Scaffolding here for when we expand options.
const legendPositionOptions: { [K in NonNullable<V2CommonChartOptions['legendPosition']>]?: string } = { //Record<NonNullable<V2CommonChartOptions['legendPosition']>, string> = {
  // top: 'Top',
  // "top-right": 'Top-Right',
  right: 'Right',
  // "bottom-right": 'Bottom-Right',
  bottom: 'Bottom',
  // "bottom-left": 'Bottom-Left',
  // left: 'Left',
  // "top-left": 'Top-Left',
  // center: 'Center',
};

export const LegendPositionChartOption: React.FC<ChartOptionProps<V2CommonChartOptions['legendPosition']>> = ({
  value: legendPosition,
  onChange: onChangeLegendPosition,
  label,
  optionStyles,
}) => {
  return (
    <>
      <TGSelect
        id="legendPosition"
        label={label ?? "Legend Position"}
        type="position"
        value={legendPositionOptions[legendPosition!]!}
        options={legendPositionOptions}
        canShowEmpty={true}
        onChange={(value) => {
          onChangeLegendPosition(value as V2CommonChartOptions['legendPosition'] || undefined);
        }}
        {...optionStyles}
      />
    </>
  );
};

export default LegendPositionChartOption;
