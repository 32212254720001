import React, { useState } from 'react';
import { V2LineChartOptions } from '@terragotech/page-renderer';
import { TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps, ColorPaletteChartOption, LegendPositionChartOption } from './common';

const curveOptions: Record<NonNullable<V2LineChartOptions['curve']>, string> = {
  basis: 'Basis',
  cardinal: 'Cardinal',
  catmullRom: 'Catmull-Rom',
  linear: 'Linear',
  monotoneX: 'Monotone (X-axis)',
  monotoneY: 'Monotone (Y-axis)',
  natural: 'Natural',
  step: 'Step',
  stepAfter: 'Step After',
  stepBefore: 'Step Before',
};
const layersOptions: Record<NonNullable<V2LineChartOptions['layers']>[number], string> = {
  grid: 'Grid',
  markers: 'Markers',
  axes: 'Axes',
  areas: 'Areas',
  crosshair: 'Crosshair',
  lines: 'Lines',
  points: 'Points',
  slices: 'Slices',
  mesh: 'Mesh',
  legends: 'Legends',
};
const layersOptionsOrderedKeys = Object.keys(layersOptions) as NonNullable<V2LineChartOptions['layers']>;

export const LineChartEditSection: React.FC<ChartEditSectionProps<'line'>> = ({
  chartOptions,
  setChartOptions,
  optionStyles,
}) => {
  const [colorPalette, setColorPalette] = useState(chartOptions?.colorPalette);
  const [legendPosition, setLegendPosition] = useState(chartOptions?.legendPosition);
  const [curve, setCurve] = useState(chartOptions?.curve);
  const [layers, setLayers] = useState<V2LineChartOptions['layers']>(chartOptions?.layers ?? layersOptionsOrderedKeys);

  return (
    <>
      <ColorPaletteChartOption
        value={colorPalette}
        onChange={(value) => {
          setColorPalette(value);
          setChartOptions((o) => ({ ...o, colorPalette: value }));
        }}
        optionStyles={optionStyles}
      />
      <LegendPositionChartOption
        value={legendPosition}
        onChange={(value) => {
          setLegendPosition(value);
          setChartOptions((o) => ({ ...o, legendPosition: value }));
        }}
        optionStyles={optionStyles}
      />
      <TGSelect
        id="curve"
        label="Curve"
        value={curveOptions[curve!]}
        options={curveOptions}
        canShowEmpty={true}
        onChange={(value) => {
          setCurve(value as V2LineChartOptions['curve']);
          setChartOptions((o) => ({ ...o, curve: value as V2LineChartOptions['curve'] || undefined }));
        }}
        {...optionStyles}
      />
      <TGSelect
        id="layers"
        label="Layers"
        type="layer(s)"
        multiple={true}
        value={layers ?? []}
        options={layersOptions}
        onChangeMultiple={(valueArray) => {
          const value = valueArray.length === layersOptionsOrderedKeys.length ? undefined : layersOptionsOrderedKeys.filter(k => valueArray.some(v => v === k));
          setLayers(value ?? layersOptionsOrderedKeys as V2LineChartOptions['layers']);
          setChartOptions((o) => ({ ...o, layers: value as V2LineChartOptions['layers'] }));
        }}
        {...optionStyles}
      />
    </>
  );
};

export default LineChartEditSection;
