import React from 'react';
import { Box, makeStyles, styled } from '@material-ui/core';

interface ColorPickerProps {
  value: string[];
  containerStyle?: React.CSSProperties;
}

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const ColorPickerPage: React.FC<ColorPickerProps> = ({
  value,
  containerStyle,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container style={containerStyle}>
        <Box className={classes.inputContainer}>
          <Box className={classes?.button}>
            <Box className={classes?.colorPaletteContainer}>
              {value.map((color, index) => (
                <Box
                  key={index}
                  className={classes?.colorBox}
                  style={{ backgroundColor: color }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  inputContainer: {
    position: 'relative',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputPicker: {
    position: 'absolute',
    top: 15,
    left: -100,
    width: 0,
    height: 0,
    zIndex: 1,
  },
  colorPaletteContainer: {
    display: 'flex',
    gap: '5px',
  },
  colorBox: {
    width: 20,
    height: 20,
    borderRadius: '50%',
  },
});
