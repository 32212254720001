import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Radio,
  Theme,
  Typography,
} from '@material-ui/core';
import { toSplicedArray } from '@terragotech/gen5-shared-utilities';
import { EditableTable } from 'components/EditableTable';
import { useConfig } from 'context/ConfigContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from 'utils/colors';
import AddModal from './AddModal';
import { ColorPickerPage } from './ColorPage';
import { ColorPalette } from '@terragotech/gen5-config-lib';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import _ from 'lodash';

interface commonEditorProps {
  title: string;
  value: string[];
  onChange?: (newColors: ColorPalette[] | undefined, action?: string) => void;
  handlePrimaryPaletteChange?: (value: string) => void;
  summary?: string;
  values?: {
    colorPalettes?: ColorPalette[];
    primaryColorPalette?: string;
  };
}

export const isFormFieldInvalid = (row: ColorPalette) => {
  if (_.hasIn(row, 'label') && !row.label) {
    errorMsg('Property "Name" is required');
    return false;
  }
  if (!row.colors || row.colors.length === 0) {
    errorMsg('Property "Colors" cannot be empty');
    return false;
  }
  return true;
};

const CommonEditor = (props: commonEditorProps) => {
  const { title, value, onChange, values, handlePrimaryPaletteChange } = props;
  const classes = useStyles();
  const {
    config: { webUIConfig },
  } = useConfig();
  const optionsColumns: Object[] = useMemo(
    () => [
      { title: 'Name', field: 'label' },
      {
        title: 'Colors',
        field: 'colors',
        render: ({ colors }: { colors: string[] }) => <ColorPickerPage value={colors} />,
      },
      {
        title: 'Primary',
        field: 'name',
        align: 'center',
        render: ({ name }: { name: string }) => {
          return (
            <Radio
              checked={values?.primaryColorPalette === name}
              onChange={(e) => {
                if (e.target.checked && values?.primaryColorPalette !== name) {
                  handlePrimaryPaletteChange?.(name);
                }
              }}
              color="primary"
            />
          );
        },
      },
    ],
    [values?.primaryColorPalette]
  );

  const [colors, setColors] = useState(value);
  const primary = webUIConfig.theme.primary;
  const [openAdd, setOpenAdd] = useState(false);
  const [paletteData, setPaletteData] = useState<ColorPalette[]>([]);
  const [editItem, setEditItem] = useState<ColorPalette | null>(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setPaletteData(values?.colorPalettes ?? []);
  }, [values]);

  const addPaletteColor = useCallback(() => {
    setColors((prev) => [...prev, prev[prev.length - 1] ?? primary]);
  }, [setColors, primary]);

  const deletePaletteColor = useCallback(
    (index: number) => {
      setColors((prev) => toSplicedArray(prev, index, 1));
    },
    [setColors]
  );

  const onChangeColor = useCallback(
    (value: string, index: number) => {
      setColors((prev) => toSplicedArray(prev, index, 1, value));
    },
    [setColors]
  );

  useEffect(() => {
    if (openAdd && editMode) {
      setColors(editItem?.colors ?? []);
    } else {
      setColors([]);
    }
  }, [openAdd, editItem, editMode]);

  const handleDelete = (row: ColorPalette) => {
    onChange?.([row], 'delete');
  };

  const handleUpdate = (value: ColorPalette) => {
    if (!isFormFieldInvalid(value)) return;
    onChange?.([value], 'replace');
    setOpenAdd(false);
    setEditItem(null);
    setEditMode(false);
  };

  return (
    <>
      {openAdd && (
        <AddModal
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          colorMappings={colors}
          setColorMappings={setColors}
          addPaletteColor={addPaletteColor}
          deletePaletteColor={deletePaletteColor}
          onChangeColor={onChangeColor}
          setPaletteData={setPaletteData}
          onChange={onChange}
          title={editMode ? 'Edit' : 'Add'}
          handleUpdate={handleUpdate}
          editMode={editMode}
          setEditMode={setEditMode}
          editComponent={editItem}
        />
      )}
      <div className={classes.headerText}>Analytics Chart Color</div>
      <Box className={`${classes.container}`}>
        <Box className={classes.header}>
          <Typography className={`${classes.headerText} ${classes.headerText2}`}>
            {title}
          </Typography>
          <Box>
            <IconButton
              onClick={() => {
                setOpenAdd(true);
                setEditItem(null);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        <EditableTable
          columns={optionsColumns}
          data={paletteData}
          fileType="simpleOptions"
          onAdd={() => {}}
          onUpdate={() => {}}
          onDelete={(deleteItem) => handleDelete(deleteItem as any)}
          hasHeader={false}
          isDrag={false}
          setEditData={(value) => {
            if (value) {
              setEditItem(value as ColorPalette);
              setEditMode(true);
              setOpenAdd(true);
            }
          }}
          tableContainer={classes.tableContainer}
          toolbarStyle={styles.table}
          headerStyle={classes.tableHeader}
        />
      </Box>
    </>
  );
};

const styles = {
  table: {
    backgroundColor: colors.white,
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
      margin: '10px 0',
      overflow: 'hidden',
    },
    tableContainer: {
      marginTop: 0,
    },
    tableHeader: {
      '& .MuiTableCell-head': {
        backgroundColor: colors.white,
      },
    },
    header: {
      backgroundColor: colors.black5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 23,
      paddingRight: 7,
      height: 45,
    },
    headerText: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      lineHeight: '100%',
    },
    headerText2: {
      fontWeight: 500,
    },
    icon: {
      color: colors.black54,
      fontSize: 20,
      fontWeight: 500,
    },
    border: {
      borderBottom: `1px solid ${colors.black10}`,
    },
    primaryBtn: {
      color: theme.palette.primary.main,
    },
  })
);

export default CommonEditor;
