"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyClientEventListeners = exports.getServiceHeartbeatKey = exports.parseQueueProcessingValue = exports.getQueueProcessingValue = exports.getQueueProcessingKey = exports.getQueueKey = void 0;
const enums_1 = require("./enums");
// #region Keys and Values
const getQueueKey = (pgNotifyChannel) => `${enums_1.MEMCACHE_KEYS.QUEUE}:${pgNotifyChannel}`;
exports.getQueueKey = getQueueKey;
const getQueueProcessingKey = (queueKey) => `${queueKey}:${enums_1.MEMCACHE_KEYS.PROCESSING}`;
exports.getQueueProcessingKey = getQueueProcessingKey;
const getQueueProcessingValue = (value, serviceId) => `${value}:${serviceId}`;
exports.getQueueProcessingValue = getQueueProcessingValue;
const parseQueueProcessingValue = (value) => value.split(':');
exports.parseQueueProcessingValue = parseQueueProcessingValue;
const getServiceHeartbeatKey = (service) => `${enums_1.MEMCACHE_KEYS.SERVICE}:${service}:${enums_1.MEMCACHE_KEYS.HEARTBEAT}`;
exports.getServiceHeartbeatKey = getServiceHeartbeatKey;
// #endregion
// #region Client
const applyClientEventListeners = (client, clientName) => {
    const logMessagePrefix = `Memcache client "${clientName}"`;
    client.on(enums_1.REDIS_CLIENT_EVENT.CONNECT, () => {
        console.log(`${logMessagePrefix} connected to Redis cache.`);
    }).on(enums_1.REDIS_CLIENT_EVENT.READY, () => {
        console.log(`${logMessagePrefix} ready.`);
    }).on(enums_1.REDIS_CLIENT_EVENT.END, () => {
        console.log(`${logMessagePrefix} connection ended.`);
    }).on(enums_1.REDIS_CLIENT_EVENT.ERROR, (error) => {
        console.error(`${logMessagePrefix} connection error: `, error);
    }).on(enums_1.REDIS_CLIENT_EVENT.RECONNECTING, () => {
        console.log(`${logMessagePrefix} reconnecting...`);
    }).on(enums_1.REDIS_CLIENT_EVENT.SHARDED_CHANNEL_MOVED, (args) => {
        console.log(`${logMessagePrefix} server's sharded channel moved: `, args);
    }).on(enums_1.REDIS_CLIENT_EVENT.CLOSE, (args) => {
        console.log(`${logMessagePrefix} connection closed: `, args);
    });
    return client;
};
exports.applyClientEventListeners = applyClientEventListeners;
// #endregion
