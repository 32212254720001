import {
  Box,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { colors } from 'utils/colors';
import { TGColorPicker, TGIosSwitch, TGSelect, TextInputTG } from './formElements';
import { ColorPalettesProps, CommonSettings } from '../pages/ui/components/settings/commonTypes';
import _ from 'lodash';
import CommonEditor from 'components/FormElements/commonEditor';
import { ColorPalette } from '@terragotech/gen5-config-lib';
import { UPDATE_PRIMARY_PALETTE } from 'views/pages/ui/components/settings/General';

const RowItem = (props: CommonSettings) => {
  const classes = useStyles();
  const { title, description, components } = props;

  const handlePaletteChange = (comp: ColorPalettesProps,newColors: ColorPalette[] | undefined, action?: string, deletedPaletteName?: string) => { 
    if(newColors){
      comp.action?.({
        colorPalettes: newColors,
      }, action);
    }
  }

  return (
    <Grid container>
      <Grid item md={4}>
        <Box className={classes.leftSection}>
          <Typography className={classes.header1}>{title}</Typography>
          <Typography className={classes.para}>{description}</Typography>
        </Box>
      </Grid>
      <Grid item md={8} className={classes.gridItem}>
        {components.map((comp) => {
          return (
            <Box key={comp.key} className={classes.childContainer}>
              {comp.type === 'textInput' && (
                <TextInputTG
                  label={comp.label}
                  value={comp.value}
                  id={comp.label}
                  onChange={comp.action}
                  type={comp.textInputType}
                />
              )}
              {comp.type === 'iosSwitch' && (
                <TGIosSwitch
                  title={comp.title}
                  description={comp.description}
                  checked={comp.checked || false}
                  onChange={(checked) => comp.action(checked)}
                  containerClass={comp.container}
                  otherComponent={
                    <>
                      {comp.title === 'Enable Legal Disclaimer' && comp.checked ? (
                        <TextInputTG
                          value={comp?.textAreaValue}
                          className={classes.textArea}
                          onChange={comp?.onChangeTextArea}
                          multiline
                          minRows={3}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  }
                />
              )}
              {comp.type === 'header' && (
                <Typography id={comp?.label} className={classes.headerText}>
                  {comp?.label}
                </Typography>
              )}
              {comp.type === 'select' && !comp.isHide && (
                <>
                  <TGSelect
                    id={comp?.label}
                    label={comp.label}
                    value={comp.value as string}
                    options={comp?.options || []}
                    onChange={(value) => comp.action(value as string)}
                  />
                  {_.size(comp?.info) > 0 && (
                    <Typography className={classes.infoMessage}>{comp?.info}</Typography>
                  )}
                </>
              )}
              {comp.type === 'colorPicker' && (
                <TGColorPicker
                  title={comp.title}
                  value={comp.value as string}
                  summary={comp.description}
                  onChange={(color: string) => comp.action(color)}
                />
              )}
              {comp.type === 'colorPalettes' && (
            
                <CommonEditor
                  title={'Color Palettes'}
                  value={
                    comp.value.colorPalettes?.find((x) => x.name === comp.value.primaryColorPalette)
                      ?.colors ?? ['#5c9a76']
                  }
                  values = {comp.value}
                  summary={comp.description}
                  onChange={(newColors: ColorPalette[] | undefined,action?: string, deletedPaletteName?: string) => {
                    handlePaletteChange(comp,newColors,action,deletedPaletteName)
                  }}
                  handlePrimaryPaletteChange={(name:string) => {
                    comp.action?.({
                      primaryColorPalette: name
                    },UPDATE_PRIMARY_PALETTE)
                  }}
                />
                // <FormLegend
                //   title={comp.title}
                //   // legendContainer={classes.legendContainer}
                //   rightContent={
                //     // <div className={classes.rightContainer}>
                //     //   <IconButton onClick={() => console.log(`S5-Test-RowItem: setOpenAdd`)} className={classes.iconButton}>
                //     //     <FontAwesomeIcon className={classes.addIcon} icon={faPlus} />
                //     //   </IconButton>
                //     // </div>
                //     <div>
                //       <IconButton onClick={() => console.log(`S5-Test-RowItem: setOpenAdd`)}>
                //         <FontAwesomeIcon icon={faPlus} />
                //       </IconButton>
                //     </div>
                //   }
                //   body={
                //     <>
                //       <EditableTable
                //         columns={[
                //           { title: 'Name', field: 'name' },
                //           { title: 'Label', field: 'label' },
                //           { title: 'Colors', field: 'colors', render: (data: ColorPalette, type: "row" | "group") => (
                //             <TGColorPalette
                //               value={data.colors}
                //               summary={comp.description}
                //               disabled={true}
                //             />
                //           ) },
                //         ]}
                //         data={comp.value.colorPalettes}
                //         // toolbarStyle={otherAttributesTableToolbarStyle}
                //         // fileType="simpleOptions"
                //         // onFileImport={CSVFileImport}
                //         onAdd={(row, resolve, reject) => console.log(`S5-Test-RowItem: onAdd: `, row)}
                //         onUpdate={(row, oldRow, resolve, reject) => console.log(`S5-Test-RowItem: onUpdate: row: `, row, ', oldRow: ', oldRow)}
                //         onDelete={(row) => console.log(`S5-Test-RowItem: onDelete: `, row)}
                //         // onReOrder={(value) => console.log(`S5-Test-RowItem: onReorder: `, value)}
                //         options={{ paging: false, search: false }}
                //         hasHeader={false}
                //         // onOpenAddModal={openAdd}
                //         // onCloseAddModal={setOpenAdd}
                //         // tableContainer={classes.tableContainer}
                //       />
                //     </>
                //   }
                // />
              )}
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftSection: {
      paddingRight: 30,
    },
    header1: {
      fontSize: 18,
      fontWeight: 500,
      color: colors.black,
      marginBottom: 7,
      lineHeight: '100%',
    },
    para: { fontSize: 15, fontWeight: 400, color: colors.black75, lineHeight: '100%' },
    gridItem: {
      '& :last-child': {
        marginBottom: 0,
      },
    },
    childContainer: {
      marginBottom: 25,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
      marginTop: 32,
    },
    infoMessage: {
      color: colors.black60,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      marginTop: 6,
    },
    textArea: {
      marginTop: 18,
      '& .MuiInputBase-root': {
        padding: '15px 6px 17px 6px',
      },
    },
  })
);

export default RowItem;
