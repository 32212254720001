import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { HEADER_HEIGHT, LEFT_MENU_WIDTH, RECORD_SEARCH_BAR_HEIGHT } from 'utils/Utils';
import { colors } from 'utils/colors';

const TOP_MENU_HEIGHT = 46;

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: LEFT_MENU_WIDTH,
      height: `calc(100vh - ${HEADER_HEIGHT + TOP_MENU_HEIGHT}px)`,
      borderRight: `1px solid ${colors.black10}`,
      backgroundColor: colors.white,
      position: 'relative',
      flexShrink: 0,
    },
    menuContainer: {
      borderBottom: `1px solid ${colors.black10}`,
      padding: '11px 25px 10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    itemTxt: {
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
    },
    listBody: {
      height: `calc(100% - ${TOP_MENU_HEIGHT + RECORD_SEARCH_BAR_HEIGHT}px)`,
      overflow: 'scroll',
    },
    list: {
      padding: '7px 24px',
      paddingBottom: 10,
      paddingRight: 6,
    },
    menuButton: {
      width: '100%',
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      borderRadius: 5,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    menuButtonTxt: {
      color: colors.black54,
      fontSize: 14,
    },
    linkButton: {
      height: 20,
      padding: 3,
      backgroundColor: colors.linkBackground,
      color: colors.black54,
      fontSize: 12,
      border: `1px solid ${colors.black10}`,
    },
  })
);
