import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { V2GroupComponentDef } from '@terragotech/page-renderer';
import collapseImg from './img/Collapse.png';
import { PageControlProps } from '../PageTypes';
import { LabelSubText, ShrinkInfo } from './Common';
import { colors } from 'utils/colors';
import _ from 'lodash';
import { CustomPageBadges } from './CustomPageBadges';

const GroupControl: FunctionComponent<PageControlProps<V2GroupComponentDef> & { name: string }> = ({
  controlDefinition,
  isPageEditor,
}) => {
  const capitalizedControlType = _.upperFirst(controlDefinition.type);
  return (
    <Panel isPageEditor={isPageEditor}>
      <PanelHeader isPageEditor={isPageEditor}>
        {!isPageEditor && (
          <CollapseHolder>
            <img src={collapseImg} alt="Collapse Button" />
          </CollapseHolder>
        )}

        <PanelTitle isPageEditor={isPageEditor}>{controlDefinition.label}</PanelTitle>
        {isPageEditor && (
          <LabelSubText
            isGroup={true}
            isDisplayOptionsEnabled={Boolean(controlDefinition.displayOptions)}
          >
            <span>
              {capitalizedControlType}:{' '}
              {
                <ShrinkInfo>
                  {controlDefinition.template.rows} Row, {controlDefinition.template.columns}{' '}
                  Columns
                </ShrinkInfo>
              }
            </span>
          </LabelSubText>
        )}
        <SubSpan>
          <CustomPageBadges controlDefinition={controlDefinition} />
        </SubSpan>
      </PanelHeader>
    </Panel>
  );
};

const Panel = styled.div<{ isPageEditor?: boolean }>`
  margin-top: ${(props) => (props.isPageEditor ? '0px' : '8px')};
`;
const PanelHeader = styled.div<{ isPageEditor?: boolean }>`
  height: 22px; // should be 30 total
  padding-top: 8px;
  border-bottom: ${(props) =>
    props.isPageEditor ? 'none' : `1px solid ${colors.grayPlaceholder}`};
  text-align: ${(props) => (props.isPageEditor ? 'left' : 'center')};
  text-transform: ${(props) => (props.isPageEditor ? 'none' : 'uppercase')};
  font-weight: 500;
  font-size: ${(props) => (props.isPageEditor ? '15px' : '12px')};
  line-height: 100%;
  color: ${(props) => (props.isPageEditor ? 'black' : colors.title)};
`;
const CollapseHolder = styled.div`
  position: absolute;
  margin-left: 10px;
`;
const PanelTitle = styled.div<{ isPageEditor?: boolean }>`
  display: flex;
  position: relative;
  margin-bottom: ${(props) => (props.isPageEditor ? '8px' : '0px')};
`;
const SubSpan = styled.div`
  margin-top: 12px;
`;
export default GroupControl;
