import { faSave } from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToSquare, faFileImport, faPen, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { MobileUIConfig, WebUIConfig } from '@terragotech/gen5-config-lib';
import { isEqual } from 'lodash';
import Papa from 'papaparse';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import useRouteBlocker from '../../../../../../common/useBlocker';
import { EditableTable, MaterialTableRef } from '../../../../../../components/EditableTable';
import { errorMsg, infoMsg, successMsg } from '../../../../../../components/SnackbarUtilsConfigurator';
import { useConfig } from '../../../../../../context/ConfigContext';
import { colors } from '../../../../../../utils/colors';
import {
  AggregateUICustomizations,
  MobileAggrUICustomization,
  WebAggrUICustomization,
} from '../../../../../../utils/types';
import PageHeader from '../../../../../components/PageHeader';
import useCommonStyles from '../../../../../useCommonStyles';
import { getAdditionalLabels } from './additionalLabels';
import AddLabelModal from './AddLabelModal';
import AddLanguageModal from './AddLanguageModal';
import EditLanguageModal from './EditLanguageModal';
import { TextInputTG } from 'views/components/formElements/TextInputTG';
import { V2FormTemplate } from '@terragotech/gen5-datamapping-lib';
import { V2PageTemplate } from '@terragotech/page-renderer';

interface LanguageModalState {
  editLanguage: boolean;
  label: boolean;
  language: boolean;
}

const LanguageOptions = () => {
  const {
    config,
    getAggregates,
    getCustomPages,
    getUICustomizations,
    getDefaultLanguage,
    getSupportedLanguages,
    getTranslationsDictionary,
    setFullTranslations,
  } = useConfig();


  const [supportedLanguages, setSupportedLanguages] = useState<string[]>(getSupportedLanguages()?.length ? getSupportedLanguages() : ['English']);
  const [defaultLanguage, setDefaultLanguage] = useState<string>(() => {
    const savedLanguage = getDefaultLanguage();
    return supportedLanguages.includes(savedLanguage) ? savedLanguage : 'English';
  });
  const [modalState, setModalState] = useState<LanguageModalState>({
    editLanguage: false,
    label: false,
    language: false,
  });
  const [newLanguage, setNewLanguage] = useState<string>('');
  const [newLabel, setNewLabel] = useState<string>('');
  const [previousLangState, setPreviousLangState] = useState<string | null>(null);
  const [currentLangState, setCurrentLangState] = useState<string>('');
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const tableRef = useRef<MaterialTableRef>(null);
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const openModal = useCallback((modalType: 'editLanguage' | 'label' | 'language', language?: string) => {
    setModalState((prev) => ({ ...prev, [modalType]: true }));
    if (modalType === 'editLanguage' && language) {
      setPreviousLangState(language);
      setCurrentLangState(language);
    }
  }, []);

  const closeModal = useCallback((modalType: 'editLanguage' | 'label' | 'language') => {
    setModalState((prev) => ({ ...prev, [modalType]: false }));
    if (modalType === 'editLanguage') {
      setPreviousLangState(null);
      setCurrentLangState('');
    } else if (modalType === 'label') {
      setNewLabel('');
    }
  }, []);

  const saveNewLanguage = useCallback(async () => {
    if (newLanguage.trim() === '') return;
    if (supportedLanguages.includes(newLanguage)) {
      infoMsg('Language already exists');
      return;
    }

    closeModal('language');

    await new Promise((resolve) => {
      setSupportedLanguages((prev) => [...new Set([...prev, newLanguage])]);
      setIsDirty(true);
      setNewLanguage('');
      setDefaultLanguage(defaultLanguage);
      resolve(true);
    }).then(() => {
      successMsg('New language added successfully');
    });
  }, [newLanguage, supportedLanguages, closeModal, defaultLanguage]);

  const saveEditedLanguage = useCallback(() => {
    if (previousLangState && currentLangState.trim() !== '') {
      closeModal('editLanguage');
      const updatedLanguages = supportedLanguages.map((lang) =>
        lang === previousLangState ? currentLangState : lang
      );

      setSupportedLanguages(updatedLanguages);
      if (defaultLanguage === previousLangState) {
        setDefaultLanguage(currentLangState);
      }
      const updatedLabels = { ...currLabelsRef.current };
      Object.entries(updatedLabels).forEach(([label, translations]) => {
        if (translations[previousLangState]) {
          translations[currentLangState] = translations[previousLangState];
          delete translations[previousLangState];
        }
      });
      setIsLoading(true);
      setCurrLabels(updatedLabels);
      currLabelsRef.current = updatedLabels;
      setIsDirty(true);
    }
  }, [previousLangState, currentLangState, supportedLanguages, defaultLanguage, closeModal]);

  const columns = useMemo(() => {
    const baseColumns = [
      { title: 'Label', field: 'label' },
    ];

    const languageColumns = supportedLanguages
      .filter((language) => language !== 'English')
      .map((language) => ({
        title: language,
        field: language.toLowerCase(),
        editable: true,
        render: (rowData: any) => {
          const text = rowData[language] as string || '';
          const { label: currLabel } = rowData as { label: string, [key: string]: string };
          return <TextInputTG value={text} onChange={(value: string) => handleLabelChange(currLabel, language, value)} useLocalValue />
        },
      }));

    return [...baseColumns, ...languageColumns];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLanguages]);

  const getAllLabels = useMemo(
    (): { [label: string]: { [language: string]: string } } => {
      const isWebAggrUICustomization = (custom: any): custom is WebAggrUICustomization => {
        return custom?.tableActions !== undefined;
      };
      const isMobileAggrUICustomization = (custom: any): custom is MobileAggrUICustomization => {
        return custom?.editorActions !== undefined;
      };
      const labels: { [label: string]: { [language: string]: string } } = { ...config.translations?.dictionary };
      const addLabelIfValid = (label: string | undefined) => {
        if (label && label !== 'none') {
          if (!labels[label]) {
            labels[label] = {};
          }
        }
      };
      const processUIConfigLabels = (
        uiConfig: (WebUIConfig | MobileUIConfig) &
          Record<string, any> & {
            aggregateUICustomizations?: Record<string, any>;
          },
        configType: string
      ) => {
        const { labels: uiLabels, fabActions, symbolLegend } = uiConfig;
        const { project, applicationName, copyrightInfo } = uiLabels ?? {};
        addLabelIfValid(project?.singular);
        addLabelIfValid(project?.plural);
        addLabelIfValid(applicationName);
        addLabelIfValid(copyrightInfo);
        if (configType === 'web') {
          const { importActions } = uiConfig as WebUIConfig;
          importActions?.forEach((action) => addLabelIfValid(action?.button?.label));
        }
        fabActions?.forEach((action) => addLabelIfValid(action?.button?.label));
        symbolLegend?.forEach((symbol) => addLabelIfValid(symbol?.name));
      };
      const processCustomizations = (customization: AggregateUICustomizations | undefined) => {
        if (!customization) return;
        Object.entries(customization).forEach(([, currCustomization]) => {
          const { cardDefinition, editorWorkflows, multiSelectActions } = currCustomization;
          cardDefinition?.otherAttributes?.forEach((attribute) =>
            addLabelIfValid(attribute?.itemLabel)
          );
          cardDefinition?.buttons?.forEach((button) => addLabelIfValid(button?.label));
          editorWorkflows?.forEach((workflow) => addLabelIfValid(workflow?.label));
          multiSelectActions?.forEach((action) => addLabelIfValid(action?.label));
          if (isWebAggrUICustomization(currCustomization)) {
            const { tableActions } = currCustomization as WebAggrUICustomization;
            tableActions.forEach((tableAction) => {
              addLabelIfValid(tableAction.label);
            });
          }
          if (isMobileAggrUICustomization(currCustomization)) {
            const { editorActions } = currCustomization as MobileAggrUICustomization;
            editorActions.forEach((editorAction) => {
              addLabelIfValid(editorAction.label);
            });
          }
        });
      };
      const getCustomPageGroupLabels = (template: V2PageTemplate) => {
        const { elements } = template;
        Object.entries(elements ?? {}).forEach(([, element]) => {
          const { component } = element;
          addLabelIfValid(component?.label);
        });
      }
      Object.entries(getCustomPages()).forEach(([, page]) => {
        const { elements } = page;
        Object.entries(elements ?? {}).forEach(([, element]) => {
          const { component } = element;
          addLabelIfValid(component?.label);
          if (component?.type === 'group') {
            getCustomPageGroupLabels(component?.template);
          }
        });
      });
      const getFormGroupLabels = (template: V2FormTemplate) => {
        const { components } = template;
        Object.entries(components ?? {}).forEach(([, component]) => {
          addLabelIfValid(component?.label);
          if (component?.type === 'group') {
            getFormGroupLabels(component?.template);
          }
        });
      }
      getAggregates().forEach((agg) => {
        const { singularName, pluralName, properties, commands } = agg;
        addLabelIfValid(singularName);
        addLabelIfValid(pluralName);
        Object.entries(properties ?? {}).forEach(([, propType]) => {
          addLabelIfValid(propType?.label);
        });
        Object.entries(commands ?? {}).forEach(([, commandDef]) => {
          addLabelIfValid(commandDef?.defaultLabel);
          commandDef?.versions?.forEach((version) => {
            if (version.commandType === 'FORM') {
              Object.entries(version.formDefinition?.components ?? {}).forEach(
                ([, formCompDef]) => {
                  addLabelIfValid(formCompDef?.label);
                  if (formCompDef?.type === 'group') {
                    getFormGroupLabels(formCompDef.template);
                  }
                }
              );
            }
          });
        });
      });
      processUIConfigLabels({ ...config.webUIConfig }, 'web');
      processUIConfigLabels({ ...config.mobileUIConfig }, 'mobile');
      const currWebUICustom = getUICustomizations('webUIConfig');
      const currMobileUICustom = getUICustomizations('mobileUIConfig');
      [currWebUICustom, currMobileUICustom].forEach((custom) =>
        processCustomizations({ ...custom })
      );
      const additionalLabels = getAdditionalLabels();
      for (const label of additionalLabels) {
        addLabelIfValid(label);
      }
      return labels;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [currLabels, setCurrLabels] = useState(getAllLabels);
  const currLabelsRef = useRef(currLabels);

  const deleteLanguage = useCallback((language: string) => {
    if (language === defaultLanguage) {
      setDefaultLanguage('English');
    }
    const updatedLanguages = supportedLanguages.filter((lang) => lang !== language);
    setSupportedLanguages(updatedLanguages);
    setIsDirty(true);
  }, [defaultLanguage, supportedLanguages]);

  const savePage = useCallback(() => {
    setSaveInProgress(true);
    new Promise(async (resolve, reject) => {
      try {
        setFullTranslations(defaultLanguage, supportedLanguages, currLabelsRef.current);
        setIsDirty(false);
        successMsg('Language Options saved successfully');
        resolve(true);
      } catch (error) {
        errorMsg("Error saving translations");
        reject(error);
      }
    }).finally(() => setSaveInProgress(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage, supportedLanguages]);


  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname !== nextLocation.pathname) {
      const areSupportedLanguagesChanged = !isEqual(getSupportedLanguages(), supportedLanguages);
      const isDefaultLanguageChanged = !isEqual(getDefaultLanguage(), defaultLanguage);
      const areTranslationsChanged = !isEqual(getTranslationsDictionary(), currLabelsRef.current);
      const isConfigChanged =
        areSupportedLanguagesChanged ||
        isDefaultLanguageChanged ||
        areTranslationsChanged;
      return isConfigChanged || isDirty;
    }
    return false;
  });

  useRouteBlocker({ blocker, onSave: savePage });

  const sortedLabels = useMemo(() => Object.fromEntries(
    Object.entries(currLabels).sort(([labelA], [labelB]) =>
      labelA.toLowerCase().trim().localeCompare(labelB.toLowerCase().trim())
    )
  ), [currLabels]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 100);
  }, [sortedLabels])

  const handleExport = useCallback(() => {
    const languages = supportedLanguages.filter(lang => lang !== 'English');

    const exportData = Object.entries(currLabelsRef.current).map(([label, translations]) => ({
      Label: label,
      ...Object.fromEntries(languages.map(lang => [lang, translations[lang] ?? '']))
    }));

    if (exportData.length === 0) {
      exportData.push(Object.fromEntries([['Label', ''], ...languages.map(lang => [lang, ''])]));
    }

    const csv = Papa.unparse(exportData, { header: true, skipEmptyLines: false });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `Translations-${new Date().toISOString()}.csv`);
    link.click();
  }, [supportedLanguages, currLabelsRef]);

  const handleImport = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: ({ data }: { data: Array<{ Label: string;[key: string]: string }> }) => {
        const updatedLabels = data.reduce(
          (
            acc: {
              [x: string]: {
                [language: string]: string;
              };
            },
            { Label, ...translations }
          ) => {
            if (Label) {
              const cleanedTranslations = {
                ...Object.fromEntries(
                  Object.entries(translations).filter(([_, translation]) => translation?.trim())
                ),
              };
              acc[Label] = cleanedTranslations;
            }
            return acc;
          },
          {}
        );

        setIsLoading(true);
        setCurrLabels(updatedLabels);
        currLabelsRef.current = updatedLabels;
        setIsDirty(true);
        successMsg('CSV file successfully uploaded');
      },
    });
  }, []);

  const formatTableData = useMemo(() => {
    const currData = Object.entries(sortedLabels)
      .filter(([label]) => label !== 'English')
      .map(([label, translations]) => ({
        label,
        ...translations,
      }));
    return currData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedLabels]);

  const onChangeLanguageSelection = useCallback((e: ChangeEvent<{ value: unknown }>) => {
    if (e.target.value !== 'Add') {
      setDefaultLanguage(e.target.value as string);
    }
  }, []);

  const handleLabelChange = useCallback((labelKey: string, language: string, value: string) => {
    if (!currLabelsRef.current[labelKey]) {
      currLabelsRef.current[labelKey] = {};
    }
    currLabelsRef.current[labelKey][language] = value;

    setIsDirty(true);
  }, []);

  const saveNewLabel = useCallback(async () => {
    if (newLabel.trim() === '') return;
    if (currLabelsRef.current.hasOwnProperty(newLabel)) {
      infoMsg('Label already exists');
      return;
    }

    closeModal('label');
    setIsLoading(true);

    await new Promise((resolve) => {
      currLabelsRef.current[newLabel] = { English: newLabel };
      setCurrLabels({ ...currLabelsRef.current });
      setIsDirty(true);
      resolve(true);
    }).then(() => {
      successMsg('New label saved successfully');
    });
  }, [newLabel, closeModal]);

  const deleteRow = useCallback(async (row: object) => {
    const { label } = row as { label: string };
    setIsLoading(true);

    await new Promise((resolve) => {
      delete currLabelsRef.current[label];
      setCurrLabels({ ...currLabelsRef.current });
      setIsDirty(true);
      resolve(true);
    }).then(() => {
      successMsg('Row deleted successfully');
    });
  }, []);

  const MemoizedAddLanguageModal = useCallback(
    () => (
      <AddLanguageModal
        isOpen={modalState.language}
        closeModal={() => closeModal('language')}
        newLanguage={newLanguage}
        onChangeNewLanguage={setNewLanguage}
        saveNewLanguage={saveNewLanguage}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalState.language, newLanguage, saveNewLanguage, closeModal, isLoading]
  );

  const MemoizedEditLanguageModal = useCallback(
    () => (
      <EditLanguageModal
        isOpen={modalState.editLanguage}
        closeModal={() => closeModal('editLanguage')}
        editedLanguage={currentLangState}
        setEditedLanguage={setCurrentLangState}
        saveEditedLanguage={saveEditedLanguage}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalState.editLanguage, currentLangState, saveEditedLanguage, closeModal, isLoading]
  );

  const MemoizedAddLabelModal = useCallback(() => (
    <AddLabelModal
      open={modalState.label}
      onClose={() => closeModal('label')}
      newLabel={newLabel}
      setNewLabel={setNewLabel}
      addLabel={saveNewLabel}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    />), [modalState.label, newLabel, saveNewLabel, closeModal, isLoading]);

  const MemoizedTable = useMemo(() => (
    <EditableTable
      title="Translations"
      data={formatTableData}
      columns={columns}
      isLanguageOptions={true}
      hasHeader={false}
      containerClass={classes.tableContainer}
      tableRef={tableRef}
      isLoading={isLoading}
      customTableHeight={'62vh'}
      onDelete={deleteRow}
      confirmOnDelete
    // eslint-disable-next-line react-hooks/exhaustive-deps
    />), [isLoading, columns, classes.tableContainer, formatTableData]);

  return (
    <>
      <Box>
        <Box className={`${commonClasses.innerContainer} ${classes.topContainerPadding}`}>
          <PageHeader
            title="Language Options"
            icon={faSave}
            buttonText="Save"
            onSave={savePage}
            showDivider
            showLinkUnlink
            saveInProgress={saveInProgress || isLoading}
          />
          <Box display="flex" alignItems="center" marginTop={3}>
            <Typography variant="h6" style={{ marginRight: 20 }}>
              Default Language:
            </Typography>
            <FormControl variant="outlined" className={classes.languageSelect}>
              <Select
                value={defaultLanguage}
                onChange={onChangeLanguageSelection}
                renderValue={(selected) => <Typography>{selected as string}</Typography>}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                style={{ height: 40 }}
              >
                {supportedLanguages.map((language, index) => (
                  <MenuItem key={language} value={language}>
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      className={`${classes.row} ${supportedLanguages.length - 1 !== index && classes.border}`}
                    >
                      <Typography>{language}</Typography>
                      {language !== 'English' && (
                        <Box style={{ justifyContent: 'end', marginLeft: 20 }}>
                          <IconButton
                            className={classes.mapperIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal('editLanguage', language);
                            }}
                            size='small'
                            style={{ marginRight: 10 }}
                          >
                            <FontAwesomeIcon icon={faPen} className={classes.icon} />
                          </IconButton>
                          <IconButton
                            className={classes.mapperIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteLanguage(language);
                            }}
                            size='small'
                          >
                            <FontAwesomeIcon icon={faTrashAlt} className={classes.icon} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => openModal('language')} value={'Add'}>
                  <Box display="flex" alignItems="center" width="100%">
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                    <Typography>Add Language</Typography>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {modalState.language && (MemoizedAddLanguageModal())}
          {modalState.editLanguage && (MemoizedEditLanguageModal())}
          {modalState.label && (MemoizedAddLabelModal())}
        </Box>
        <Box>
          <Box display="flex" justifyContent="flex-end" style={{ marginRight: 45 }}>
            <Button
              disableElevation
              variant="outlined"
              color="default"
              startIcon={
                <FontAwesomeIcon icon={faPlus} className={classes.buttonIcon} />
              }
              style={{ marginRight: 10 }}
              onClick={async () => {
                openModal('label');
              }}
            >
              <Typography>Add</Typography>
            </Button>
            <Button
              disableElevation
              variant="outlined"
              color="default"
              onClick={handleExport}
              startIcon={
                <FontAwesomeIcon icon={faArrowDownToSquare} className={classes.buttonIcon} />
              }
              style={{ marginRight: 10 }}
            >
              <Typography>Export</Typography>
            </Button>
            <input
              accept=".csv"
              style={{ display: 'none' }}
              id="import-button-file"
              type="file"
              onChange={handleImport}
            />
            <label htmlFor="import-button-file">
              <Button
                disableElevation
                variant="outlined"
                color="default"
                startIcon={
                  <FontAwesomeIcon icon={faFileImport} className={classes.buttonIcon} />
                }
                component="span"
              >
                <Typography>Import</Typography>
              </Button>
            </label>
          </Box>
        </Box>
      </Box>
      {MemoizedTable}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    buttonIcon: {
      height: 18,
      width: 18,
      color: colors.greyText,
    },
    languageSelect: {
      minWidth: 200,
      height: 35,
      '& .MuiSelect-root': {
        fontSize: 15,
        color: colors.black,
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '3px 7px 3px 16px',
      height: 'auto',
    },
    mapperIcon: {
    },
    icon: {
      color: colors.black54,
      fontSize: 20,
      fontWeight: 500,
    },
    border: {
      borderBottom: `1px solid ${colors.black10}`,
    },
    tableContainer: {
      marginLeft: 40,
      marginRight: 40,
      flex: 1,
      paddingBottom: 50,
      position: 'relative'
    },
    topContainerPadding: {
      padding: '0px 50px',
    },
  })
);

export default LanguageOptions;
