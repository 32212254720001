import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { NavigationControlProps } from '../FormTypes';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { errorMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import { CONFIRMATION } from '../../../../../utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';
import { WebNavigation } from '@terragotech/gen5-config-lib';
import { emptySelectedItems } from 'views/pages/settings/components/NavigationEditor';
import { controlMap } from 'pages/aggregates/components/CommandFormEditor/MobileTheme';
import clsx from 'clsx';
import { generateNavigationName } from 'pages/aggregates/utils/navigationUtils';

const Navigation: FunctionComponent<NavigationControlProps<WebNavigation> & { name: string }> = ({
  controlDefinition,
  editItem,
  focusedItem,
  navDefinition,
  hasError,
  error,
  name,
  selectedItems,
  setNavDefinition,
  selectNextComponent,
  setSelectedItems,
  subMenuPath,
  setExistingLabelError,
  setEmptyLabelError,
  setEmptyIconError,
  setEmptyLinkError,
}) => {
  const classes = useStyles();
  const { subMenu } = controlDefinition;
  const { openConfirmation } = useConfirmDialog();
  const STANDARD_ICON_LEFT_MARIGIN = 26;
  const addItem = (event: React.MouseEvent, type: string) => {
    if (navDefinition && setNavDefinition) {
      let currControlDef = controlDefinition;
      let subMenuOrder = currControlDef.subMenu.order;
      let dropComponents = currControlDef.subMenu.components;
      const compName = generateNavigationName(subMenuOrder, currControlDef.label);
      const item = {
        type: 'navigation' as const,
        label: compName,
        icon: '',
        conditionalMap: { outputDefinition: { outputNode: 'NAVIGATION_OPTIONS' } },
        subMenu: emptySelectedItems,
        level: currControlDef.level + 1,
      };
      dropComponents[compName] = item;
      subMenuOrder.push(compName);
      setNavDefinition(navDefinition);
      setSelectedItems(event, compName, name, {
        ...item,
        name: compName,
        droppableId: name,
        index: subMenuOrder.length - 1,
      });
    }
  };

  const handleItemClick = (
    event: React.MouseEvent,
    componentName: string,
    name: string,
    index: number,
    component?: WebNavigation
  ) => {
    event.stopPropagation();
    if (hasError) {
      errorMsg(error);
      return;
    }
    setSelectedItems(event, componentName, name);
    if (component) {
      editItem(component, componentName, name, index);
    }
  };

  const handleEvent = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    addItem(event, 'navigation');
  };

  const getPathContainerMargin = () => {
    let value = 0;
    if (subMenuPath) {
      value = STANDARD_ICON_LEFT_MARIGIN;
    }
    if (controlDefinition.icon) {
      value += STANDARD_ICON_LEFT_MARIGIN;
    }

    return value;
  };

  const handleRemoveItem = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    componentName: string,
    index: number
  ) => {
    e.stopPropagation();
    const props = CONFIRMATION.navigations({ name: componentName });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      if (hasError) {
        setExistingLabelError && setExistingLabelError(false);
        setEmptyLabelError && setEmptyLabelError(false);
        setEmptyIconError && setEmptyIconError(false);
        setEmptyLinkError && setEmptyLinkError(false);
      }
      if (selectNextComponent)
        selectNextComponent(name, index, controlDefinition.subMenu, navDefinition);
      controlDefinition.subMenu.order.splice(index, 1);
      delete controlDefinition.subMenu.components[componentName];
    }
  };
  const getSubMenuMargin = (level: number) => {
    if (subMenuPath) {
      if (level > 0) {
        return level * STANDARD_ICON_LEFT_MARIGIN;
      }
      return STANDARD_ICON_LEFT_MARIGIN;
    }
    return 0;
  };
  const updatedPath = (subMenuPath ? subMenuPath : '') + controlDefinition.label + ' / ';
  return (
    <Panel>
      <Droppable droppableId={name} type={name}>
        {(provided) => (
          <NavigationInner {...provided.droppableProps} ref={provided.innerRef}>
            <Box className={classes.navigation}>
              <Box
                className={classes.column}
                sx={{ marginLeft: getSubMenuMargin(controlDefinition.level) }}
              >
                <Box className={classes.labelContainer}>
                  {subMenuPath && (
                    <i className={`fa-solid fa-arrow-turn-down-right`} style={styles.menuIcon} />
                  )}
                  {controlDefinition.icon && (
                    <i
                      className={`fa-solid ${controlDefinition.icon}`}
                      style={styles.subMenuIcon}
                    />
                  )}
                  <Typography className={classes.panelTitle}>{controlDefinition.label}</Typography>
                </Box>
                <Box
                  className={classes.pathContainer}
                  sx={{
                    marginLeft: getPathContainerMargin(),
                  }}
                >
                  {
                    <Typography className={clsx(classes.row, classes.menuPath)} style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <span style={{
                        flexGrow: 1,
                        overflow: 'hidden',           
                        textOverflow: 'ellipsis',    
                        whiteSpace: 'nowrap',         
                      }}>
                        {subMenuPath}
                      </span>
                      <Typography
                        className={classes.addSubMenu}
                        onClick={(e) => {
                          if (hasError) {
                            return;
                          }
                          handleEvent(e);
                        }}
                        style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}
                      >
                        Add Sub Menu
                      </Typography>
                    </Typography>
                  }
                </Box>
              </Box>
            </Box>
            <Box>
              {subMenu.order.map((componentName, index) => {
                const component = subMenu.components[componentName] as WebNavigation;
                const Control = component && controlMap[component.type];
                if (Control) {
                  return (
                    <Draggable
                      key={name + '.' + componentName}
                      draggableId={name + '.' + componentName}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          //@ts-ignore
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${classes.panelItem} ${
                            focusedItem === name + componentName ? classes.selected : ''
                          }`}
                          onClick={(event) => {
                            if (hasError) {
                              return;
                            }
                            handleItemClick(event, componentName, name, index, component);
                          }}
                        >
                          <Control
                            controlDefinition={component}
                            name={name + '.' + componentName}
                            editItem={editItem}
                            setSelectedItems={setSelectedItems}
                            focusedItem={focusedItem}
                            navDefinition={navDefinition}
                            setNavDefinition={setNavDefinition}
                            selectNextComponent={selectNextComponent}
                            selectedItems={selectedItems}
                            hasError={hasError}
                            error={error}
                            subMenuPath={updatedPath}
                            setExistingLabelError={setExistingLabelError}
                            setEmptyLabelError={setEmptyLabelError}
                            setEmptyIconError={setEmptyIconError}
                            setEmptyLinkError={setEmptyLinkError}
                          />
                          {focusedItem === name + componentName && (
                            <div className={classes.trashContainer}>
                              <IconButton
                                className={classes.trashButton}
                                component="span"
                                onClick={(e) => handleRemoveItem(e, componentName, index)}
                              >
                                <FontAwesomeIcon icon={faTrashCan} className={classes.trashIcon} />
                              </IconButton>
                            </div>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  );
                } else {
                  console.log(`Couldn't find component: ${component.type}`);
                }
                return null;
              })}
            </Box>
            {provided.placeholder}
          </NavigationInner>
        )}
      </Droppable>
    </Panel>
  );
};

const Panel = styled.div`
  overflow: hidden;
`;
const NavigationInner = styled.div``;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trashIcon: {
      fontSize: 16,
    },
    trashButton: {
      color: colors.black54,
      padding: 5,
      marginRight: 3,
    },
    trashContainer: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    panelTitle: {
      fontSize: 15,
      fontWeight: 500,
      color: colors.black,
    },
    panelItem: {
      position: 'relative',
      background: colors.white,
      color: colors.title,
      boxShadow: `0px 1px 4px 0px ${colors.black15}`,
      border: '1px solid transparent',
      overflow: 'hidden',
      width: '100%',
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginLeft: 54,
      paddingLeft: 100,
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      alignItems: 'center',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    addSubMenu: {
      fontSize: 13,
      color: theme.palette.primary.main,
      display: 'block',
      maxWidth: 100,
      cursor: 'pointer',
      fontWeight: 400,
    },
    pathContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 5,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '15px 18px',
    },
    menuPath: {
      fontSize: 13,
      color: colors.black70,
      fontWeight: 400,
    },
  })
);

const styles = {
  menuIcon: {
    width: 16,
    height: 13,
  },
  subMenuIcon: {
    fontSize: 16,
    maxWidth: 16,
    color: colors.black54,
  },
};

export default Navigation;
