import React, { useState } from 'react';
import { V2PieChartOptions } from '@terragotech/page-renderer';
import { TGIosSwitch, TGSelect } from '../../../views/components/formElements';
import { ChartEditSectionProps, ColorPaletteChartOption, LegendPositionChartOption } from './common';

const layersOptions: Record<NonNullable<V2PieChartOptions['layers']>[number], string> = {
  arcs: 'Arcs',
  arcLabels: 'Arc Labels',
  arcLinkLabels: 'Arc Link Labels',
  legends: 'Legends',
};
const layersOptionsOrderedKeys = Object.keys(layersOptions) as NonNullable<V2PieChartOptions['layers']>;

export const PieChartEditSection: React.FC<ChartEditSectionProps<'pie'>> = ({
  chartOptions,
  setChartOptions,
  optionStyles,
}) => {
  const [colorPalette, setColorPalette] = useState(chartOptions?.colorPalette);
  const [legendPosition, setLegendPosition] = useState(chartOptions?.legendPosition);
  const [layers, setLayers] = useState<V2PieChartOptions['layers']>(chartOptions?.layers ?? layersOptionsOrderedKeys);
  const [sortByValue, setSortByValue] = useState(chartOptions?.sortByValue);

  return (
    <>
      <ColorPaletteChartOption
        value={colorPalette}
        onChange={(value) => {
          setColorPalette(value);
          setChartOptions((o) => ({ ...o, colorPalette: value }));
        }}
        optionStyles={optionStyles}
      />
      <LegendPositionChartOption
        value={legendPosition}
        onChange={(value) => {
          setLegendPosition(value);
          setChartOptions((o) => ({ ...o, legendPosition: value }));
        }}
        optionStyles={optionStyles}
      />
      <TGSelect
        id="layers"
        label="Layers"
        type="layer(s)"
        multiple={true}
        value={layers ?? []}
        options={layersOptions}
        onChangeMultiple={(valueArray) => {
          const value = valueArray.length === layersOptionsOrderedKeys.length ? undefined : layersOptionsOrderedKeys.filter(k => valueArray.some(v => v === k));
          setLayers(value ?? layersOptionsOrderedKeys as V2PieChartOptions['layers']);
          setChartOptions((o) => ({ ...o, layers: value as V2PieChartOptions['layers'] }));
        }}
        {...optionStyles}
      />
      <TGIosSwitch
        title="Sort By Value"
        description="Order arcs according to their associated value instead of order within chart data."
        checked={sortByValue ?? false}
        onChange={(value) => {
          setSortByValue(value as V2PieChartOptions['sortByValue']);
          setChartOptions((o) => ({
            ...o,
            sortByValue: value as V2PieChartOptions['sortByValue'],
          }));
        }}
        {...optionStyles}
      />
    </>
  );
};

export default PieChartEditSection;
