import { camelCase, pascalCase } from 'change-case';
import {
  AggregateConfig,
  PropertyCollection,
} from '@terragotech/gen5-config-lib/dist/AggregateConfig';
import { camelCaseToSpaceSeparated } from '../components/FormElements';
import { Gen5ColorPicker } from '../pages/MobileUI/components/Gen5ColorPicker';
import {
  EventDefinition,
  CommandDefinition,
  CommandVersionDefinition,
  Gen5Config,
  CommandReference,
  ActionButton,
  BuiltinMobileActions,
  BuiltinWebActions,
  EventVersionDefinition,
  CommandToEventMapping,
  ChoiceCommandDefinition,
  TimerCommandDefinition,
  ButtonCommandDefinition,
  InternalCommandDefinition,
  ImportCommandDefinition,
  FormCommandDefinition,
  ScheduleActionRow,
} from '@terragotech/gen5-config-lib';
import { NodeMapDefinition, mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { JSONSchema6 } from 'json-schema';
import { fontawesomeIconList } from './fontawesomeIconList';
import { FabActionRow } from '../pages/MobileUI/subpages/FabActions';
import { FabActionColumnInput } from '../pages/aggregates/components/FabActionColumnInput';
import { TableEmbeddedMapper } from '../components/TableEmbeddedMapper';
import { Gen5ColorDefinition } from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';
import {
  WebAggrUICustomization,
  MobileAggrUICustomization,
  UIConfigType,
  CommandVersionDefault,
  EventVersionDefault,
} from './types';
import { getSvgImageString } from './SymbolCreator';
import { useSymbolScale } from '../context/symbolScaleContext/symbolScaleContext';
import { FunctionDefinition } from '@terragotech/gen5-datamapping-lib';
import _ from 'lodash';
import { V2PageTemplate } from '@terragotech/page-renderer';
import React from 'react';
import { colors } from './colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/pro-solid-svg-icons';
import { IconPicker } from './iconPicker';
import {
  TimerActionSelect,
  TimerTimeSelect,
  TimerRepeatSelect,
  TimerDaySelect,
} from './TimerCommandUtils';

type FabActions = Array<{
  aggregateName?: string;
  button: ActionButton<BuiltinMobileActions | BuiltinWebActions>;
}>;

export interface FontIconOption {
  value?: string;
  label?: React.ReactNode;
}

export const getFAIconName = (value: string) => {
  if (value && value.startsWith('fas ')) {
    return value.slice(4);
  } else if (value && value.startsWith('fa-solid ')) {
    return value.slice(9);
  } else if (!value) {
    return undefined;
  }
  return value;
};

export const metadataSchema: JSONSchema6 = {
  type: 'object',
  properties: {
    timeFormOpened: {
      type: 'string',
      format: 'date-time',
    },
    latitude: { type: 'number' },
    longitude: { type: 'number' },
    selectedLatitude: { type: 'number' },
    selectedLongitude: { type: 'number' },
    locationAccuracy: { type: 'number' },
    aggregateType: {
      type: 'string',
    },
    userInfo: {
      type: 'object',
      properties: {
        userName: {
          type: 'string',
        },
        email: {
          type: 'string',
        },
        authExpiration: {
          type: 'string',
          format: 'date-time',
        },
        familyName: {
          type: 'string',
        },
        givenName: {
          type: 'string',
        },
        authPayload: {
          type: 'object',
          properties: {},
        },
        roles: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: {
                type: 'string',
              },
              name: {
                type: 'string',
              },
            },
          },
        },
        roleString: {
          type: 'string',
        },
        permissions: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        permissionString: {
          type: 'string',
        },
      },
    },
    isOnline: {
      type: 'boolean',
    },
    selectedProjects: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};
export const updateAssetLocationSchema: JSONSchema6 = {
  type: 'object',
  properties: {
    lat: {
      type: 'number',
    },
    lon: {
      type: 'number',
    },
    horizontalAccuracy: {
      type: 'string',
    },
    altitude: {
      type: 'string',
    },
    altitudeAccuracy: {
      type: 'string',
    },
    satellitesCount: {
      type: 'number',
    },
    bearing: {
      type: 'string',
    },
    gpsTime: {
      type: 'string',
    },
    speed: {
      type: 'string',
    },
    corrected: {
      type: 'string',
    },
    sourceType: {
      type: 'string',
    },
    bearingTrueNorth: {
      type: 'string',
    },
    bearingAccuracy: {
      type: 'string',
    },
    lockType: {
      type: 'string',
    },
    locationProvider: {
      type: 'string',
    },
    pdop: {
      type: 'string',
    },
    vdop: {
      type: 'string',
    },
    hdop: {
      type: 'string',
    },
    location: {
      anyOf: [
        {
          type: 'string',
        },
        {
          $ref: '/Geography/GeoJSON',
        },
      ],
    },
  },
};
export const generateAggregateByName = (aggrName: string): AggregateConfig => {
  const defaultAggregateObject: AggregateConfig = {
    typeName: pascalCase(aggrName),
    pluralName: `${pascalCase(aggrName)}s`,
    singularName: pascalCase(aggrName),
    objectStore: {
      rebuildOnStartup: true,
      table: `${camelCase(aggrName)}s` || 'assets',
      type: 'Postgres' as const,
      reducerName: 'EVENT_MAP',
      schema: 'public',
    },
    labelProperty: 'title',
    derivedPropertyMapping: {
      nodes: {
        OUTPUT: {
          type: 'OBJECT-BUILDER',
          name: 'Output State',
          inputs: {},
          config: { objectSchema: 'STATE' },
        },
      },
      outputDefinition: { outputNode: 'OUTPUT' },
    },
    properties: {
      symbolKey: {
        label: 'Symbol Key',
        type: 'String',
        uiType: 'Symbol Key',
        filterOptions: 'DISTINCT',
        filterable: true,
        hideFromAssetAttributes: true,
        nullable: true,
      },
      title: {
        label: 'Title',
        type: 'String',
        uiType: 'Title',
        filterOptions: 'DISTINCT',
        filterable: true,
        nullable: true,
      },
      id: {
        label: 'Id',
        type: 'ID',
        uiType: 'Hidden',
        filterOptions: 'DISTINCT',
        filterable: true,
        hideFromAssetAttributes: true,
        nullable: true,
      },
      project: {
        label: 'Project',
        type: 'Project',
        filterable: true,
        nullable: true,
        relation: 'ONE-TO-ONE',
      },
      createdBy: {
        label: 'Created By',
        type: 'String',
        filterOptions: 'DISTINCT',
        filterable: true,
        nullable: true,
      },
      createdDateTime: {
        label: 'Created Date/Time',
        type: 'DateTime',
        filterOptions: 'DISTINCT',
        filterable: true,
        nullable: true,
      },
      modifiedDateTime: {
        label: 'Modified Date/Time',
        type: 'DateTime',
        uiType: 'Hidden',
        filterOptions: 'DISTINCT',
        filterable: true,
        nullable: true,
      },
      lat: {
        label: 'Latitude',
        type: 'Float',
        filterOptions: 'DISTINCT',
        filterable: true,
        nullable: true,
      },
      lon: {
        label: 'Longitude',
        type: 'Float',
        filterOptions: 'DISTINCT',
        filterable: true,
        nullable: true,
      },
      assetAttributes: {
        label: 'Asset Attributes',
        type: 'JSON',
        uiType: 'Hidden',
        filterOptions: 'DISTINCT',
        filterable: false,
        nullable: true,
      },
    },
    events: {
      locationChangedEvent: generateNewEvent('locationChangedEvent', updateAssetLocationSchema, {
        nodes: {
          OUTPUT: {
            type: 'OBJECT-BUILDER',
            config: { objectSchema: 'STATE' },
            inputs: {
              lat: {
                sourceObject: 'EVENT',
                sourcePath: '$.data.lat',
              },
              lon: {
                sourceObject: 'EVENT',
                sourcePath: '$.data.lon',
              },
            },
          },
        },
        outputDefinition: {
          outputNode: 'OUTPUT',
        },
      }),
    },
    commands: {
      updateAssetLocation: generateNewCommand(
        'updateAssetLocation',
        'INTERNAL',
        'locationChangedEvent',
        'Update Location'
      ),
    },
  };
  return defaultAggregateObject;
};
export const generateFunction = (): FunctionDefinition => {
  return {
    currentVersion: 1,
    versions: [
      {
        versionNumber: 1,
        output: {
          type: 'object',
          properties: {
            result: {
              type: 'number',
            },
          },
        },
        input: {
          type: 'object',
          properties: {
            in: {
              type: 'number',
            },
          },
        },
        aggregateMap: {
          nodes: {
            OUTPUT: {
              type: 'OBJECT-BUILDER',
              config: { objectSchema: 'STATE' },
            },
            DATA: {
              type: 'DATA',
              config: {},
            },
          },
          outputDefinition: {
            outputNode: 'OUTPUT',
          },
        },
      },
    ],
  };
};
export const generateEventSchema = (eventName: string, dataSchema?: JSONSchema6): JSONSchema6 => ({
  type: 'object',
  properties: {
    type: {
      type: 'string',
      enum: [eventName],
    },
    version: {
      type: 'number',
    },
    timestamp: {
      description: 'Timestamp',
      type: 'string',
      format: 'date-time',
    },
    aggregateId: {
      type: 'string',
    },
    metadata: metadataSchema,
    data: dataSchema || {
      type: 'object',
      properties: {},
    },
  },
});

export const generateNewEvent = (
  eventName: string,
  dataSchema?: JSONSchema6,
  aggregateMap?: NodeMapDefinition
): EventDefinition => ({
  versions: [generateNewEventVersion(eventName, { dataSchema, aggregateMap })],
});

export const generateNewEventAndVersions = (
  eventName: string,
  versions: EventVersionDefault[]
): EventDefinition => ({
  versions: versions.map((v) => generateNewEventVersion(eventName, v)),
});

export const generateNewEventVersion = (
  eventName: string,
  versionDefault: EventVersionDefault
): EventVersionDefinition => ({
  versionNumber: versionDefault.versionNumber ?? 1,
  eventSchema: generateEventSchema(eventName, versionDefault.dataSchema),
  aggregateMap: versionDefault.aggregateMap ?? mapScenarios.EVENT_AGGREGATE_MAP.defaultNodeMap,
});

export const generateNewCommand = (
  name: string,
  commandType: 'FORM' | 'BUTTON' | 'IMPORT' | 'TIMER' | 'INTERNAL',
  eventName?: string,
  defaultLabel?: string
): CommandDefinition => ({
  defaultLabel: defaultLabel || camelCaseToSpaceSeparated(name),
  versions: [generateNewCommandVersion({ commandType }, eventName)],
});

export const generateNewCommandAndVersions = (
  name: string,
  versionsDefaults: CommandVersionDefault[],
  defaultLabel?: string
): CommandDefinition => ({
  defaultLabel: defaultLabel || camelCaseToSpaceSeparated(name),
  versions: versionsDefaults.map((v) => generateNewCommandVersion(v)),
});

export const generateNewCommandVersion = (
  versionDefault: CommandVersionDefault,
  eventName?: string
): CommandVersionDefinition => {
  eventName =
    eventName ??
    (versionDefault.cmdToEventsMapping?.mappingType === 'ONE-TO-MANY'
      ? undefined
      : versionDefault.cmdToEventsMapping?.eventName);
  const defaultAttribs: {
    isCreationCommand: boolean;
    version: number;
    cmdToEventsMapping: CommandToEventMapping;
  } = {
    version: versionDefault.version ?? 1,
    isCreationCommand: versionDefault.isCreationCommand ?? false,
    cmdToEventsMapping: versionDefault.cmdToEventsMapping ?? {
      eventName: eventName || '',
      mappingType: 'COPY',
    },
  };

  let cmdVers: CommandVersionDefinition;
  switch (versionDefault.commandType) {
    case 'IMPORT':
      cmdVers = {
        ...defaultAttribs,
        commandType: versionDefault.commandType,
        formDefinition: versionDefault.formDefinition ?? { components: {}, order: [] },
      } as ImportCommandDefinition;
      break;
    case 'FORM':
      cmdVers = {
        ...defaultAttribs,
        commandType: versionDefault.commandType,
        formDefinition: versionDefault.formDefinition ?? { components: {}, order: [] },
      } as FormCommandDefinition;
      break;
    case 'CHOICE':
      cmdVers = {
        ...defaultAttribs,
        commandType: versionDefault.commandType,
      } as ChoiceCommandDefinition;
      break;
    case 'TIMER':
      cmdVers = {
        ...defaultAttribs,
        commandType: versionDefault.commandType,
      } as TimerCommandDefinition;
      break;
    case 'BUTTON':
      cmdVers = {
        ...defaultAttribs,
        commandType: versionDefault.commandType,
      } as ButtonCommandDefinition;
      break;
    case 'INTERNAL':
    default:
      cmdVers = {
        ...defaultAttribs,
        commandType: 'INTERNAL',
      } as InternalCommandDefinition;
      break;
  }

  cmdVers = {
    ...cmdVers,
    // Further attributes added conditionally at the end for backwards compatibility.
    ...(versionDefault.hasNoAggregateIdCommand !== undefined && {
      hasNoAggregateIdCommand: versionDefault.hasNoAggregateIdCommand,
    }),
    ...(versionDefault.fabOptionEnabled !== undefined && {
      fabOptionEnabled: versionDefault.fabOptionEnabled,
    }),
  };

  return cmdVers;
};

export const defaultAggregateUICustomization = (
  uiConfigType: UIConfigType,
  labelProperty: string
) =>
  ({
    cardDefinition: {
      template: 'conditionalCard',
      titleKey: labelProperty,
      showTitleLabel: true,
      ...(uiConfigType === 'mobileUIConfig' && { cardHeight: 370 }),
      ...(uiConfigType === 'mobileUIConfig' && {
        secondaryButtonOptions: {
          groupButtons: false,
          groupButtonLabel: 'Actions',
        },
      }),
      otherAttributes: [],
      buttons: [],
    },
    editorWorkflows: [],

    ...(uiConfigType === 'mobileUIConfig' && { editorActions: [] }),
    ...(uiConfigType === 'webUIConfig' && { tableActions: [] }),
    editorActions: [],
    multiSelectActions: [],
  } as MobileAggrUICustomization | WebAggrUICustomization);

export const getCommandReferenceArr = (
  config: Gen5Config,
  creationCommandOnly?: boolean,
  indexOfAggr?: number,
  importCommandOnly?: boolean
) => {
  const commandRefArr = [] as CommandReference[];
  let aggregatesArr = config.aggregates;
  if (indexOfAggr === 0 || indexOfAggr) {
    aggregatesArr = [config.aggregates[indexOfAggr]];
  }
  aggregatesArr.forEach((aggr) => {
    if (aggr?.commands)
      Object.entries(aggr.commands).forEach(([commandName, command]) => {
        command.versions.forEach(
          ({
            version,
            isCreationCommand,
            hasNoAggregateIdCommand,
            fabOptionEnabled,
            isImportCommand,
          }) => {
            if (
              (importCommandOnly && isImportCommand) ||
              (!creationCommandOnly && !importCommandOnly && !isImportCommand) ||
              (creationCommandOnly &&
                !isImportCommand &&
                (isCreationCommand || hasNoAggregateIdCommand || fabOptionEnabled))
            )
              commandRefArr.push({ commandName, commandVersion: version });
          }
        );
      });
  });
  return commandRefArr;
};

export const convertCommandRefToString = (
  commandRef: CommandReference | BuiltinMobileActions | BuiltinWebActions
) => {
  if (typeof commandRef === 'string') return commandRef;
  if (typeof commandRef === 'object')
    return `${commandRef.commandName} ver:${commandRef.commandVersion}`;
  return '';
};

export const convertStringToCommandRef = (commandRefString: string) => {
  if (commandRefString.includes(' ver:')) {
    const [commandName, commandVersion] = commandRefString.split(' ver:');
    return { commandName, commandVersion: +commandVersion };
  }
  return commandRefString;
};

export const getCommandReferenceList = (
  config: Gen5Config,
  additionalActionOptions: string[],
  empty?: boolean,
  indexOfAggr?: number
) => {
  const commandRefArr = getCommandReferenceArr(config, false, indexOfAggr);
  const commandRefArrStrings = commandRefArr.map((item) => convertCommandRefToString(item));
  return [empty && '', ...commandRefArrStrings, ...additionalActionOptions];
};

type AggregatePropList = { name: string | undefined; type: string; label?: string };

export const getAggregatePropertiesList = (config: Gen5Config, aggregateName: string) => {
  const aggregate: AggregateConfig | undefined = config?.aggregates.find(
    (a) => a.typeName === aggregateName
  );
  const aggrPropList: AggregatePropList[] = [{ name: undefined, type: '' }];
  if (aggregate?.properties)
    Object.keys(aggregate.properties)
      .sort()
      .forEach((prop) => {
        aggrPropList.push({
          name: prop,
          label: aggregate.properties[prop].label,
          type: aggregate.properties[prop].type,
        });
      });

  return aggrPropList;
};

export const aggregatePropsToList = (aggregateProps: PropertyCollection) =>
  Object.keys(aggregateProps).map((key) => key);

export const aggregatePropsToNames = (aggregateProps: object | AggregatePropList[]) => {
  let aggreProp = aggregateProps;
  if (aggregateProps instanceof Array) {
    const allnames = _.map(aggregateProps, 'name');
    aggreProp = allnames.reduce((a, v) => ({ ...a, [v ? v : '']: v }), {});
  }
  return aggreProp;
};

export const actionButtonsToRows = (
  editorWorkflowsArr: Array<{ action: CommandReference | BuiltinMobileActions | BuiltinWebActions }>
) =>
  editorWorkflowsArr.map((commandAction) => ({
    ...commandAction,
    action: convertCommandRefToString(commandAction.action),
  }));

export const commandActionButtonActionToRef = <T extends { action: string }>(
  commandActionRow: T
) => ({
  ...commandActionRow,
  action: convertStringToCommandRef(commandActionRow.action) as CommandReference,
});

export const commandRefObjToList = (
  commandRefArr: CommandReference[],
  additionalActionOptions: string[] = []
) => {
  const commandRefList = {} as { [commandName: string]: string };
  commandRefArr.forEach((commandRef) => {
    const name = convertCommandRefToString(commandRef);
    commandRefList[name] = name;
  });
  additionalActionOptions.forEach((basicAction) => {
    commandRefList[basicAction] = basicAction;
  });
  return commandRefList;
};

export const getAggregateList = (config: Gen5Config) => {
  const aggrList = {} as { [aggrName: string]: string };
  Object.entries(config.aggregates).forEach(([_, aggr]) => {
    aggrList[aggr.typeName] = aggr.typeName;
  });
  return aggrList;
};

export const getAllfontIcons = () => {
  let options = [];
  for (const iconString of fontawesomeIconList) {
    options.push({
      value: iconString,
      label: <i className={`${iconString}`} style={{ ...styles.icon }} />,
    });
  }
  return options;
};
export interface FontIconOption {
  value?: string;
  label?: React.ReactNode;
}
export const styles = {
  selectField: {
    height: 38,
    marginTop: 10,
    width: '100%',
  },
  icon: {
    fontSize: 22,
    color: colors.black54,
  },
  iconStyle: {
    width: 30,
    height: 30,
    color: colors.black,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 7,
  },
  iconButton: {
    paddingLeft: 0,
  },
  dropDown: {
    height: '50%',
    marginTop: 5,
  },
  checkIcon: {
    height: 20,
    width: 20,
    display: 'flex',
    margin: 'auto',
  },
};
export const getActionButtonsColumns = (
  actions: object,
  properties: PropertyCollection,
  aggregateProps?: object
): object[] => [
  {
    title: 'Label',
    field: 'label',
  },
  {
    title: 'Icon',
    field: 'icon',
    align: 'center',
    editComponent: ({
      value,
      onChange,
    }: {
      value?: string;
      onChange: (value: string | undefined) => void;
    }) => IconPicker(value, onChange),
    render: ({ icon }: { icon: string }) => (
      <i className={`fa-solid ${icon}`} style={{ ...styles.icon }} />
    ),
  },
  {
    title: 'Conditional Map',
    field: 'conditionalMap',
    editComponent: ({
      value,
      onChange,
    }: {
      value: NodeMapDefinition | undefined;
      onChange: (value: NodeMapDefinition | undefined) => void;
    }) => <TableEmbeddedMapper value={value} onChange={onChange} properties={properties} />,
    render: ({ conditionalMap }: { conditionalMap: NodeMapDefinition }) =>
      conditionalMap ? 'Mapped' : '',
  },
  ...(aggregateProps
    ? [
        {
          title: 'Color',
          field: 'color',
          editComponent: ({
            value,
            onChange,
          }: {
            value: string;
            onChange: (value: Gen5ColorDefinition | undefined) => void;
          }) => (
            <Gen5ColorPicker
              color={value}
              setColor={onChange}
              aggregates={aggregatePropsToNames(aggregateProps)}
            />
          ),
          render: (props: { color: string }) => <Gen5ColorPicker color={props.color} />,
        },
      ]
    : []),
  {
    title: 'Action',
    field: 'action',
    option: {
      ...actions,
    },
  },
];
const iconRender = (value: boolean) => {
  return value === true ? (
    <FontAwesomeIcon icon={faCheck} color={colors.ChateauGreen} style={styles.checkIcon} />
  ) : (
    <FontAwesomeIcon icon={faClose} color={colors.red} style={styles.checkIcon} />
  );
};
export const getCardActionButtonsColumns = (
  actions: object,
  properties: PropertyCollection,
  aggregateProps: object
) => [
  { title: 'Label', field: 'label' },
  {
    title: 'Icon',
    field: 'icon',
    align: 'center',
    editComponent: ({
      value,
      onChange,
    }: {
      value: string;
      onChange: (value: string | undefined) => void;
    }) => IconPicker(value, onChange),
    render: ({ icon }: { icon: string }) => (
      <i className={`fa-solid ${icon}`} style={{ ...styles.icon }} />
    ),
  },
  {
    title: 'Primary',
    field: 'canBePrimary',
    type: 'boolean',
    label: 'Can be primary',
    render: (rowdata: { canBePrimary: boolean }) => iconRender(rowdata.canBePrimary as boolean),
    gridView: true,
    gOrder: 1,
  },
  {
    title: 'Conditional Map',
    field: 'conditionalMap',
    editComponent: ({
      value,
      onChange,
    }: {
      value: NodeMapDefinition | undefined;
      onChange: (value: NodeMapDefinition | undefined) => void;
    }) => <TableEmbeddedMapper value={value} onChange={onChange} properties={properties} />,
    render: ({ conditionalMap }: { conditionalMap: NodeMapDefinition }) =>
      conditionalMap ? 'Mapped' : '',
  },
  {
    title: 'Color',
    field: 'color',
    editComponent: ({
      value,
      onChange,
    }: {
      value: string;
      onChange: (value: Gen5ColorDefinition | undefined) => void;
    }) => (
      <Gen5ColorPicker
        color={value}
        setColor={onChange}
        aggregates={aggregatePropsToNames(aggregateProps)}
      />
    ),
    render: (props: { color: string }) => <Gen5ColorPicker color={props.color} showColor />,
  },
  {
    title: 'Action',
    field: 'action',
    lookup: {
      ...actions,
    },
  },
];

export const getFabActionColumns = (aggregates: object, forImport?: boolean) => [
  {
    title: 'Records Name',
    field: 'aggregateName',
    lookup: {
      ...aggregates,
    },
  },
  { title: 'Label', field: 'label' },
  {
    title: 'Icon',
    field: 'icon',
    align: 'center',
    editComponent: ({
      value,
      onChange,
    }: {
      value: string;
      onChange: (value: string | undefined) => void;
    }) => IconPicker(value, onChange),
    render: ({ icon }: { icon: string }) => (
      <i className={`fa-solid ${icon}`} style={{ ...styles.icon }} />
    ),
  },
  {
    title: 'Conditional Map',
    field: 'conditionalMap',
    editComponent: ({
      value,
      onChange,
    }: {
      value: NodeMapDefinition | undefined;
      onChange: (value: NodeMapDefinition | undefined) => void;
    }) => <TableEmbeddedMapper value={value} onChange={onChange} />,
    render: ({ conditionalMap }: { conditionalMap: NodeMapDefinition }) =>
      conditionalMap ? 'Mapped' : '',
  },
  {
    title: 'Action',
    field: 'action',
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: FabActionRow;
      onChange: (value: string | undefined) => void;
    }) => <FabActionColumnInput row={rowData} onChange={onChange} forImport={forImport} />,
  },
];

export const getScheduleActionColumns = (aggregates: object) => [
  {
    title: 'Record Name',
    field: 'recordName',
    lookup: {
      ...aggregates,
    },
  },
  {
    title: 'Timer',
    field: 'timer',
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: ScheduleActionRow;
      onChange: (value: string | undefined) => void;
    }) => <TimerActionSelect row={rowData} onChange={onChange} />,
  },
  {
    title: 'Repeat',
    field: 'repeat',
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: ScheduleActionRow;
      onChange: (value: string | undefined) => void;
    }) => <TimerRepeatSelect row={rowData} onChange={onChange} />,
  },
  {
    title: 'Start Time (HH:MM)',
    field: 'startTime',
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: ScheduleActionRow;
      onChange: (value: string | undefined) => void;
    }) => <TimerTimeSelect row={rowData} onChange={onChange} startOrEnd={true} />,
  },
  {
    title: 'End Time (HH:MM)',
    field: 'endTime',
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: ScheduleActionRow;
      onChange: (value: string | undefined) => void;
    }) =>
      rowData?.repeat &&
      rowData?.repeat !== 'Daily' && (
        <TimerTimeSelect row={rowData} onChange={onChange} startOrEnd={false} />
      ),
  },
  {
    title: 'Days',
    field: 'days',
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: ScheduleActionRow;
      onChange: (value: string | undefined) => void;
    }) => <TimerDaySelect row={rowData} onChange={onChange} />,
  },
];

export const extractActionButtonFromFabAction = (fabAction: FabActions) =>
  fabAction.map((fabAction) => fabAction.button);

export const getCardDefinitionOtherAttributesColumns = (
  aggregateProps: object | AggregatePropList[],
  properties: PropertyCollection
) => {
  const aggreProp = aggregatePropsToNames(aggregateProps);
  const aggrePropWithoutEmptyValue = _.pickBy(aggreProp, _.identity);
  return [
    { title: 'Item Key', field: 'itemKey', lookup: { ...aggrePropWithoutEmptyValue } },
    { title: 'Item Label', field: 'itemLabel' },
    { title: 'Place Holder', field: 'placeholder' },
    {
      title: 'Conditional Map',
      field: 'conditionalMap',
      editComponent: ({
        value,
        onChange,
      }: {
        value: NodeMapDefinition | undefined;
        onChange: (value: NodeMapDefinition | undefined) => void;
      }) => (
        <TableEmbeddedMapper
          form={{
            //This form value defines the output node - if we want to expand the scope of this mapping to additional properties, this is where you would add them
            type: 'object',
            properties: {
              isVisible: {
                type: 'boolean',
                title: 'isVisible',
              },
            },
          }}
          properties={properties}
          value={value}
          onChange={onChange}
          mapScenario="CARD_ELEMENT_MAP"
        />
      ),
      render: ({ conditionalMap }: { conditionalMap: NodeMapDefinition }) =>
        conditionalMap ? 'Mapped' : '',
    },
    {
      title: 'Label Property Color',
      field: 'color',
      editComponent: ({
        value,
        onChange,
      }: {
        value: string;
        onChange: (value: Gen5ColorDefinition | undefined) => void;
      }) => (
        <Gen5ColorPicker
          color={value}
          setColor={onChange}
          aggregates={aggreProp}
          showColor={false}
        />
      ),
      render: (props: { color: string }) => <Gen5ColorPicker color={props.color} showColor />,
    },
    { title: 'Type', field: 'type', editable: 'never' },
  ];
};

export const getSymbolKeyColumns = () => [
  {
    title: 'Preview',
    field: 'preview',
    render: (props: { name: string; preview: number; symbolKey: string }) => {
      return <SymbolPreview {...props} />;
    },
    editComponent: (props: {
      rowData: {
        name: string;
        preview: number;
        symbolKey: string;
      };
    }) => <SymbolPreview {...props.rowData} />,
  },
  { title: 'Name', field: 'name' },
  {
    title: 'Symbol Key',
    field: 'symbolKey',
  },
];
const DEFAULT_ICON_SIZE = 41;
const SymbolPreview = (props: { name: string; preview: number; symbolKey: string }) => {
  const { scale } = useSymbolScale();
  let symbolParts: string[] = [],
    isSymbolValid = false,
    isSymbol = false,
    isLine = false;
  if (props.symbolKey) {
    symbolParts = props.symbolKey.split('_');
    isSymbolValid = symbolParts.length >= 3;
    isSymbol = props.symbolKey.startsWith('symbol');
    isLine = props.symbolKey.startsWith('line');
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${require('../resources/images/backgroundImg.png')})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: 100,
        width: 100,
        marginTop: 4,
      }}
    >
      {isSymbolValid && (
        <>
          {isSymbol && (
            <img
              src={getSvgImageString(props.symbolKey)}
              alt="symbol key"
              style={{
                filter: 'drop-shadow(1px 1px 2px #ccc )',
                cursor: 'pointer',
                marginBottom: 6,
                height: (DEFAULT_ICON_SIZE * scale) / 100,
                width: (DEFAULT_ICON_SIZE * scale) / 100,
              }}
            />
          )}
          {isLine && (
            <div
              style={{
                width: props.preview / 2,
                height: 0,
                borderTopStyle: symbolParts[1] as 'solid' | 'dashed',
                borderTopWidth: `${symbolParts[2]}px`,
                borderTopColor: `#${symbolParts[3]}`,
                marginBottom: 4,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export const generateCustomPage = (init?: Partial<V2PageTemplate>): V2PageTemplate => ({
  rows: 1,
  columns: 1,
  allowDynamicSizing: true,
  elements: {},
  ...init,
});
