import React, { useRef } from 'react';
import { colors } from '../../utils/colors';
import { Box, Typography, makeStyles, styled } from '@material-ui/core';
import { TGColorPicker } from 'views/components/formElements';

interface ColorPickerProps {
  title?: string;
  value: string;
  onChange?: (value: string) => void;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  summaryBox?: string;
  onlyColorEnabled?: boolean;
}

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
const Title = styled(Typography)({
  fontSize: 14,
  color: colors.black54,
  display: 'inline',
  marginRight: 10,
});
const Summary = styled(Typography)({
  color: colors.black54,
  fontSize: 12,
  margin: '5px 0 0',
});

export const ColorPicker: React.FC<ColorPickerProps> = ({
  title,
  value,
  onChange,
  labelStyle,
  containerStyle,
  disabled,
  summaryBox,
  onlyColorEnabled,
}) => {
  const classes = useStyles();
  const colorPickerRef = useRef<HTMLInputElement>(null);

  const onPaletteClick = () => {
    if (colorPickerRef?.current !== null) {
      colorPickerRef?.current.click();
    }
  };
  return (
    <React.Fragment>
      <Container style={containerStyle}>
        {title && <Title style={labelStyle}>{title}:</Title>}
        {onlyColorEnabled ? (
          <Box className={classes.inputContainer}>
            <Box className={classes?.button} onClick={onPaletteClick}>
              <Box className={classes?.colorPalette} style={{ backgroundColor: value }} />
            </Box>
            <input
              ref={colorPickerRef}
              className={classes?.inputPicker}
              type="color"
              value={value}
              onChange={(e) => onChange && onChange(e.target.value)}
              disabled={disabled}
            />
          </Box>
        ) : (
          <TGColorPicker
            {...{
              classes: {
                container: classes.container,
                picker: classes.picker,
                palette: classes.palette,
                input: classes.input,
              },
              value,
              onChange,
              disabled,
            }}
          />
        )}
      </Container>
      {summaryBox && <Summary>{summaryBox}</Summary>}
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  picker: {
    width: 41,
    borderRight: 'none',
    backgroundColor: colors.white,
    paddingLeft: 5,
  },
  input: {
    '& .MuiInputBase-input': {
      paddingLeft: 45,
    },
  },
  palette: {
    width: '70%',
    height: '60%',
  },
  inputContainer: {
    position: 'relative',
  },
  button: {
    width: 56,
    height: 36,
    border: `1px solid ${colors.black25}`,
    backgroundColor: colors.lotion,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    top: -18,
    left: 0,
    cursor: 'pointer',
    borderRadius: 5,
  },
  inputPicker: {
    position: 'absolute',
    top: 15,
    left: -100,
    width: 0,
    height: 0,
    zIndex: 1,
  },
  colorPalette: {
    backgroundColor: colors.black,
    width: '75%',
    height: '70%',
    borderRadius: 5,
  },
});
