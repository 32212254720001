import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { ColorPicker } from './ColorPicker';
import { TextInputTG } from 'views/components/formElements';
import { faBars, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { ColorPalette } from '@terragotech/gen5-config-lib';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import { isFormFieldInvalid } from './commonEditor';

const ModalComponent = styled.div``;

interface Props {
  openAdd: boolean;
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  colorMappings: string[];
  addPaletteColor: () => void;
  deletePaletteColor: (index: number) => void;
  onChangeColor: (value: string, index: number) => void;
  setPaletteData: React.Dispatch<React.SetStateAction<ColorPalette[]>>;
  onChange?: (value: ColorPalette[], action?: string) => void;
  setColorMappings: React.Dispatch<React.SetStateAction<string[]>>;
  title?: string;
  handleUpdate?: (value: ColorPalette) => void;
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  editComponent: ColorPalette | null
}
const AddModal = (props: Props) => {
  const {
    openAdd,
    setOpenAdd,
    colorMappings,
    addPaletteColor,
    deletePaletteColor,
    onChangeColor,
    setPaletteData,
    onChange,
    setColorMappings,
    title,
    handleUpdate,
    editMode,
    setEditMode,
    editComponent,
  } = props;
  const classes = useStyles();
  function closeModal(): void {
    setOpenAdd(false);
    if(editMode){
      setEditMode?.(false);
    }
  }
  const [key, setKey] = React.useState(editComponent?.label ?? '');
  const handleSave = useCallback(() => {
    const newPalette = {
      name: v4(),
      label: key,
      colors: colorMappings,
    };
    if(!isFormFieldInvalid(newPalette)) return;
    setPaletteData((prevData: ColorPalette[]) => ({...prevData, newPalette}));
    onChange?.([newPalette], 'add');
    closeModal();
  },[colorMappings, key, onChange, setPaletteData]);

  const titleText = colorMappings.length > 1 ? 'Color(s)' : 'Color';
  const onDragEnd = (result: { destination: any; source: any }) => {
    const { destination, source } = result;
    if (!destination) return;

    const reorderedColors = Array.from(colorMappings);
    const [removed] = reorderedColors.splice(source.index, 1);
    reorderedColors.splice(destination.index, 0, removed);

    setColorMappings(reorderedColors);
  };
  return (
    <ModalComponent className={classes.root}>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openAdd}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
      >
        <>
          <Box className={classes.paper}>
            <Box className={classes.header}>
              <Typography className={classes.headerText}>{title}</Typography>
              <IconButton className={classes.iconCloseContainer} onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} color="grey" className={classes.closeIcon} />
              </IconButton>
            </Box>
            <Divider />
            <Box style={{ padding: '20px', flex: 1, overflowY: 'auto' }}>
              <TextInputTG
                label={'Name'}
                value={key}
                id={key}
                onChange={(value) => setKey(value)}
                type={'textInput'}
                autoFocus
                labelRoot={classes.label}
                inputStyle={classes.input}
              />
              <div className={classes.schemesContainer}>Color Schemes</div>
              <div style={styles.container}>
                <div style={styles.innerContainer}>
                  <div style={styles.txt}>{titleText}</div>
                  <div>
                    <IconButton component="span" onClick={addPaletteColor}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
                {colorMappings.length === 0 && (
                  <div className={`${classes.render} ${classes.noColor}`}>
                    <div className={classes.noColorTxt}>No colors to display</div>
                  </div>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="colors" direction="vertical">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {colorMappings.map((item, index) => (
                          <Draggable key={index} draggableId={`color-${index}`} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                key={`${index}`}
                              >
                                <div
                                  className={`${classes.render} ${classes.colorsContainer}`}
                                >
                                  <div {...provided.dragHandleProps}>
                                    <FontAwesomeIcon
                                      icon={faBars}
                                      color={colors.black54}
                                      className={classes.icon}
                                      style={{ marginRight: 10 }}
                                    />
                                  </div>
                                  <ColorPicker
                                    value={item}
                                    onChange={(value) => onChangeColor(value || '', index)}
                                    onlyColorEnabled
                                  />
                                  <TextInputTG
                                    value={item}
                                    onChange={(value) => onChangeColor(value || '', index)}
                                    className={classes.colorInput}
                                    inputRoot={classes.colorPickerContainer}
                                    placeholder={`item ${index}`}
                                  />                             
                                    <IconButton
                                      style={styles.removeIcon}
                                      onClick={() => deletePaletteColor(index)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faMinus}
                                        className={classes.minusIcon}
                                      />
                                    </IconButton>                     
                                </div>
                                {colorMappings.length - 1 !== index && <Divider />}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </Box>
            <Box className={classes.bottomContainer}>
              <Button
                variant="contained"
                color="default"
                disableElevation
                className={`${classes.buttonContainer} ${classes.cancelBtn}`}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={`${classes.buttonContainer} ${classes.saveBtn}`}
                onClick={()=> !editMode ? handleSave() : handleUpdate?.({name: (editComponent!).name, label: key as string, colors: colorMappings})}
              >
                {!editMode ? 'Save' : 'Save Changes'}
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    </ModalComponent>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    marginTop: 10,
  },
  innerContainer: {
    background: colors.grayBackground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
  },
  removeIcon: {
    color: colors.black,
  },
  txt: {
    marginLeft: 22,
    fontSize: 15,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '100%',
    color: colors.black,
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 400,
      '@media all and (-ms-high-contrast: none)': {
        display: 'none',
      },
    },
    noColor: {
      height: 60,
    },
    noColorTxt: {
      fontSize: 15,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '100%',
      color: colors.black54,
    },
    input: {
      fontSize: 15,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '100%',
    },
    schemesContainer: {
      fontSize: 15,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '100%',
      marginTop: 24
    },
    label: { fontWeight: 500 },
    icon: {
      fontSize: 17,
    },
    colorPickerContainer: {
      height: 36,
    },
    colorInput: {
      width: '97%',
      marginLeft: 7,
    },
    minusIcon: {
      fontSize: 17,
      color: colors.black54,
    },
    render: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
    },
    colorsContainer: {
      paddingRight: 5,
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(2px)',
    },
    paper: {
      width: 637,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      maxHeight: '90%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    header: {
      height: 57,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5,
    },
    headerText: {
      fontWeight: 500,
      fontSize: 20,
      marginLeft: 15,
    },
    bottomContainer: {
      position: 'relative',
      zIndex: 9,
      display: 'flex',
      padding: '17px 20px',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      gap: 15,
      boxShadow: `0px -2px 4px 0px ${colors.black5}`,
      height: 77,
    },
    buttonContainer: {
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      border: `1.5px solid ${theme.palette.primary.main}`,
      height: 42,
      borderRadius: 5,
      width: 144,
    },
    cancelBtn: {
      color: theme.palette.primary.main,
      width: 134,
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    closeIcon: {
      height: 20,
      width: 20,
    },
    iconCloseContainer: {
      position: 'relative',
      right: 10,
    },
    saveBtn: {
      width: 145,
    },
  })
);
export default AddModal;
