import React, { useMemo, useState } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { AttributeBasedFilterSimpleOptionType } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import ValueIcon from '@material-ui/icons/Visibility';
import { categoryColors } from 'utils/colors';
import { TextInputTG } from 'views/components/formElements';

interface AttributeBasedTextInputProps {
  id: string;
  value: AttributeBasedFilterSimpleOptionType | undefined;
  onChange: (arrayOption: AttributeBasedFilterSimpleOptionType | undefined) => void;
  keyType?: string | undefined;
  allowValue?: boolean;
}

export const AttributeBasedTextInput: React.FC<AttributeBasedTextInputProps> = ({
  id,
  value,
  onChange,
  keyType,
  allowValue,
}) => {
  const classes = useStyles();
  const [tempValue, setTempValue] = useState(
    value === '$$_USER_$$' || value === '$$_VALUE_$$' ? '' : value
  );
  const isNumber = keyType === 'Int' || keyType === 'Float';

  const handleToggleValue = () => {
    if (value === '$$_VALUE_$$') {
      onChange(tempValue);
    } else {
      if (value !== '$$_USER_$$') setTempValue(value);
      onChange('$$_VALUE_$$');
    }
  };

  // TODO: Remove deprecated variables; base validity on `AttributeBasedFilterVariable`. For now, just warn the user.
  const invalidVariables = ['$$_USER_$$'] as const;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isValueValid = useMemo(() => !invalidVariables.some(x => x === value), [value]);

  return (
    <Box className={classes.container}>
      {/* TODO: fix id  */}
      <TextInputTG
        id={id}
        value={value as string}
        onChange={onChange}
        style={styles.textInput}
        type={isNumber ? 'number' : 'text'}
        disabled={value === '$$_SELF_$$' || value === '$$_VALUE_$$'}
        error={!isValueValid}
        helperText={!isValueValid ? `"${value}" is not a variable or is no longer valid.` : undefined}
      />
      {allowValue && (
        <IconButton
          title="Value"
          className={`${classes.button} ${value === '$$_VALUE_$$' ? classes.active : ''}`}
          component="span"
          onClick={handleToggleValue}
        >
          <ValueIcon />
        </IconButton>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  button: {
    padding: 6,
    position: 'absolute',
    right: 0,
    color: categoryColors.defaultColor,
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

const styles = {
  textInput: {
    margin: 0,
  },
};
